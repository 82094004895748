import { ReactNode } from "react";
import "../../pages/pages.scss";
import InformationalBox from "../../components/InformationalBox/InformationalBox";
import CodeSample from "../../components/CodeSamplesContainer/CodeSample";
import { Page } from "../../components";
import { PageTitleProps } from "../../components/PageTitle/PageTitle";
import { HeaderSections } from "../../components/Header/headerSectionsEnum";
import { TableOfContentsItem } from "../../components/TableOfContents/TableOfContents";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const update11Point37: ReactNode = (
    <div className="paragraph">
        <h2>11.37</h2>
        <ul>
            <li>
                Added <Link to="/myGeotab/apiReference/objects/DutyStatusState">DutyStatusStates</Link> to the <Link to="/myGeotab/apiReference/objects/DutyStatusLog">DutyStatusLog</Link> object:
                <ul>
                    <li>
                        DutyStatusLogSearch.States will retrieve a list of DutyStatusState (<code>Active</code>, <code>Inactive</code>, <code>Requested</code>, <code>Rejected</code>).
                    </li>
                </ul>
            </li>
        </ul>
    </div>
);

const update11Point35: ReactNode = (
    <div className="paragraph">
        <h2>11.35</h2>
        <ul>
            <li>
                Added <code>ViewRiskManagementReportOption</code> to the <Link to="/myGeotab/apiReference/objects/SecurityIdentifier">SecurityIdentifier</Link> object.
                This option controls whether the <b>Run report by: Individual assets or drivers</b> option is available when running a <b>Risk Management</b> report.
                It is enabled by default for the Administrator and Supervisor clearances.
            </li>
            <li>
                Removed the obsolete <code>EnergyUsedSinceLastChargeKwh</code> property from the <Link to="/myGeotab/apiReference/objects/ChargeEvent">ChargeEvent</Link> object.
            </li>
            <li>
                Added null handling to the <code>Channel</code> and <code>Type</code> properties according to .NET object model expectations for <Link to="/myGeotab/apiReference/objects/IoxAddOn">IoxAddOn</Link> objects.
            </li>
        </ul>
    </div>
);

const update11Point34: ReactNode = (
    <div className="paragraph">
        <h2>11.34</h2>
        <ul>
            <li>
                Added support for a notification ID identifier to <Link to="/myGeotab/apiReference/objects/TextMessage">TextMessage</Link> objects to track objects added by Beep or GoTalk Notification Services.
            </li>
            <li>
                Added support for nullable properties to the <Link to="/myGeotab/apiReference/objects/Map">Map</Link> object for the following properties:
                <ul>
                    <li>
                        CannedResponse
                    </li>
                    <li>
                        DataToComponent
                    </li>
                    <li>
                        DeviceStatusInfoSearch
                    </li>
                </ul>
            </li>
            <li>
                Added null handling to the <code>Left</code>, <code>Right</code>, <code>Bottom</code> and <code>Top</code> properties according to .NET object model expectations
                for <Link to="/myGeotab/apiReference/objects/BoundingBox">BoundingBox</Link> objects.
            </li>
        </ul>
    </div>
);

const update11Point33: ReactNode = (
    <div className="paragraph">
        <h2>11.33</h2>
        <ul>
            <li>
                Databases are now categorized into tiers based on the number of active devices, with each higher tiers having a higher base rate limit multiplier. That is,
                databases with larger numbers of devices have higher API rate limits to accommodate their higher usage requirements. For specific details, refer to
                the <Link to="https://developers.geotab.com/myGeotab/guides/rateLimits">developer documentation</Link>. Tiered rate limits will be enabled across all endpoints on <b>August 20, 2024</b>.
            </li>
            <li>
                Created an endpoint to call TachoModule reporting protocol with all telemetries derived from DrivingTimeStatus.
                <ul>
                    <li>
                        Renamed <code>Crew</code> to <code>isCrew</code> within <Link to="/myGeotab/apiReference/objects/TachographDriverActivity">TachographDriverActivity</Link>
                    </li>
                    <li>
                        Renamed <code>Driver</code> to <code>UserSearch</code>, <code>From</code> to <code>FromDate</code>, <code>To</code> to <code>ToDate</code> in <Link to="/myGeotab/apiReference/objects/TachographDriverActivitySearch">TachographDriverActivitySearch</Link>
                    </li>
                    <li>
                        Added <Link to="/myGeotab/apiReference/objects/TachographDrivingTimeStatus">TachographDrivingTimeStatus</Link>
                    </li>
                    <li>
                        Added <Link to="/myGeotab/apiReference/objects/TachographDrivingTimeStatusSearch">TachographDrivingTimeStatusSearch</Link>
                    </li>
                </ul>
                <li>Removed active graphs from dashboards in the Fleet Management Application UI:</li>
                <ul>
                    <li>
                        Removed ExceptionsSummaryGraph, TripsIdlingSummaryGraph, TripsDensitySummaryGraph, FleetSummaryGraph, CustomerStopGraph from ReportArgumentType
                    </li>
                    <li>
                        Removed ExceptionsSummaryGraph, TripsIdlingSummaryGraph, TripsDensitySummaryGraph, FleetSummaryGraph, CustomerStopGraph from ReportDataSource
                    </li>
                    <li>
                        Removed ReportExceptionsSummaryGraph, ReportTripsIdlingSummaryGraph, ReportFleetSummaryGraph, ReportCustomerStopGraph, ReportTripsDensitySummaryGraph from SecurityRole
                    </li>
                </ul>
            </li>
        </ul>
    </div>
);

const update11Point32: ReactNode = (
    <div className="paragraph">
        <h2>11.32</h2>
        <ul>
            <li>
                Updated <Link to="/myGeotab/apiReference/objects/ZoneTypeSearch">ZoneTypeSearch</Link> to allow for searching zone types based on keywords.
            </li>
            <li>
                Added support to sort <code>ZoneTypes</code> by name or comments.
            </li>
            <li>
                Fixed a malformed namespace in SortField.cs:
                Geotab.Checkmate.ObjectModel.Geotab.Checkmate.ObjectModel corrected to Geotab.Checkmate.ObjectModel.
            </li>
            <li>
                Added null handling to the <code>Payload</code> and <code>ControllerProtocol</code> properties according to .NET object model expectations for <Link to="/myGeotab/apiReference/objects/ColdChainContent">ColdChainContent</Link> objects.
            </li>
        </ul>
    </div>
);

const update11Point31: ReactNode = (
    <div className="paragraph">
        <h2>11.31</h2>
        <ul>
            <li>
                Added header information for the following items in the C# NuGet SDK:
                <ul>
                    <li>
                        X-Rate-Limit-Limit
                    </li>
                    <li>
                        X-Rate-Limit-Remaining
                    </li>
                    <li>
                        X-Rate-Limit-Reset
                    </li>
                </ul>
            </li>
            <li>
                Added support for nullable properties to the <Link to="/myGeotab/apiReference/objects/ColdChainFaultClearContent">ColdChainFaultClearContent</Link> object.
            </li>
        </ul>
    </div>
);

const update11Point30: ReactNode = (
    <div className="paragraph">
        <h2>11.30</h2>
        <ul>
            <li>
                Added support for sorting work order jobs.
            </li>
        </ul>
    </div>
);

const update11Point29: ReactNode = (
    <div className="paragraph">
        <h2>11.29</h2>
        <ul>
            <li>
                Fixed issue with DiagnosticsBridge that caused <Link to="/myGeotab/apiReference/methods/Get">Get</Link> and <Link to="/myGeotab/apiReference/methods/GetCountOf">GetCount</Link> to incorrectly return different values.
            </li>
            <li>
                Fixed issue that allowed archived users to be used in a <Link to="/myGeotab/apiReference/objects/DriverChange">DriverChange</Link>.
            </li>
            <li>
                Added support for the GO Anywhere as a device type in the SDK.
            </li>
        </ul>
    </div>
);

const update11Point27: ReactNode = (
    <div className="paragraph">
        <h2>11.27</h2>
        <ul>
            <li>
                Added support to retrieve diagnostics of more than one Diagnostic Type to avoid having to perform separate front-end requests.
            </li>
            <li>
                Added several new security identifiers to provide precise access levels to entity types (not features):
                <ul>
                    <li>
                        <code>ViewStatusData</code>
                    </li>
                    <li>
                        <code>ViewDiagnostic</code>
                    </li>
                    <li>
                        <code>ViewCustomData</code>
                    </li>
                    <li>
                        <code>ViewFaultData</code>
                    </li>
                    <li>
                        <code>ViewExceptionEvent</code>
                    </li>
                    <li>
                        <code>ViewRule</code>
                    </li>
                    <li>
                        <code>ViewController</code>
                    </li>
                </ul>
            </li>
            <li>
                Added Language and Country parameters. Language is the two-letter ISO primary contact language code with a two-letter region code (for example, en-US for American English).
                Country is the two-letter ISO country code where the company's primary office is located.
            </li>
            <li>
                Added null handling to the <code>EffectiveStatus</code> property according to .NET object model expectations.
            </li>
            <li>
                Added null handling to the <code>FaultStates</code> property according to .NET object model expectations for <Link to="/myGeotab/apiReference/objects/FaultStateProvider">FaultStateProvider</Link> objects.
            </li>
            <li>
                Added null handling to the <code>IncludeHourlyData</code> and <code>IncludeBoundaries</code> properties according to .NET object model expectations for <Link to="/myGeotab/apiReference/objects/FuelTaxDetailSearch">FuelTaxDetailSearch</Link> objects.
            </li>
            <li>
                Added null handling to the <code>IsDeviceCommunicating</code>, <code>IsDriving</code>, and <code>IsHistoricLastDriver</code> properties according to .NET object model expectations for <Link to="/myGeotab/apiReference/objects/DeviceStatusInfo">DeviceStatusInfo</Link> objects.
            </li>
            <li>
                Added null handling to the <code>CannedResponseOptions</code> property according to .NET object model expectations for <Link to="/myGeotab/apiReference/objects/CannedResponseContent">CannedResponseContent</Link> objects.
            </li>
            <li>
                Added null handling to the following properties according to .NET object model expectations for <Link to="/myGeotab/apiReference/objects/FuelTaxDetail">FuelTaxDetail</Link> objects:
                <ul>
                    <li>
                        <code>EnterTime</code>
                    </li>
                    <li>
                        <code>EnterOdometer</code>
                    </li>
                    <li>
                        <code>EnterGpsOdometer</code>
                    </li>
                    <li>
                        <code>EnterLatitude</code>
                    </li>
                    <li>
                        <code>EnterLongitude</code>
                    </li>
                    <li>
                        <code>IsEnterOdometerInterpolated</code>
                    </li>
                    <li>
                        <code>ExitTime</code>
                    </li>
                    <li>
                        <code>ExitOdometer</code>
                    </li>
                    <li>
                        <code>ExitGpsOdometer</code>
                    </li>
                    <li>
                        <code>ExitLatitude</code>
                    </li>
                    <li>
                        <code>ExitLongitude</code>
                    </li>
                    <li>
                        <code>IsExitOdometerInterpolated</code>
                    </li>
                    <li>
                        <code>IsClusterOdometer</code>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
);

const update11Point26: ReactNode = (
    <div className="paragraph">
        <h2>11.26</h2>
        <ul>
            <li>
                Established an SDK endpoint to initiate the deletion process of <code>Route Completion</code> service routes.
            </li>
            <li>
                Added a bridge class that is responsible for getting and setting <code>Range Estimates</code> entities.
            </li>
            <li>
                Added the ability to search by multiple severities (Unknown, Low, Medium, High, Critical) and by diagnostic source (Engine, Telematics Device) to the backend search.
            </li>
            <li>
                Added support for predictive workers to report MaintenanceIssues.
            </li>
            <li>
                Removed feature flags from Email Sender Domains.
            </li>
            <li>
                Added support for nullable properties to <Link to="/myGeotab/apiReference/objects/WifiHotspotData">WifiHotspotData</Link> objects.
            </li>
        </ul>
    </div>
);

const update11Point25: ReactNode = (
    <div className="paragraph">
        <h2>11.25</h2>
        <ul>
            <li>
                Added <code>Coast Pay</code> as a Provider to the <Link to="/myGeotab/apiReference/objects/FuelTransactionProvider">FuelTransactionProvider</Link> object.
            </li>
            <li>
                Changed the default GeometryTypeSearch to returns counts of <code>.Polygon</code> and not <code>.All</code>.
            </li>
            <li>
                Added null handling to the <code>Volume</code> and <code>Source</code> properties according to .NET object model expectations for <Link to="/myGeotab/apiReference/objects/FuelTankCapacity">FuelTankCapacity</Link> objects.
            </li>
            <li>
                Added null handling to the <code>MaxSpeed</code> and <code>TruckMaxSpeed</code> properties according to .NET object model expectations for <Link to="/myGeotab/apiReference/objects/MaxRoadSpeedResult">MaxRoadSpeedResult</Link> objects.
            </li>
        </ul>
    </div>
);

const update11Point24: ReactNode = (
    <div className="paragraph">
        <h2>11.24</h2>
        <ul>
            <li>
                Added support for <code>AutoGroups</code> (Asset Status Groups) to <Link to="/myGeotab/apiReference/objects/CustomDevice">CustomDevice</Link> and <Link to="/myGeotab/apiReference/objects/CustomVehicleDevice">CustomVehicleDevice</Link>.
            </li>
        </ul>
    </div>
);

const update11Point23: ReactNode = (
    <div className="paragraph">
        <h2>11.23</h2>
        <ul>
            <li>
                Removed support for <code>GO4v3</code> from the Fleet Management Application source code.
            </li>
            <li>
                Removed reference to Npgsql Nuget Package in the ObjectModel Project due to a vulnerability issue.
            </li>
        </ul>
    </div>
);

const update11Point22: ReactNode = (
    <div className="paragraph">
        <h2>11.22</h2>
        <ul>
            <li>
                Removed Beta flags from fuel objects in the SDK.
            </li>
            <li>
                Added handling of null properties to longitude and latitude.
            </li>
        </ul>
    </div>
);

const update11Point21: ReactNode = (
    <div className="paragraph">
        <h2>11.21</h2>
        <ul>
            <li>
                The specific date property that "SortByDate" sorts by will be specified in the documentation. Applicable to: <Link to="/myGeotab/apiReference/objects/FaultData">FaultData</Link>, <Link to="/myGeotab/apiReference/objects/StatusData">StatusData</Link>, <Link to="/myGeotab/apiReference/objects/ExceptionEvent">ExceptionEvent</Link>, <Link to="/myGeotab/apiReference/objects/Audit">Audit</Link>, <Link to="/myGeotab/apiReference/objects/DebugData">DebugData</Link>, <Link to="/myGeotab/apiReference/objects/DriverChange">DriverChange</Link>, <Link to="/myGeotab/apiReference/objects/DutyStatusLog">DutyStatusLog</Link>, <Link to="/myGeotab/apiReference/objects/FuelTaxDetail">FuelTaxDetail</Link>, <Link to="/myGeotab/apiReference/objects/LogRecord">LogRecord</Link>, <Link to="/myGeotab/apiReference/objects/ShipmentLog">ShipmentLog</Link>, <Link to="/myGeotab/apiReference/objects/TextMessage">TextMessage</Link>, <code>UserHosRuleSet</code>.
            </li>
        </ul>
    </div>
);


const update11Point20: ReactNode = (
    <div className="paragraph">
        <h2>11.20</h2>
        <ul>
            <li>
                Increased the rate limit for <code>GetAllFeatureFlags</code> API from 10 per second to 100 per second.
            </li>
            <li>
                <Link to="/myGeotab/apiReference/objects/ZoneTypeSearch">ZoneTypeSearch</Link> is now publicly available.
            </li>
        </ul>
    </div>
);

const update11Point19: ReactNode = (
    <div className="paragraph">
        <h2>11.19</h2>
        <ul>
            <li>
                Added a more meaningful error message when attempting to add or set an <Link to="/myGeotab/apiReference/objects/AnnotationLog">AnnotationLog</Link> with a null Driver ID.
            </li>
        </ul>
    </div>
);

const update11Point18: ReactNode = (
    <div className="paragraph">
        <h2>11.18</h2>
        <ul>
            <li>
                Added handling of null properties to <Link to="/myGeotab/apiReference/objects/RoutePlanItem">RoutePlanItem</Link>,{" "}
                <Link to="/myGeotab/apiReference/objects/RequestLocation">RequestLocation</Link>, and <Link to="/myGeotab/apiReference/objects/CompanyDetails">CompanyDetails</Link>.
            </li>
        </ul>
    </div>
);

const update11Point17: ReactNode = (
    <div className="paragraph">
        <h2>11.17</h2>
        <ul>
            <li>
                Added handling of null properties to <Link to="/myGeotab/apiReference/objects/DriverChange">DriverChange</Link>,{" "}
                <Link to="/myGeotab/apiReference/objects/DriverAuthListContent">DriverAuthListContent</Link>, and <Link to="/myGeotab/apiReference/objects/FillUpSearch">FillUpSearch</Link>.
            </li>
        </ul>
    </div>
);

const update11Point16: ReactNode = (
    <div className="paragraph">
        <h2>11.16</h2>
        <ul>
            <li>
                Added handling of null properties for <Link to="/myGeotab/apiReference/objects/TimeZoneInfo">TimeZoneInfo</Link>,{" "}
                <Link to="/myGeotab/apiReference/objects/TimeZoneInfoAdjustmentRule">TimeZoneInfoAdjustmentRule</Link>, <Link to="/myGeotab/apiReference/objects/SecurityFilter">SecurityFilter</Link>
                , <Link to="/myGeotab/apiReference/objects/TextMessageSearch">TextMessageSearch</Link>, and <Link to="/myGeotab/apiReference/objects/FieldSelector">FieldSelector</Link>.
            </li>
        </ul>
    </div>
);

const update11Point15: ReactNode = (
    <div className="paragraph">
        <h2>11.15</h2>
        <ul>
            <li>
                Removed Beta tag for <Link to="/myGeotab/apiReference/objects/TachographDataFileSearch">TachographDataFileSearch</Link> and{" "}
                <Link to="/myGeotab/apiReference/objects/TachographDataFile">TachographDataFile</Link>.
            </li>
        </ul>
    </div>
);

const update11Point14: ReactNode = (
    <div className="paragraph">
        <h2>11.14</h2>
        <ul>
            <li>
                Added EFS as a provider under <Link to="/myGeotab/apiReference/objects/FuelTransactionProvider">FuelTransactionProvider</Link>.
            </li>
            <li>
                Added handling of null properties for <Link to="/myGeotab/apiReference/objects/TripSearch">TripSearch</Link>, <Link to="/myGeotab/apiReference/objects/JsonRpcError">JsonRpcError</Link>, <Link to="/myGeotab/apiReference/objects/JsonRpcErrorData">JsonRpcErrorData</Link>, and <Link to="/myGeotab/apiReference/objects/VersionInformation">VersionInformation</Link>.
            </li>
        </ul>
    </div>
);

const update11Point13: ReactNode = (
    <div className="paragraph">
        <h2>11.13</h2>
        <ul>
            <li>
                Added beta support for <Link to="/myGeotab/apiReference/objects/ChargeEvent">ChargeEvent</Link>, which represents a vehicle being charged.
            </li>
            <li>
                Added beta support for <Link to="/myGeotab/apiReference/objects/ChargeType">ChargeType</Link>, which represents the current type of the charge event.
            </li>
            <li>
                Added beta support for <Link to="/myGeotab/apiReference/objects/ChargeEventSearch">ChargeEventSearch</Link>
            </li>
        </ul>
    </div>
);

const update11Point12: ReactNode = (
    <div className="paragraph">
        <h2>11.12</h2>
        <ul>
            <li>
                The <Link to="/myGeotab/apiReference/objects/MaxRoadSpeedResult">MaxRoadSpeedResult</Link> object is now public.
            </li>
            <li>
                Added missing type info to <Link to="/myGeotab/apiReference/objects/PostedRoadSpeedOptions">PostedRoadSpeedOptions</Link>.
            </li>
            <li>
                Added new type <code>NoRepairNeeded</code> to <Link to="/myGeotab/apiReference/objects/DtcSeverity">DtcSeverity</Link>.
            </li>
            <li>
                Replaced the Drive App's API call to get <Link to="/myGeotab/apiReference/objects/Device">Device</Link> instead of <Link to="/myGeotab/apiReference/objects/Trailer">Trailer</Link>.
            </li>
        </ul>
    </div>
);

const update11Point11: ReactNode = (
    <div className="paragraph">
        <h2>11.11</h2>
        <ul>
            <li>
                The maximum number of faults per request per <code>DeviceId</code> is now limited to 200. If a device has more than 200 faults, they will be retrieved
                in batches of 200 faults per request.
            </li>
            <li>Implemented web layer APIs/wrappers to check for user overlay permissions, and to ask for permission if not granted.</li>
        </ul>
    </div>
);

const update11Point10: ReactNode = (
    <div className="paragraph">
        <h2>11.10</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/DeviceStatusInfo">DeviceStatusInfo</Link> in the DDIS is now calculated for devices with no GPS data. In addition, control whether the{" "}
                <Link to="/myGeotab/apiReference/objects/DeviceStatusInfo">DeviceStatusInfo</Link> object is generated with an updated <code>dateTime</code> or not.
            </li>
        </ul>
    </div>
);

const update11Point9: ReactNode = (
    <div className="paragraph">
        <h2>11.9</h2>
        <ul>
            <li>Updated the API reference section on the SDK page with documentation on rate limiting for APIs. Note that these are intended changes, which are not currently available.</li>
            <li>
                On the SDK reference page, <Link to="/myGeotab/apiReference/methods/GetPostedRoadSpeedsForDevice">GetPostedRoadSpeedsForDevice</Link> is now public and{" "}
                <code>GetRoadMaxSpeeds</code> is now deprecated.
            </li>
            <li>
                Removed <code>ElectricDistanceKm</code> from ChargeEvents.
            </li>
        </ul>
    </div>
);

const update11Point7: ReactNode = (
    <div className="paragraph">
        <h2>11.7</h2>
        <ul>
            <li>Added EV Real Time Range API. Note that this is an interpolated value, and is not the range data pulled from the vehicle.</li>
            <li>
                Added a default rule for <b>Enhanced Minor Collision</b>.
            </li>
            <li>
                Renamed the built-in rule previously known as <b>Enhanced Major Collision</b> to <b>Major Collision</b>. Similarly, <b>Possible Accident</b> has been renamed to{" "}
                <b>Possible Collision (Legacy)</b> to better reflect its functionality.
            </li>
            <li>
                Added a description for the <b>AutoGroups</b> property (in the{" "}
                <Link to="/myGeotab/apiReference/objects/UntrackedAsset">
                    <b>UntrackedAsset</b>
                </Link>{" "}
                object) to the SDK reference page.
            </li>
            <li>
                Added new built-in zone type <b>VendorServiceCenter</b>, allowing for more specific categorization.
            </li>
        </ul>
    </div>
);

const update11Point6: ReactNode = (
    <div className="paragraph">
        <h2>11.6</h2>
        <ul>
            <li>Generic exception changed to "Invalid group id used. {"{ID}"} is not a valid group id." when an invalid group ID is provided.</li>
        </ul>
    </div>
);

const update11Point4: ReactNode = (
    <div className="paragraph">
        <h2>11.4</h2>
        <ul>
            <li>
                Added the <Link to="/myGeotab/apiReference/objects/ChargeEvent">ChargeEvent</Link> object to <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link>. For more information
                about <Link to="/myGeotab/apiReference/objects/ChargeEvent">ChargeEvent</Link>, refer to{" "}
                <a href="https://docs.google.com/document/d/1f5aUkK9t3JiEqg6oL9dep5UDps_ymuC2refw7pEZ0VU/edit#heading=h.4t4zr0erfpzp" target="_blank" rel="noopener noreferrer">
                    this document
                </a>
                .
            </li>
        </ul>
    </div>
);

const update11Point0Point6844: ReactNode = (
    <div className="paragraph">
        <h2>11.0.6844</h2>
        <ul>
            <li>
                Fixed an issue where the <code>startOfDay</code> property could be null, instead of having a fallback default value (the default value is midnight,
                00:00:00).
            </li>
        </ul>
    </div>
);

const update11Point0Point60xx: ReactNode = (
    <div className="paragraph">
        <h2>11.0.60xx</h2>
        <ul>
            <li>
                Added property <code>isIgnored</code> and removed property <code>isHidden</code> from the{" "}
                <Link to="/myGeotab/apiReference/objects/DutyStatusLog">DutyStatusLog</Link> object.
            </li>
            <li>
                <Link to="/myGeotab/apiReference/objects/LogRecord">LogRecords</Link> are no longer interpolated when no device is provided. Interpolation is now gated behind a custom code.
            </li>
            <li>
                <code>cycleDriving</code> property was added to <Link to="/myGeotab/apiReference/objects/DutyStatusAvailability">DutyStatusAvailability</Link> object.{" "}
                <code>cycleDriving</code> property is also added to the <Link to="/myGeotab/apiReference/objects/DutyStatusViolationType">DutyStatusViolationType</Link>{" "}
                object.
            </li>
            <li>
                Removed all logic related to <code>Viewport</code> in <Link to="/myGeotab/apiReference/objects/ZoneSearch">ZoneSearch</Link>, since it has been
                deprecated for some time. If a <Link to="/myGeotab/apiReference/objects/ZoneSearch">ZoneSearch</Link>
                is provided with <code>Viewport</code>, an error message is returned.
            </li>
            <li>
                Added <code>minYear</code> parameter to <Link to="/myGeotab/apiReference/methods/GetDaylightSavingRules">GetDaylightSavingRules</Link> so that
                adjustment rules are returned only for the year 2000 and onward. Previously, years going back to 1920 were stored causing a large amount of wasted localstorage.
            </li>
        </ul>
    </div>
);

const update11Point0Point48xx: ReactNode = (
    <div className="paragraph">
        <h2>11.0.48xx</h2>
        <ul>
            <li>Fixed an error when retrieving DutyStatusLogs for all drivers.</li>
        </ul>
    </div>
);

const update11Point0Point30xx: ReactNode = (
    <div className="paragraph">
        <h2>11.0.30xx</h2>
        <ul>
            <li>
                Added support for the <Link to="/myGeotab/apiReference/objects/AddInSearch">AddIn</Link> search object.
            </li>
            <li>Device license plate is now updated if the device license plate field is empty but the VLR (Variable Length Record) has registration (Tachograph processing).</li>
            <li>Added ability to configure the time window of route stops. The default is 1 hour.</li>
        </ul>
    </div>
);

const update11Point0Point29xx: ReactNode = (
    <div className="paragraph">
        <h2>11.0.29xx</h2>
        <ul>
            <li>
                A property called <code>jobPriorities</code> is added to the <Link to="/myGeotab/apiReference/objects/User">User</Link> object, it stores the list of
                selected job priorities.
            </li>
            <li>
                A property called <code>activeDefaultDashboards</code> is added to the <Link to="/myGeotab/apiReference/objects/User">User</Link> object, it stores the
                list of default dashboards.
            </li>
            <li>
                The <Link to="/myGeotab/apiReference/methods/GetDaylightSavingRules">GetDaylightSavingRules</Link> method now returns only rules from the year 2000 onwards by default.
            </li>
        </ul>
    </div>
);

const update11Point0Point28xx: ReactNode = (
    <div className="paragraph">
        <h2>11.0.28xx</h2>
        <ul>
            <li>
                Sealed objects are removed from <b>Nuget</b> package for existing objects.
            </li>
            <li>
                Bug fixed by ensuring <code>PropertySelector</code> for <Link to="/myGeotab/apiReference/objects/Device">Device</Link> only returns the property added
                to the field id within <code>PropertySelector</code>.
            </li>
            <li>
                New ruleset <b>"CanadaBCLoggingTruck"</b> added to the list of <Link to="/myGeotab/apiReference/objects/HosRuleSet">HosRuleSet</Link>.
            </li>
        </ul>
    </div>
);

const update11: ReactNode = (
    <div className="paragraph">
        <h2>Get and GetFeed for FuelUsed and FillUp</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/methods/Get">Get</Link> and <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link> for{" "}
                <Link to="/myGeotab/apiReference/objects/FuelUsed">FuelUsed</Link> is now available. The query will return fuel consumption for each MyGeotab trip — corresponding to each trip's
                ending date and time.
            </li>
            <li>
                <Link to="/myGeotab/apiReference/methods/Get">Get</Link> and <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link> for{" "}
                <Link to="/myGeotab/apiReference/objects/FillUp">FillUp</Link> is now available. The query will return fill-ups detected by MyGeotab or matching fuel transaction records, if
                available. Each fill-up will also be matched to a MyGeotab trip — corresponding to the trip's ending date and time.
            </li>
        </ul>
        <h2>New transaction provider</h2>
        <ul>
            <li>
                Car IQ is now available as a <Link to="/myGeotab/apiReference/objects/FuelTransactionProvider">fuel card provider</Link>.
            </li>
        </ul>
        <h2>Added new MessageContentTypes</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/ColdChainSetpointSetContent">ColdChainSetpointSetContent</Link> and{" "}
                <Link to="/myGeotab/apiReference/objects/ColdChainFaultClearContent">ColdChainFaultClearContent</Link> have been added.
            </li>
        </ul>
        <h2>New ConditionType Property</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/ConditionType">isValueThreshold</Link> has been added.
            </li>
        </ul>
        <h2>New MediaFileSearch options</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/MediaFile">MediaFile</Link> entries can now be searched by the <code>solutionId</code> property.
            </li>
            <li>
                <Link to="/myGeotab/apiReference/objects/MediaFile">MediaFile</Link> entries can now be searched by using the <Link to="/myGeotab/apiReference/objects/TagSearch">TagSearch</Link>{" "}
                object.
            </li>
        </ul>
        <h2>New TextMessageSearch options</h2>
        <ul>
            <li>
                <code>mimeTypes</code> and <code>channelNumbers</code> search options have been added
            </li>
        </ul>
        <h2>New property for DutyStatusAvailability</h2>
        <ul>
            <li>
                A new property has been added for the <Link to="/myGeotab/apiReference/objects/DutyStatusAvailability">DutyStatusAvailability</Link> object that details the duration of the driving
                break. Note: This new property is available for USA rulesets only.
            </li>
        </ul>
        <InformationalBox>
            <p>
                <b>Note</b>: This new property is available for USA rulesets only.
            </p>
        </InformationalBox>
        <h2>Added TachographDataFile object</h2>
        <ul>
            <li>
                Added the <Link to="/myGeotab/apiReference/objects/TachographDataFile">TachographDataFile</Link> object that represents a tachograph data file.
            </li>
        </ul>
        <h2>New CaptchaAnswer properties</h2>
        <ul>
            <li>
                Added the <Link to="/myGeotab/apiReference/objects/CaptchaAnswer">greCaptchaToken</Link> and <Link to="/myGeotab/apiReference/objects/CaptchaAnswer">greCaptchaAction</Link>{" "}
                properties when using google enterprise recaptcha.
            </li>
        </ul>
        <h2>PropertySelector fixes</h2>
        <ul>
            <li>
                Fixed the property selector not working without the search object for the following entities:
                <ul>
                    <li>
                        <Link to="/myGeotab/apiReference/objects/Trip">Trip</Link>
                    </li>
                    <li>
                        {" "}
                        <Link to="/myGeotab/apiReference/objects/User">User</Link>
                    </li>
                    <li>
                        {" "}
                        <Link to="/myGeotab/apiReference/objects/Group">Group</Link>
                    </li>
                </ul>
            </li>
        </ul>
        <h2>Add-In configuration updates via SystemSettings</h2>
        <InformationalBox>
            <p>
                Add-In updates via the customerPages are <b>no longer supported</b>.
            </p>
        </InformationalBox>
        <h2>Nuget package</h2>
        <ul>
            <li>A fix has been issued for the nuget package where the name property for stock groups was not returned.</li>
        </ul>
    </div>
);

const update10: ReactNode = (
    <div className="paragraph">
        <h2>SDK updates</h2>
        <h3>API runner (SDK)</h3>
        <p>The following improvements are added:</p>
        <ul>
            <li>Added loading spinner to indicate to users that the login request is being processed.</li>
            <li>
                Added a keypress handler for the login form that allows the cursor to move to the next field, and added functionality for an enter key press event to submit the form when inputting
                information in the last field.
            </li>
            <li>Fixed an error message occurring in the main .js:scrollSidebar.</li>
            <li>Fixed an issue that caused multiple click handlers to be added to the login button.</li>
        </ul>
        <h2>SecurityIdentifier</h2>
        <ul>
            <li>Added the ImpersonateGpsTextMessage security identifier. When enabled, the security identifier does not validate the identity of the sender.</li>
            <li>Added the ResetPassword security identifier that allows a user to reset another user's passwords.</li>
        </ul>
        <h2>Mg-Api-Js</h2>
        <p>We've corrected the following errors:</p>
        <ul>
            <li>Fixed UnhandledRejection exceptions.</li>
            <li>Improved exception messages to include the type from the JSON-RPC error.</li>
            <li>Fixed InvalidUserException retry authentication.</li>
            <li>Updated CallBackError to be invoked as the last operation in the promise field.</li>
        </ul>
        <h2>
            GetFeed{"<"}FuelTaxDetail{">"}
        </h2>
        <ul>
            <li>Fixed issues with results sorting that led to missing results.</li>
        </ul>
        <h2>Nuget</h2>
        <ul>
            <li>Enabled brotli compression in Nuget package API.cs requests.</li>
            <li>
                <s>Many ObjectModel classes are marked as "sealed”. Marking a class as "sealed” prevents tampering of important classes that can compromise security or impact performance.</s>
            </li>
        </ul>
        <InformationalBox>
            <p>
                <b>!IMPORTANT:</b> Updating your application to version 10.0 may not work if an ObjectModel class is inherited.
            </p>
        </InformationalBox>
        <h2>TextMessage</h2>
        <ul>
            <li>
                Added <code>PropertySelector</code> (Beta) support for the TextMessage type.
            </li>
        </ul>
        <h2>IoxAddOn</h2>
        <ul>
            <li>
                Added <code>PropertySelector</code> (Beta) support for the IoxAddOn type.
            </li>
        </ul>
        <h2>IoxAddOnStatus</h2>
        <ul>
            <li>
                Added <code>PropertySelector</code> (Beta) support for the IoxAddOnStatus type.
            </li>
        </ul>
        <h2>DriverChange</h2>
        <ul>
            <li>Updated the description of DriverChange DateTime to notify users that the DateTime must not be in the future when adding a DriverChange through API.</li>
        </ul>
        <h2>DriverWhiteListContent</h2>
        <ul>
            <li>
                DriverWhiteListContent is replaced by <b>DriverAuthListContent</b>
            </li>
            <li>Prior to the 10.0 release, DriverWhiteListContent and DriverAuthListContent were both available to allow users to adjust to the transition.</li>
        </ul>
        <h2>Condition and ConditionType</h2>
        <ul>
            <li>Added the Group exception rule condition. This separates conditions in a single rule when different groups require different conditions.</li>
        </ul>
        <h2>Geotab developers</h2>
        <ul>
            <li>
                Added a section to the <Link to="/myGeotab/addIns/developingAddIns">Developing Add-Ins</Link> page suggesting to use the generator-addin repository.
            </li>
        </ul>
        <h2>SDK samples</h2>
        <ul>
            <li>Added JavaScript samples to refresh the user interface.</li>
        </ul>
        <h2>SDK Add-In samples</h2>
        <ul>
            <li>
                Added Add-In samples for the AddInData API to the{" "}
                <a href="https://github.com/Geotab/sdk-addin-samples" target="_blank" rel="noopener noreferrer">
                    Geotab/sdk-addin-samples
                </a>{" "}
                Github repository.
            </li>
        </ul>
    </div>
);

const update9: ReactNode = (
    <div className="paragraph">
        <h2>Custom security identifiers for Add-Ins</h2>
        <p>
            Custom security identifiers for MyGeotab Add-Ins are now available. Identifiers can be defined in the configuration.json file of Add-Ins, which will then add them to the list of
            permissions available when editing clearances. These definitions can support multiple languages.
        </p>
        <p>
            Administrator clearance will remain non-editable and have all custom clearances enabled by default. All other default/system clearance levels only allow editing of custom security
            identifiers, while keeping the system defined default identifiers non-editable.
        </p>
        <p>
            <b>* NOTE</b>: By default, once the <code>enableViewSecurityId</code> property in the configuration.json for an Add-In is set to True, a View "Add-In name”
            Add-In identifier is created that must be enabled for users to be able to view the Add-In. More granular control needs to be set within the Add-In code for any custom identifiers added
            to the configuration.json.
        </p>
        <h2>IP rate limits</h2>
        <ul>
            <li>
                Added new rate limits for API requests for endpoints that do not require authentication. See{" "}
                <a href="https://docs.google.com/document/d/1sUKaOKjVT23qvaCITMseIMqGUhQzlr7xDhq1EjrseYE/edit#heading=h.ygvbrm3xiv4u" target="_blank" rel="noopener noreferrer">
                    this announcement
                </a>{" "}
                for more information.
            </li>
        </ul>
        <h2>Updates</h2>
        <h3>DatabaseExists</h3>
        <ul>
            <li>
                Removed the DatabaseExists API that was originally used for database registration purposes. If the database name exists, <code>CreateDatabase</code>{" "}
                throws a <code>RegistrationException</code>.
            </li>
        </ul>
        <h3>RouteSearch</h3>
        <ul>
            <li>
                Removed the <code>RouteSearchGroups</code> property and replaced it with the <code>ServiceGroups</code> property.
            </li>
        </ul>
        <h3>PropertySelector</h3>
        <ul>
            <li>
                Added the optional <code>PropertySelector</code> parameter that is used with the <Link to="/myGeotab/apiReference/methods/Get">Get</Link> and{" "}
                <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link> methods to include or exclude specific properties for entity types requested. Detailed information can be found on
                the <HashLink to="/myGeotab/guides/concepts#property-selector">Geotab Developers</HashLink> site.
            </li>
        </ul>
        <h3>DriverWhiteListContent / DriverAuthListContent</h3>
        <ul>
            <li>
                Removed the <code>DriverWhiteListContent</code> object and replaced it with the <code>DriverAuthListContent</code> object.
            </li>
        </ul>
        <h3>Group</h3>
        <ul>
            <li>Improved system group objects to be returned with an English Name field.</li>
        </ul>
        <h3>DeviceShareStatus</h3>
        <ul>
            <li>
                Added <code>RequestPending</code> as an acceptable value of <code>DeviceShareStatus</code>. This value indicates when an
                outgoing device share request has been created by a user on the source database, and is waiting for confirmation it has been created successfully.
            </li>
        </ul>
        <h3>SecurityIdentifier</h3>
        <ul>
            <li>
                Added the following security identifiers:
                <ul>
                    <li>
                        <code>ResetPassword</code>
                    </li>
                    <li>
                        <code>EditStockRules</code>
                    </li>
                    <li>
                        <code>ContinuousConnectClearance</code>
                    </li>
                    <li>
                        <code>ViewShipments</code>
                    </li>
                    <li>
                        <code>ManageShipments</code>
                    </li>
                    <li>
                        <code>ManageTachographInspection</code>
                    </li>
                    <li>
                        <code>ViewTachographInspection</code>
                    </li>
                    <li>
                        <code>ManageTachographCompanyCards</code>
                    </li>
                    <li>
                        <code>ViewTachographCompanyCards</code>
                    </li>
                    <li>
                        <code>TachographDownloadAndUploadFiles</code>
                    </li>
                    <li>
                        <code>ViewTachographRemoteDownloadData</code>
                    </li>
                    <li>
                        <code>ViewTachographDrivingTimeData</code>
                    </li>
                    <li>
                        <code>ViewTachographInfringementData</code>
                    </li>
                    <li>
                        <code>SendColdChainCommand</code>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
);

const update8: ReactNode = (
    <div className="paragraph">
        <h2>Special note about Trailer and Device</h2>
        <p>We have migrated all Trailers to be Devices in all customer databases. As a result, you will see the following changes:</p>
        <ul>
            <li>
                <code>GroupAssetTypeId</code>, <code>GroupTrailerId</code>, and <code>GroupVehicleId</code>{" "}
                built-in groups are added under CompanyGroup.
            </li>
            <li>
                A <code>GroupVehicleId</code> built-in group is added to all Devices.
            </li>
            <li>
                Calling Add Trailer and Add Device with the <code>GroupTrailerId</code> built-in group now performs the same action. Both create a Device in the system
                that is in the <code>GroupTrailerId</code> group.
            </li>
            <li>Trailer APIs have been marked as obsolete but will continue to be supported (for now).</li>
            <li>
                Calling <Link to="/myGeotab/apiReference/methods/Get">Get</Link> Device now returns devices that are in the <code>xGroupTrailerId</code> built-in group
                as part of the response.
            </li>
            <li>
                If the Customer wants only vehicles to be returned when calling <Link to="/myGeotab/apiReference/methods/Get">Get</Link> Device, and not trailers, they should specify groupSearch:{" "}
                <code>{'{"id”:”GroupVehicleId”}'}</code>.
            </li>
            <li>
                Calling Add Trailer with the <code>GroupTrailerId</code> or <code>GroupVehicleId</code> built-in groups will throw an
                error.
            </li>
        </ul>
        <InformationalBox>
            <p>
                <b>! IMPORTANT</b>: Calling Set Device and removing the <code>GroupVehicleId</code> or <code>GroupTrailerId</code>{" "}
                built-in groups will prevent the vehicle or trailer from being shown on the relevant selection screens on the Drive App. Calling Set Device and switching the group from{" "}
                <code>GroupTrailerId</code> built-in group to <code>GroupVehicleId</code> built-in group or vice versa will not be
                allowed. This is a temporary restriction in the 8.0 release, and we intend to remove this check in a future release.
            </p>
        </InformationalBox>
        <p>
            See{" "}
            <a href="https://docs.google.com/presentation/d/1C0CBY4qaJKHx3J-fdB-YZzxbilQoleqjv8WsyFIZhEE/edit#slide=id.gdb284aa95f_0_61" target="_blank" rel="noopener noreferrer">
                this slide deck
            </a>{" "}
            to understand more about why this change was made, and how this may impact you.
        </p>
        <h2>Special note about EV powertrain groups</h2>
        <p>
            This new built-in group structure automatically classifies electric vehicles (EV) based on their unique powertrain types: Plug-in Hybrid (PHEV), Battery Electric Vehicle (BEV), or Fuel
            Cell Electric Vehicle (FCEV).{" "}
            <a href="https://docs.google.com/document/d/1W9_Y1XukkaRKQDfJ-RH2YsUptSkUPazx_E4UjfOcKoU/edit" target="_blank" rel="noopener noreferrer">
                See MyGeotab Version 8.0 SDK Announcement - New built-in groups for EV powertrain identification for additional details.
            </a>
        </p>
        <h2>Updates</h2>
        <h3>AddInData</h3>
        <ul>
            <li>
                Removed the obsolete alpha <code>Data</code> property.
            </li>
        </ul>
        <h3>Audit</h3>
        <ul>
            <li>
                Fix: The Audit API is inconsistent in what it returns. Most ways of getting audit (<Link to="/myGeotab/apiReference/methods/Get">Get</Link> - from ID or search,{" "}
                <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link>) do not return a populated User property. However, the GetAll (
                <Link to="/myGeotab/apiReference/methods/Get">Get</Link> with no search) returns records with the user fully populated. This is not consistent with the API philosophy. A nested
                entity will only have its ID populated. User will now never be returned in the Audit object (only userName).
            </li>
        </ul>
        <h3>Device</h3>
        <ul>
            <li>
                Fix: Searching for <code>CustomDevice</code> type could also return <code>CustomVehicleDevice</code> devices in some
                cases. It has been fixed to return only devices of <code>CustomDevice</code> type.
            </li>
        </ul>
        <h3>DutyStatusAvailability</h3>
        <ul>
            <li>
                Added <code>IsAdverseDrivingApplied</code> and <code>IsRailroadExemptionAvailable</code>.
            </li>
        </ul>
        <h3>DutyStatusLogType</h3>
        <ul>
            <li>
                Added <code>RailroadExemption</code>.
            </li>
        </ul>
        <h3>DVIRLog</h3>
        <ul>
            <li>
                <code>RepairStatus</code> / <code>RepairUser</code> / <code>RepairDate</code> cannot be
                changed once set. A repair cannot be completed without the <code>RepairUser</code>, <code>RepairDate</code>, and{" "}
                <code>RepairStatus</code>.
            </li>
        </ul>
        <h3>GroupRelations</h3>
        <ul>
            <li>
                Improved description of <code>GroupRelations</code> in <Link to="/myGeotab/apiReference">API Reference</Link>.
            </li>
        </ul>
        <h3>Group</h3>
        <ul>
            <li>
                Added the following system groups:
                <ul>
                    <li>
                        <code>GroupAssetTypeId</code>
                    </li>
                    <li>
                        <code>GroupVehicleId</code>
                    </li>
                    <li>
                        <code>GroupTrailerId</code>
                    </li>
                    <li>
                        <code>PowertrainAndFuelTypeId</code>
                    </li>
                    <li>
                        <code>GroupElectricHybridPluginId</code>
                    </li>
                    <li>
                        <code>GroupBatteryElectricVehicleId</code>
                    </li>
                    <li>
                        <code>GroupPluginHybridElectricVehicleId</code>
                    </li>
                    <li>
                        <code>GroupFuelCellElectricVehicleId</code>
                    </li>
                    <li>
                        <code>GroupInternalCombustionEngineId</code>
                    </li>
                    <li>
                        <code>GroupBiodieselId</code>
                    </li>
                    <li>
                        <code>GroupCompressedNaturalGasId</code>
                    </li>
                    <li>
                        <code>GroupDieselId</code>
                    </li>
                    <li>
                        <code>GroupEthanolId</code>
                    </li>
                    <li>
                        <code>GroupGasolinePetrolId</code>
                    </li>
                    <li>
                        <code>GroupLiquifiedNaturalGasId</code>
                    </li>
                    <li>
                        <code>GroupPropaneLiquifiedPetroleumGasId</code>
                    </li>
                    <li>
                        <code>GroupManuallyClassifiedPowertrainId</code>
                    </li>
                </ul>
            </li>
        </ul>
        <h3>HosRuleSet</h3>
        <ul>
            <li>
                Added <code>America7DayRailroad</code> and <code>America8DayRailroad</code>.
            </li>
        </ul>
        <h3>KnownIoxAddOnTypes</h3>
        <ul>
            <li>
                Added <code>NFC</code>, <code>Bluetooth</code>, and <code>UReader</code> add-on types.
            </li>
        </ul>
        <h3>Nuget package</h3>
        <ul>
            <li>
                Nuget package uses <code>HttpClient.VersionPolicy</code> <code>RequestVersionOrHigher</code>. Allowing client to use
                HTTP/2 and above. HttpClient default is HTTP/1.1.
            </li>
        </ul>
        <h3>SecurityIdentifier</h3>
        <ul>
            <li>
                Added <code>EVBatteryHealthReport</code>.
            </li>
        </ul>
        <h3>Trailer</h3>
        <ul>
            <li>Marked obsolete, but can still be used for this release.</li>
        </ul>
        <h3>TrailerAttachment</h3>
        <ul>
            <li>Marked obsolete, but can still be used for this release.</li>
        </ul>
        <h3>User</h3>
        <ul>
            <li>
                Added <code>IsAdverseDrivingEnabled</code>.
            </li>
        </ul>
        <h3>Zone</h3>
        <ul>
            <li>When adding and setting zones, points are validated to be latitude and longitude bounds. Valid Latitude -90 to 90. Valid Longitude -180 to 180.</li>
        </ul>
        <h3>ZoneSearch</h3>
        <ul>
            <li>
                <code>Viewport</code> property which was made obsolete in v5.7.2004 will be <b>removed and no longer supported in v9.0</b>. Please switch your
                application to use <code>searchArea</code> and <code>BoundingBox</code> objects as soon as possible.
            </li>
        </ul>
    </div>
);

const update7: ReactNode = (
    <div className="paragraph">
        <h2>Security updates</h2>
        <p>
            In an effort to increase application and API security, exception types that expose database provider or platform-specific error messages have been removed and are now represented as one
            of the exceptions below. Most exceptions and error messages have not changed. Exception types that were previously documented remain unchanged; however, some new exception types include
            non-specific, generalized messages to avoid sharing information about the underlying infrastructure. The following common exceptions are still supported.
        </p>
        <ul>
            <li>
                <code>ArgumentException</code>
            </li>
            <li>
                <code>ArgumentNullException</code>
            </li>
            <li>
                <code>ArgumentOutOfRangeException</code>
            </li>
            <li>
                <code>CaptchaException</code>
            </li>
            <li>
                <code>DatabaseMaintenanceException</code>
            </li>
            <li>
                <code>DbUnavailableException</code>
            </li>
            <li>
                <code>DuplicateException</code>
            </li>
            <li>
                <code>ExpiredPasswordException</code>
            </li>
            <li>
                <code>GenericException</code> <i>new</i>
            </li>
            <li>
                <code>GroupRelationViolatedException</code>
            </li>
            <li>
                <code>InvalidApiOperationException</code> <i>new</i> (formerly <code>InvalidOperationException</code>)
            </li>
            <li>
                <code>InvalidCastException</code>
            </li>
            <li>
                <code>InvalidMyAdminUserException</code>
            </li>
            <li>
                <code>InvalidPermissionsException</code>
            </li>
            <li>
                <code>InvalidUserException</code>
            </li>
            <li>
                <code>JsonSerializerException</code>
            </li>
            <li>
                <code>MissingMemberException</code>
            </li>
            <li>
                <code>MissingMethodException</code>
            </li>
            <li>
                <code>PasswordPolicyViolationException</code>
            </li>
        </ul>
        <h2>Password policies</h2>
        <ul>
            <li>
                User passwords will now be validated against a list of common passwords. If it is a common password, a <code>PasswordPolicyViolationException</code> is
                returned.
            </li>
            <li>
                User passwords will now be validated against username, first name, and last name. If it contains a username, first name, last name, a{" "}
                <code>PasswordPolicyViolationException</code> is returned. This method can no longer be disabled.
            </li>
        </ul>
        <h2>User policies</h2>
        <ul>
            <li>
                The maximum number of active sessions for a user on a single database has been lowered to 100. Active sessions are a rolling list sorted by date and time. When the number of active
                sessions reaches 100, a new session is added, and the oldest session is removed from the list (expired).
            </li>
        </ul>
        <h2>General updates</h2>
        <ul>
            <li>
                Added <code>ModifyGroupFilter</code> and <code>ViewGroupFilter</code> to{" "}
                <code>SecurityIdentifier</code>.
            </li>
            <li>
                Added <code>CaliforniaPropertyShortHaulWithRest</code>, <code>CanadaOil</code>,{" "}
                <code>CanadaNorthOf60Oil</code>, <code>CanadaOilTeam</code>, and{" "}
                <code>CanadaNorthOf60OilTeam</code> properties.
            </li>
            <li>
                Added support for fuel transaction provider, <code>WexCanada</code>.
            </li>
            <li>Minor bug fixes and package updates.</li>
        </ul>
        <h2>Coming soon</h2>
        <ul>
            <li>
                The <code>AddInData</code> legacy property <code>Data</code> will be removed in the coming 9.0 release. Please update your
                integration requests to use the <code>Details</code> property instead
            </li>
            <li>JSONP support will be removed from the API in the coming 8.0 release, and should no longer be used.</li>
        </ul>
    </div>
);

const update6: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                Changed the software version naming convention to use three parts (e.g. 6.0.0) from four parts (e.g. 5.7.2104.0). To learn more,{" "}
                <a href="https://community.geotab.com/s/feed/0D52J00008j4IghSAE?language=en_US" target="_blank" rel="noopener noreferrer">
                    click here
                </a>
                .
            </li>
            <li>
                Added <Link to="/myGeotab/apiReference/objects/WifiHotspot">WifiHotspot</Link> capability, with Interface to configure hotspot settings on telematics devices.
            </li>
            <li>
                The MyAdmin SDK is now available from <Link to="/myAdmin/introduction">the SDK</Link>. All pages are in the process of being converted to Markdown format.
            </li>
        </ul>
        <h2>HOS</h2>
        <p>
            Added <code>CanadaNorthOf60CycleOneTeam</code> and <code>CanadaNorthOf60CycleTwoTeam</code> to the rulesets.
        </p>
        <h2>Limits</h2>
        <p>Increased media file size limits to 50 MB for video and 10 MB for images.</p>
        <h2>NuGet</h2>
        <ul>
            <li>
                Fixed an issue in which the NuGet package <code>API.SessionId</code> property generates an{" "}
                <code>InvalidOperationException</code>, if accessed before it is assigned.
            </li>
            <li>
                The MyGeotab NuGet package no longer includes a reference to <code>Newtonsoft.json</code>.
            </li>
        </ul>
    </div>
);

const update2104: ReactNode = (
    <div className="paragraph">
        <h2>JSON Serializer change in 5.7.2103</h2>
        <p>
            Post-release update: it was recently uncovered within our development team that as of MyGeotab release 5.7.2103, the JSON Serializer responsible for parsing API calls has changed to no
            longer allow single quote (') usage within the call parameters. Integrators should now solely use double quotes (") for this purpose. The expected error result for single quote usage
            with this change is as follows:
        </p>
        <CodeSample
            language="javascript"
            code={`{
	"error":{
		"message":"Exception of type 'Geotab.Serialization.JsonSerializerException' was thrown.",
		"code":-32700,
		"data":{
			"id":"5b161301-f931-43e0-ba2a-46d6bb54d898",
			"type":"JsonSerializerException",
			"requestIndex":0
		},
		"name":"JSONRPCError",
		"errors":[
			{
				"message":"Exception of type 'Geotab.Serialization.JsonSerializerException' was thrown.",
				"name":"JsonSerializerException"
			}
		]
	},
	"jsonrpc":"2.0",
	"requestIndex":0
}`}
        />
        <p>
            The new Serializer logic only accepts property names and string values in double quotes because that format is required by the{" "}
            <a href="https://datatracker.ietf.org/doc/html/rfc8259" target="_blank" rel="noopener noreferrer">
                RFC8259
            </a>{" "}
            specification and is the only format considered to be valid JSON.
        </p>
        <h2>New stock groups available</h2>
        <ul>
            <li>GroupDriverActivityGroupId</li>
            <li>GroupPersonalGroupId</li>
            <li>GroupBusinessGroupId</li>
        </ul>
        <h2>Device</h2>
        <ul>
            <li>
                <code>FuelTankCapacity</code> will now throw an <code>ArgumentOutOfRangeException</code> if the value is less than 0.
            </li>
            <li>
                The <code>DevicePlans</code> property will be removed from the object model in a future version. DevicePlans does not encapsulate billing information,
                so please use the <code>DevicePlanBillingInfo</code> property from this version forward.
            </li>
            <li>
                Added the <code>DevicePlanBillingInfo</code> property to replace the <code>DevicePlans</code> property.{" "}
                <code>DevicePlanBillingInfo</code> contains more billing information than <code>DevicePlans</code>.
            </li>
        </ul>
        <h2>DeviceStatusInfoSearch</h2>
        <p>
            Fixed a bug that omitted the <code>closestAssetLimit</code> property when applying <code>closestAssetLimit</code> and{" "}
            <code>resultsLimit</code> together.
        </p>
        <h2>UserHosRuleSetSearch</h2>
        <p>
            Fixed bug that applied the wrong date when searching for <code>UserHosRuleSet</code> using both <code>fromDate</code> and{" "}
            <code>userSearch.fromDate</code>.
        </p>
        <h2>FuelTransaction</h2>
        <p>
            Added the <code>ProviderProductDescription</code> property. This property requests the non-generic product description as described by the fuel card
            provider.
        </p>
        <h2>DutyStatusViolationType</h2>
        <p>
            Added <code>EwdRest</code>, <code>EwdWork</code>, and <code>EwdWorkExemption</code>.
        </p>
        <h2>Errors</h2>
        <p>Removed provider-specific details from exception messages when a relation violation exception occurs. The exception types returned have not changed.</p>
        <h2>Defect</h2>
        <p>
            Added <code>IsHidden</code> and <code>IsRequired</code> properties.
        </p>
        <ul>
            <li>
                <code>IsHidden</code> is a boolean value indicating whether a defect is hidden in the UI. Used to determine if "other” should be shown or not.
            </li>
            <li>
                <code>IsRequired</code> is a boolean value indicating whether a defect must be signed off. Used to determine if the part must be explicitly marked as
                having defect(s) or not.
            </li>
        </ul>
    </div>
);

const update2103: ReactNode = (
    <div className="paragraph">
        <h2>JSON Serializer change</h2>
        <p>
            The JSON Serializer responsible for parsing API calls has changed to no longer allow single quote (') usage within the call parameters. Integrators should now solely use double quotes
            (") for this purpose. The expected error result for single quote usage with this change is as follows:
        </p>
        <CodeSample
            language="javascript"
            code={`{
	"error":{
		"message":"Exception of type 'Geotab.Serialization.JsonSerializerException' was thrown.",
		"code":-32700,
		"data":{
			"id":"5b161301-f931-43e0-ba2a-46d6bb54d898",
			"type":"JsonSerializerException",
			"requestIndex":0
		},
		"name":"JSONRPCError",
		"errors":[
			{
				"message":"Exception of type 'Geotab.Serialization.JsonSerializerException' was thrown.",
				"name":"JsonSerializerException"
			}
		]
	},
	"jsonrpc":"2.0",
	"requestIndex":0
}`}
        />
        <p>
            The new Serializer logic only accepts property names and string values in double quotes because that format is required by the{" "}
            <a href="https://datatracker.ietf.org/doc/html/rfc8259" target="_blank" rel="noopener noreferrer">
                RFC8259
            </a>{" "}
            specification and is the only format considered to be valid JSON.
        </p>
        <h2>Add/Set FuelTransactionPermalink</h2>
        <p>Fuel transactions must be unique when comparing all fields (excluding sourceData) against existing transactions.</p>
        <h2>CompanyDetails</h2>
        <p>
            Added <code>jurisdiction</code> property.
        </p>
        <h2>CreateDatabase</h2>
        <p>
            The CreateDatabase API now requires user-selected jurisdiction. The jurisdiction is the place of residency for Customer data, maintenance hours, and other information (e.g.U.S., EU).
            This was previously inferred from the selected timezone. To maintain backwards compatibility, timezone can still be used to infer jurisdiction. However, all users are encouraged to
            provide a jurisdiction as part of the CompanyDetails provided to the CreateDatabase API.
        </p>
        <h2>CustomVehicleDevice</h2>
        <p>
            Added <code>FuelTankCapacity</code> property.
        </p>
        <h2>DiagnosticType</h2>
        <p>
            Added <code>LevcFault</code>.
        </p>
        <h2>Drive Add-In photos</h2>
        <p>
            A new API was added to Drive Add-Ins to access the device camera to take a photo or select an exiting photo from the mobile device using{" "}
            <code>api.mobile.camera.takePicture()</code>.
        </p>
        <h2>DriverRegulation</h2>
        <p>
            Added <code>CurrentDutyStatus</code> representing the latest <code>DutyStatusLogType</code> affecting availability or
            violations.
        </p>
        <h2>DutyStatusViolationType</h2>
        <p>
            Added <code>EwdRest</code>, <code>EwdWork</code>, and <code>EwdWorkExemption</code> (formerly{" "}
            <code>Work</code> and <code>WorkExemption</code>).
        </p>
        <h2>DVIRLogSearch</h2>
        <p>
            Added <code>LogTypes</code> property for searching by list of DVIRLogType.
        </p>
        <h2>ExceptionEvent</h2>
        <p>
            Exception events can be deleted when new data arrives from a device that, when evaluated against the same rule conditions, invalidates the previous state of the exception. For example, a
            speeding exception is generated for a street with a 40mph speed limit beside a highway. As more GPS data arrives, it becomes clear the vehicle is on the highway, not the service road, so
            the exception is invalidated. This is a problem for users who continuously request ExceptionEvent data because they are unaware when an exception is invalidated, and deleted at a later
            date. To resolve this issue, two new properties have been added to ExceptionEvent; <code>lastModifiedDate</code> and{" "}
            <code>state</code>. These properties determine if the exception event is invalidated instead of deleted. This means that when a new{" "}
            <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link> request is made, the user sees the updated record and can adjust their records accordingly. Invalidated exceptions will
            no longer be removed immediately.
        </p>
        <InformationalBox>
            <p>
                NOTE: Invalidated exceptions will not be returned by default. You must pass the search parameter <code>includeInvalidated</code> in the request to{" "}
                <Link to="/myGeotab/apiReference/methods/Get">Get</Link>
                or <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link> to return invalidated exception events. The <code>state</code> of these exceptions
                will be <code>Invalid</code>.
            </p>
        </InformationalBox>
        <h2>ExceptionEventSearch</h2>
        <p>
            Added <code>includeInvalidated</code> property.
        </p>
        <h2>ExceptionEventState</h2>
        <p>
            New object representing the state of the exception event. Possible states are <code>Valid</code> and <code>Invalid</code>.
        </p>
        <h2>Generator Add-In</h2>
        <p>
            <a href="https://github.com/Geotab/generator-addin" target="_blank" rel="noopener noreferrer">
                Generator-addin
            </a>{" "}
            updated to mock drive add-in camera API features.
        </p>
        <h2>Group</h2>
        <p>
            Group objects in some instances had <code>color</code> and <code>children</code> properties partially populated when nested in
            another object (ex device.groups). This is fixed, so they are no longer populated when groups are nested in group linked entities.
        </p>
        <h2>HosRuleSet</h2>
        <p>
            Added <code>CaliforniaPropertyShortHaul</code> and <code>CaliforniaPropertyShortHaulWithRest</code>.
        </p>
        <h2>Jurisdiction</h2>
        <p>New enumeration representing the Jurisdiction of a database.</p>
        <h2>MediaType</h2>
        <p>
            Added <code>Application</code> media file type. This is to support PDF file types in MediaFiles.
        </p>
        <h2>RadioDownloader, RadioData and related objects are removed</h2>
        <p>All Radio Downloader related objects are removed as Geotab deprecates all RF functionality.</p>
        <h2>SecurityIdentifier</h2>
        <p>
            Added <code>ViewDeviceDataPrivacyChangeData</code> and <code>EditDeviceDataPrivacyChangeData</code> Added{" "}
            <code>ViewSharedDevice</code> Added <code>AdministerPropertySet</code>,{" "}
            <code>ViewPropertySet</code>, <code>AdministerProperty</code>, and <code>ViewProperty</code>{" "}
            Added <code>ViewActiveInsights</code> Added <code>IgnoreHOSLogs</code> Added{" "}
            <code>ViewShareableLink</code>, <code>CreateShareableLink</code>, and{" "}
            <code>DeleteShareableLink</code>
        </p>
        <h2>TripSearch</h2>
        <p>
            Added <code>SearchArea</code> property to allow searching for trips within a rectangular <code>BoundingBox</code> geographic
            area.
        </p>
    </div>
);

const update2102: ReactNode = (
    <div className="paragraph">
        <h2>Data Intake Gateway (DIG)</h2>
        <p>
            DIG is our new platform for integrating custom telematics data into MyGeotab. To learn more, <Link to="/myGeotab/guides/usingCustomTelematicsDevices">click here</Link>.
        </p>
        <h2>APIv1 JSON Serialization</h2>
        <p>
            To reduce the duration of process-intensive requests with large JSON payloads, the MyGeotab JSON-RPC API now uses System.Text.Json instead of Newtonsoft.JSON to serialize JSON data sent
            using the API. This change includes backward compatibility with Newtonsoft.JSON, with the following exception: Numbers with decimals will no longer be serialized using the decimal
            followed by a zero, if it is a whole number.
        </p>
        <h2>Nuget package</h2>
        <p>
            The Nuget package now targets .NET Standard 2.0,.NET Standard 2.1 and .NET 5.0. To improve serialization and deserialization performance, the Geotab.Checkmate.Objectmodel Nuget Package
            version 5.7.2102 replaced the JSON serialization library from Newtonsoft.JSON, with System.Text.Json.
        </p>
        <InformationalBox>
            <p>
                Due to the performance improvement with System.Text.Json, the existing rate limit OverLimitException may be surpassed when calling the{" "}
                <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link> API in a tight loop.
            </p>
        </InformationalBox>
        <h2>SDK site</h2>
        <p>SDK site adjusted for AODA compliance.</p>
        <h2>Generator Add-In</h2>
        <p>New Geotab Drive Add-In features start/stop, hook and notifications added to generator-addin.</p>
        <h2>General SDK updates</h2>
        <h3>DeviceSearch</h3>
        <p>
            Keywords property expanded to include <code>EngineVehicleIdentificationNumber</code>, <code>VehicleIdentificationNumber</code>{" "}
            and <code>SerialNumber</code> properties.
        </p>
        <h3>DeviceShare, DeviceShareSearch, DeviceShareType, DeviceShareSearch</h3>
        <p>
            Beta support for <code>DeviceShare</code> functionality added. This object is used for Extendable Services billing purposes.
        </p>
        <h3>DutyStatusLogType</h3>
        <p>
            Added <code>Work</code>, <code>Rest</code>, and <code>WorkExemption</code> properties.
        </p>
        <h3>ExceptionRuleBaseType</h3>
        <p>
            Route Completion displays the completion status of custom routes and roads to help users maintain compliance with service level agreements. A route is completed based on the rule and
            conditions set by the user. Route completion exceptions represent servicing activity for a set of previously defined routes, within a service group.
        </p>
        <p>
            A new <code>RouteCompletion</code> category is used to classify a rule in the Route Completion Report. Route completion rules are returned with unfiltered
            requests to the{" "}
            <code>
                Get{"<"}Rule{">"}
            </code>{" "}
            API, or with the category filter <code>UserExceptionRules</code>. They can also be searched by <code>RouteCompletion</code>.
        </p>
        <h3>FaultState</h3>
        <p>
            Added <code>FaultStates</code>. This allows faults to represent more precise and potentially multiple fault states. In the future, FaultState will be
            deprecated, though still available for backwards compatibility.
        </p>
        <h3>FaultStateProvider</h3>
        <p>
            Complements the <code>FaultStates</code> property of <code>FaultData</code>. Describes the status of a fault.
        </p>
        <h3>GetFeed</h3>
        <p>
            To comply with the <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link> contract and avoid performance loss, fixed a bug that applies both fromDate and fromVersion when both
            are supplied in the API request. When fromVersion is supplied, fromVersion will be ignored.
        </p>
        <InformationalBox>
            <p>
                This fix may return more records when both fromDate and fromVersion are supplied with before the given <code>dateTime</code> is returned.
            </p>
        </InformationalBox>
        <p>Also, fixed a bug where toVersion is returned as 0, when a search returns no results. Now, when no results are returned, ToVersion is returned as the latest Feed version.</p>
        <h3>HosRuleSet</h3>
        <p>
            Added <code>StandardHoursSoloExemptionHours</code>.
        </p>
        <h3>RoutePlanItem</h3>
        <p>
            Added <code>PassCount</code>. The expected number of passes through the Zone.
        </p>
        <h3>RouteSearch</h3>
        <p>
            Added <code>Groups</code> search option to allow searches for Route Completion routes (<code>RouteType.Service</code>) that
            are members of <code>GroupSearch</code>(s). Only returns routes that are members of a service group hierarchy.
        </p>
        <h3>RouteType</h3>
        <p>
            Added <code>Service</code> route type.
        </p>
        <h3>SecurityIdentifier</h3>
        <p>
            Added <code>RouteCompletionReport</code>.
        </p>
        <h3>UserSearch</h3>
        <p>
            Added <code>UserSearchType</code> property to address IsDriver search limitation for Driver or Drivers, and Users. UserSearch allows searching for drivers
            and users, users who are not drivers, and only users who are drivers. <code>IsDriver</code> will be deprecated but remain backwards compatible.
        </p>
        <h3>UserSearchType</h3>
        <p>
            Added values to the <code>UserSearch</code>, <code>UserSearchType</code> properties.
        </p>
    </div>
);

const update2101: ReactNode = (
    <div className="paragraph">
        <h2>Map Add-In</h2>
        <p>
            Map Add-Ins are now fully supported, and no longer in Feature Preview. <Link to="/myGeotab/addIns/mapAddIns">Click here to learn more about Map Add-Ins</Link>
        </p>
        <h2>Storage API</h2>
        <p>
            Storage APIs are now fully supported, and no longer in Feature Preview. <Link to="/myGeotab/addIns/addInStorage">Click here to learn more about Storage APIs</Link>
        </p>
        <h2>General SDK updates</h2>
        <h3>DeviceStatusInfo</h3>
        <p>
            The dates of GPS, status and fault records are compared and uses the latest recorded data point as the <code>DateTime</code>.
        </p>
        <h3>AddInData</h3>
        <p>
            The <code>Set</code> method will now allow modifying a value with no groups assigned.
        </p>
        <h3>CreateDatabase</h3>
        <p>
            Added a rate limit to the <code>CreateDatabase</code> method: 15/1m, 100/1h, 800/1d.
        </p>
        <h3>Device</h3>
        <p>Added support for Untracked Assets. This allows adding devices that do not have a serial number.</p>
        <h3>DutyStatusLog</h3>
        <ul>
            <li>
                Added the <code>IsTransitioning</code> property indicating whether an HOS log is in transition after the first driver accepts it.
            </li>
            <li>
                Added the <code>IsHidden</code> property.
            </li>
        </ul>
        <h3>DutyStatusLogType</h3>
        <ul>
            <li>
                Added <code>CanadaCycleOne</code>, <code>CanadaCycleTwo</code>,{" "}
                <code>OperatingZoneCanadaSouthOf60</code>, <code>OperatingZoneCanadaNorthOf60</code>,{" "}
                <code>OperatingZoneAmerica</code> and <code>INT_CoDriver</code>.
            </li>
        </ul>
        <h3>DutyStatusViolationSearch</h3>
        <p>
            The <code>DutyStatusViolationSearch</code> method can now search by user company or driver groups.
        </p>
        <h3>DVIRLog</h3>
        <p>
            Updated the documentation for Canada-specific fields on DVIRLogs (<code>LoadHeight</code>, <code>LoadWidth</code>, and{" "}
            <code>Odometer</code>) to better describe how they are populated.
        </p>
        <h3>FaultState</h3>
        <p>
            Added <code>Inactive</code>, <code>PendingOff</code>, <code>ActiveOff</code>,{" "}
            <code>InactiveOff,</code> and <code>Cleared</code>.
        </p>
        <h3>Get:DutyStatusLog</h3>
        <p>Fixed bug getting latest log for all users</p>
        <h3>Get:StatusData</h3>
        <p>
            Users can now extrapolate the status date for diagnostics using the unit of measure <code>None</code> when{" "}
            <Link to="/myGeotab/apiReference/methods/Get">Get</Link> is used with search (device, diagnostic, from and to date).
        </p>
        <h3>GetFeed:DeviceStatusInfo</h3>
        <p>
            Added <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link> for <code>DeviceStatusInfo</code>.
        </p>
        <h3>MessageContentType</h3>
        <p>
            Added <code>MimeContent</code> to <code>MessageContentType</code>.
        </p>
        <h3>MimeContent</h3>
        <p>Fixed documentation of maximum size.</p>
        <h3>RuleSearch</h3>
        <p>Fixed a bug getting zone stop rules.</p>
        <h3>SDK Runner</h3>
        <p>Fixed a UI bug rendering JSON, causing it to fail on empty object.</p>
        <h3>Sdk-Addin-Samples:Proximity</h3>
        <ul>
            <li>
                Removed ResultsLimit of 1000 for the{" "}
                <code>
                    Get{"<"}Device{">"}
                </code>{" "}
                request.
            </li>
            <li>
                <code>
                    Get{"<"}Device{">"}
                </code>{" "}
                request now accepts wildcard searches.
            </li>
            <li>Added a Run, Select All and Deselect All button.</li>
            <li>Updated warning messages if an input is missing or invalid when a user clicks Run.</li>
            <li>Updated minor UI aesthetics.</li>
        </ul>
        <h3>Sdk-Map-Addin-Samples</h3>
        <p>Added a new sample illustrating tooltip which displays the odometer, fuel level, and battery charge level (if applicable) of a vehicle.</p>
        <h3>SecurityIdentifier</h3>
        <p>
            Added <code>ViewDeviceShare</code>, <code>ViewDeviceShare</code>, <code>InstallRecord</code>,{" "}
            <code>ViewDeviceShare</code>, <code>ViewDeviceShare</code>, <code>ViewUserDeviceLink</code>, and{" "}
            <code>ViewUserDeviceLink</code>.
        </p>
        <h3>VersionInformation</h3>
        <p>
            Added the <code>ServerId</code> property, a unique identifier for a server/cluster.
        </p>
    </div>
);

const update2004: ReactNode = (
    <div className="paragraph">
        <h2>New media file API</h2>
        <p>Geotab is happy to announce a new set of APIs related to Media Files. This new API can be used to store images or video clips related to a device or driver.</p>
        <p>
            <Link to="/myGeotab/apiReference/objects/MediaFile">MediaFile</Link>: MediaFile is a new type used to store images or video clips related to a device or driver. More information about
            media files can be found{" "}
            <a href="https://github.com/Geotab/mg-media-files" target="_blank" rel="noopener noreferrer">
                here
            </a>
            .
        </p>
        <p>
            <Link to="/myGeotab/apiReference/objects/MediaFile">MediaFile</Link>: The type of Media.
        </p>
        <p>
            <Link to="/myGeotab/apiReference/objects/Status">Status</Link>: The status of an uploaded file.
        </p>
        <p>
            <Link to="/myGeotab/apiReference/objects/MediaFileSearch">MediaFileSearch</Link>: The object used to specify the arguments when searching for MediaFile. This will return the data
            describing a file, not the actual file.
        </p>
        <p>
            <Link to="/myGeotab/apiReference/objects/Tag">Tag</Link>: A named tag to provide context to an entity.
        </p>
        <p>
            <Link to="/myGeotab/apiReference/methods/DownloadMediaFile">DownloadMediaFile</Link>: Download a file for the given MediaFile. The Content type is determined by the file extension. Range
            headers are supported.
        </p>
        <p>
            <Link to="/myGeotab/apiReference/methods/UploadMediaFile">UploadMediaFile</Link>: Upload a file for the corresponding MediaFile using multipart/form-data POST request.
        </p>
        <p>
            <Link to="/myGeotab/apiReference/objects/SecurityIdentifier">SecurityIdentifier</Link>: Added ViewMedia and ManageMedia.
        </p>
        <h2>General SDK updates</h2>
        <h3>BinaryDataType</h3>
        <p>
            Added <code>ThirdPartyData</code> type to allow flexible length binary data format records to be stored.
        </p>
        <h3>Methods</h3>
        <p>
            <Link to="/myGeotab/apiReference/methods/GetCountOf">GetCountOf</Link> method now accounts for user scope. It previously did not account for user scope, which was a bug.
        </p>
        <h3>ZoneSearch</h3>
        <p>
            <code>Viewport</code> is obsolete and no longer officially supported. It is replaced with <code>SearchArea</code> property.
            This will be better represented by the type <code>BoundingBox</code>. Providing a bounding box is simpler to use because map libraries provide viewport/map
            bounds in this way already. Backwards compatibility will be maintained with the <code>Viewport</code> property, though no longer documented.
        </p>
        <h3>BoundingBox</h3>
        <p>
            Added <code>BoundingBox</code> which represents a geographic area defined by the top-left and bottom-right coordinates.
        </p>
        <h3>DiagnosticSearch</h3>
        <p>Added searching by diagnostic name.</p>
        <h3>FaultDataSearch</h3>
        <p>Added searching by Diagnostic Code, Diagnostic Name, Diagnostic Source Name, Diagnostic Source Id, FaultState and Controller Id.</p>
        <h3>Generator-Addin</h3>
        <p>
            Added groups filter to{" "}
            <a href="https://github.com/Geotab/generator-addin" target="_blank" rel="noopener noreferrer">
                generator-addin
            </a>
            .
        </p>
        <h3>HOSRuleSet</h3>
        <p>
            <b>Added</b>: <code>America7DaySleeper</code>, <code>America7DayBigSleeper</code>,{" "}
            <code>America8DaySleeper</code>, <code>America8DayBigSleeper</code>,{" "}
            <code>OilTransport7DaySleeper</code>, <code>OilTransport7DayBigSleeper</code>,{" "}
            <code>OilTransport8DaySleeper</code>, <code>OilTransport8DayBigSleeper</code>,{" "}
            <code>America7DayNo34hSleeper</code>, <code>America8DayNo34hSleeper</code>,{" "}
            <code>AmericaNoRestRequirement7DaySleeper</code>, <code>AmericaNoRestRequirement7DayBigSleeper</code>,{" "}
            <code>AmericaNoRestRequirement8DaySleeper</code>, <code>AmericaNoRestRequirement8DayBigSleeper</code>,{" "}
            <code>OilWell7DaySleeper</code>, <code>OilWell7DayBigSleeper</code>,{" "}
            <code>OilWell8DaySleeper</code>, <code>OilWell8DayBigSleeper</code>,{" "}
            <code>OilTransportNoRestRequirement7DaySleeper</code>, <code>OilTransportNoRestRequirement7DayBigSleeper</code>,{" "}
            <code>OilTransportNoRestRequirement8DaySleeper</code>, <code>OilTransportNoRestRequirement8DayBigSleeper</code>,{" "}
            <code>OilWellNoRestRequirement7DaySleeper</code>, <code>OilWellNoRestRequirement7DayBigSleeper</code>,{" "}
            <code>OilWellNoRestRequirement8DaySleeper</code>, <code>OilWellNoRestRequirement8DayBigSleeper</code>,{" "}
            <code>AlaskaProperty7DaySleeper</code>, <code>AlaskaProperty8DaySleeper</code>
        </p>
        <h3>Removed BETA attribute on the following</h3>
        <ul>
            <li>AnnotationLog</li>
            <li>AnnotationLogSearch</li>
            <li>ApplicationVersionInformation</li>
            <li>DefectRemark</li>
            <li>DefectSeverity</li>
            <li>DutyStatusAvailability</li>
            <li>DutyStatusAvailabilitySearch</li>
            <li>DutyStatusLog</li>
            <li>DutyStatusLogSearch</li>
            <li>DutyStatusLogType</li>
            <li>DutyStatusMalfunctionTypes</li>
            <li>DutyStatusOrigin</li>
            <li>DutyStatusState</li>
            <li>DutyStatusViolation</li>
            <li>DutyStatusViolationSearch</li>
            <li>DutyStatusViolationType</li>
            <li>DVIRDefect</li>
            <li>DVIRDefectSearch</li>
            <li>DVIRLog</li>
            <li>DVIRLogSearch</li>
            <li>DVIRLogType</li>
            <li>DtcClass</li>
            <li>DtcSeverity</li>
            <li>ElectricEnergyEconomyUnit</li>
            <li>ElectricEnergyUnit</li>
            <li>HosRuleSet</li>
            <li>InvalidMyAdminUserException</li>
            <li>RepairStatusType</li>
            <li>ShipmentLog</li>
            <li>ShipmentLogSearch</li>
            <li>TextMessageContentType</li>
            <li>Trailer</li>
            <li>TrailerAttachment</li>
            <li>TrailerAttachmentSearch</li>
            <li>TrailerSearch</li>
            <li>VersionInformation</li>
            <li>GetVersionInformation</li>
            <li>GetFeed:Audit</li>
            <li>GetFeed:Device</li>
            <li>GetFeed:Diagnostic</li>
            <li>GetFeed:DriverChange</li>
            <li>GetFeed:Route</li>
            <li>GetFeed:Rule</li>
            <li>GetFeed:TextMessage</li>
            <li>GetFeed:TrailerAttachment</li>
            <li>GetFeed:Driver</li>
            <li>GetFeed:Zone</li>
        </ul>
        <h2>Java SDK (Feature Preview)</h2>
        <p>
            We work hard to create fast and flexible tools that make sense for your business, and your feedback is an essential part of that process. With this in mind, we are previewing our new
            Java SDK, and we want you to tell us how we did! So go ahead - test the kit, join our Community Developer Discussions to help us improve our product, and get to know our users.
        </p>
        <p>
            The Java SDK offers an easy way to integrate MyGeotab into Java software. All communication with Geotab services is accomplished using HTTPS with serialized data in JSON format. The Java
            library provides Java objects representing MyGeotab entities and automatically handles their JSON serialization and deserialization.
        </p>
        <p>The Java SDK is available as a Maven Dependency Library from the Maven Central Repository and includes documentation with information and usage samples for your new kit.</p>
        <p>You can find Java-based API usage samples at https://github.com/Geotab/sdk-java-samples</p>
        <p>Samples include:</p>
        <p>
            <Link to="/myGeotab/apiReference/methods/Get">Get</Link> Logs for a given vehicle between a range of dates. Send Text Messages to and from a GO device Import Groups includes a console
            example that is also a Group import tool. The sample enables a one-time import of groups to a database from a CSV file. Import Devices includes console example that imports devices from
            a CSV file. Import Users includes a console example that imports users from a CSV file. Get Data Feed includes an example for retrieving GPS, StatusData and FaultData as a feed, and for
            exporting to CSV file.
        </p>
        <p>Supported Methods include:</p>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/methods/Authenticate">Authenticate</Link>
            </li>
            <li>
                <Link to="/myGeotab/apiReference/methods/Get">Get</Link>
            </li>
            <li>
                <Link to="/myGeotab/apiReference/methods/Add">Add</Link>
            </li>
            <li>
                <Link to="/myGeotab/apiReference/methods/Set">Set</Link>
            </li>
            <li>
                <Link to="/myGeotab/apiReference/methods/Remove">Remove</Link>
            </li>
            <li>
                <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link> (LogRecord, StatusData, FaultData, Trip)
            </li>
            <li>
                <Link to="/myGeotab/apiReference/methods/GetCountOf">GetCountOf</Link>
            </li>
        </ul>
        <p>Supported Objects include:</p>
        <ul>
            <li>Id</li>
            <li>Entity</li>
            <li>EntityWithVersion</li>
            <li>NameEntity</li>
            <li>NameEntityWithVersion</li>
            <li>LoginResult</li>
            <li>Credentials</li>
            <li>Coordinate</li>
            <li>Color</li>
            <li>Controller</li>
            <li>ControllerSearch</li>
            <li>Device (all types)</li>
            <li>DeviceSearch</li>
            <li>Diagnostic</li>
            <li>DiagnosticSearch</li>
            <li>DataDiagnostic</li>
            <li>DiagnosticType</li>
            <li>EngineType</li>
            <li>EngineTypeSearch</li>
            <li>FailureMode</li>
            <li>FailureModeSearch</li>
            <li>FaultData</li>
            <li>FaultDataSearch</li>
            <li>FlashCode</li>
            <li>Group</li>
            <li>GroupSearch</li>
            <li>IoxAddOn</li>
            <li>IoxAddOnSearch</li>
            <li>LogRecord</li>
            <li>LogRecordSearch</li>
            <li>ParameterGroup</li>
            <li>ParameterGroupSearch</li>
            <li>Source</li>
            <li>SourceSearch</li>
            <li>StatusData</li>
            <li>StatusDataSearch</li>
            <li>TextMessage</li>
            <li>TextMessageSearch</li>
            <li>TextMessageContentType</li>
            <li>Trip</li>
            <li>TripSearch</li>
            <li>UnitOfMeasure</li>
            <li>UnitOfMeasureSearch</li>
            <li>User</li>
            <li>Driver</li>
            <li>UserSearch</li>
            <li>WorkTime</li>
            <li>WorkTimeDetail</li>
            <li>WorkTimeHolidayGroupId</li>
            <li>WorkTimeSearch</li>
            <li>DefectSeverity</li>
            <li>DeviceType</li>
            <li>DiagnosticType</li>
            <li>DtcClass</li>
            <li>DtcSeverity</li>
            <li>ElectricEnergyEconomyUnit</li>
            <li>FaultLampState</li>
            <li>FaultResetMode</li>
            <li>FaultState</li>
            <li>FuelEconomyUnit</li>
            <li>GoogleMapStyle</li>
            <li>GoTalkLanguage</li>
            <li>HosOption</li>
            <li>HosRuleSet</li>
            <li>MessageContentType</li>
            <li>OpenStreetMapStyle</li>
            <li>SecurityIdentifier</li>
            <li>SecurityFilter</li>
            <li>ZoneDisplayMode</li>
            <li>MapView</li>
            <li>FeedResult</li>
            <li>DbUnavailableException</li>
            <li>DuplicateException</li>
            <li>GroupRelationViolatedException</li>
            <li>InvalidMyAdminUserException</li>
            <li>InvalidUserException</li>
            <li>OverLimitException</li>
            <li>RegistrationException</li>
            <li>JsonRpcError</li>
            <li>JsonRpcErrorData</li>
        </ul>
    </div>
);

const update2003: ReactNode = (
    <div className="paragraph">
        <h2>General improvements</h2>
        <p>
            JSON serialization improvements have been made to increase the efficiency of API calls. This is especially noticeable on API calls with large response payload. For example, calling{" "}
            <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link> of <code>StatusData</code> with full payload (50,000 results), the average end to end time
            decreased from 1800 ms to 800 ms.
        </p>
        <h2>TextMessage and TextMessageSearch</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/TextMessage">TextMessage</Link> - Added <code>Recipient</code>. This property is used to send a text message to
                a user.
            </li>
            <li>
                <Link to="/myGeotab/apiReference/objects/TextMessageSearch">TextMessageSearch</Link> - Added searching by <code>IsDelivered</code>,{" "}
                <code>IsRead</code>, <code>UserSearch</code>.
                <ul>
                    <li>
                        <code>IsDelivered</code>, when set to true, returns all text messages that were delivered to the recipient/device.
                    </li>
                    <li>
                        <code>IsRead</code>, when set to true, returns all text messages that were read by the recipient/device.
                    </li>
                    <li>
                        <code>UserSearch</code> searches TextMessages from a user, and users in the specified <code>CompanyGroups</code>{" "}
                        or <code>DriverGroups</code>.
                    </li>
                </ul>
            </li>
            <li>
                <Link to="/myGeotab/apiReference/objects/TextMessageSearch">TextMessageSearch</Link> - Added searching by <code>ContentTypes</code> and{" "}
                <code>IsDirectionToVehicle</code>.
                <ul>
                    <li>
                        <code>ContentTypes</code> searches for TextMessages based on their MessageContentType.
                    </li>
                    <li>
                        <code>IsDirectionToVehicle</code>, when set to true, will return all text messages that were sent to the device. If set to false, it will return
                        all text messages that were not sent to the device.
                    </li>
                </ul>
            </li>
        </ul>
        <h2>SecurityIdentifier</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/SecurityIdentifier">SecurityIdentifier</Link> - <code>PerformanceReport</code> has been removed.
            </li>
        </ul>
        <h2>Exception messages</h2>
        <p>Some exception messages contained escaped Unicode characters. We have fixed these to exclude escaped characters. See the example message change below:</p>
        <InformationalBox>
            <p>The method \u0022NotAMethod\u0022 could not be found. Verify the method name and ensure all method parameters are included</p>
        </InformationalBox>
        <InformationalBox>
            <p>The method 'NotAMethod' could not be found. Verify the method name and ensure all method parameters are included</p>
        </InformationalBox>
        <p>
            This fix applies to messages of exception types <code>MissingMethodException</code>, <code>AmbiguousMatchException</code>,{" "}
            <code>MissingMemberException</code> and <code>JsonSerializationException</code>.
        </p>
        <h2>DiagnosticType</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/DiagnosticType">DiagnosticType</Link> - Added <code>GmcccFault</code> and{" "}
                <code>BrpFault</code>
            </li>
        </ul>
        <h2>KnownId</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/KnownId">KnownId</Link> - Added <code>ControllerGmcccFaultId</code>,{" "}
                <code>SourceGmcccId</code>, <code>SourceGmcccObsoleteId</code>,{" "}
                <code>ControllerBrpFaultId</code>, <code>SourceBrpId</code>,{" "}
                <code>SourceBrpObsoleteId</code>
            </li>
            <li>
                <Link to="/myGeotab/apiReference/objects/KnownId">KnownId</Link> - Added <code>NoExceptionEventId</code>,{" "}
                <code>NoRuleId</code>
            </li>
        </ul>
        <h2>Device</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/Device">Device</Link> - Added <code>AutoHos</code>. This property is a toggle that represents automatic
                generation of DutyStatusLogs for a <code>GoDevice</code> and/or a <code>CustomVehicleDevice</code>.
            </li>
        </ul>
        <h2>DutyStatusViolation</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/DutyStatusViolation">DutyStatusViolation</Link> - Added <code>HoursLimit</code> and{" "}
                <code>DaysLimit</code>. These properties show the maximum or minimum hours and/or days limit for duty status violations.
            </li>
            <li>
                <Link to="/myGeotab/apiReference/objects/DutyStatusViolation">DutyStatusViolation</Link> - Deprecated <code>Reason</code> property. This will be removed
                in a future version. The data in the Reason property string is now provided as <code>DaysLimit</code> and{" "}
                <code>HoursLimit</code> for better programmatic access.
            </li>
        </ul>
        <h2>UserSearch</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/UserSearch">UserSearch</Link> - Added searching by <code>LicenseNumber</code>,{" "}
                <code>EmployeeNumber</code>, <code>HosRuleSet</code> and <code>UserAuthenticationType</code>
                .
            </li>
        </ul>
        <h2>GetFeed DebugData</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link>: <Link to="/myGeotab/apiReference/objects/DebugData">DebugData</Link> - Fixed sort order issue leading to possible
                missed records.
            </li>
        </ul>
        <h2>FuelTransaction</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/FuelTransaction">FuelTransaction</Link> - Added <code>Device</code> and{" "}
                <code>Driver</code>. These properties add fuel transactions for a device or user, rather than a loose match by VIN, etc. If left null, the application
                will attempt to match the fuel transaction to a device and driver at time of an Add or a Set. If no match is found, the fuel transaction's{" "}
                <code>Device</code> and <code>Driver</code> properties defaults to NoDevice and UnknownDriver.
            </li>
        </ul>
        <h2>DVIRLog</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/DVIRLog">DVIRLog</Link> - Added <code>AuthorityName</code>,{" "}
                <code>AuthorityAddress</code>, <code>Odometer</code>, <code>LoadHeight</code>,{" "}
                <code>LoadWidth</code> and <code>IsInspectedByDriver</code>. These properties support Canadian DVIR inspections.
                AuthorityName and AuthorityAddress are automatically populated based on what the user's corresponding fields are at the time. Odometer currently only applies to the entered{" "}
                <code>Hubometer</code> value for Trailer DVIRs.
            </li>
        </ul>
        <h2>ConditionType</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/ConditionType">ConditionType</Link> - Added <code>IsValueLessThanPercent</code> and{" "}
                <code>IsValueMoreThanPercent</code>. These properties are used to create a percentage threshold for speeding violations, rather than an exact speed
                value under/over the current posted road speed.
            </li>
        </ul>
        <h2>WebServerInvoker (NuGet only)</h2>
        <p>
            This method has been changed to use generics instead of passing type in, and returning an object, that needs to be cast. For example,{" "}
            <code>var version = (string)(await invoker.InvokeAsync("GetVersion", typeof(string)));</code> is now{" "}
            <code>
                var version = await invoker.InvokeAsync{`<`}string{`>`}("GetVersion");
            </code>
        </p>
        <InformationalBox>
            <p>
                While not an officially supported component, it's possible <code>WebServerInvoker</code> is being used by some integrations. For this reason we thought
                it worth mentioning this change.
            </p>
        </InformationalBox>
    </div>
);

const update2002: ReactNode = (
    <div className="paragraph">
        <h2>NuGet</h2>
        <p>
            <span>! IMPORTANT</span>: A bug has been identified with Geotab.Checkmate.Objectmodel NuGet packages older than version 5.7.2002, which can lead to serialization errors when a previous
            version received a new device plan value. Please update to the latest NuGet package to establish compatibility.
        </p>
        <h2>Map Add-In</h2>
        <p>
            Users can now create a <Link to="/myGeotab/addIns/mapAddIns/">Map Add-In</Link> without using the view panel on the right. For quick tasks such as adding icons or text to the Map, simply
            use the <code>"noView":true</code> parameter in your configuration file.
        </p>
        <CodeSample
            language="javascript"
            code={`{
	"page": "map",
	"noView": true,
	"title": "Some title",
	"mapScript": {
		"script": "..."
	}
}`}
        />
        <p>You can now hide Vehicle State and Groups information from the tooltip when hovering or selecting vehicles on the Map. See the example below.</p>
        <CodeSample
            language="javascript"
            code={`service.tooltip.setConfig({
	device: {
		state: false,
		groups: false
	}
});`}
        />
        <h2>Interpolation</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/methods/Get">Get</Link>: <Link to="/myGeotab/apiReference/objects/StatusData">StatusData</Link>,{" "}
                <Link to="/myGeotab/apiReference/objects/LogRecord">LogRecord</Link> - In the v5.7.2001 release and earlier, we interpolate between points when using{" "}
                <Link to="/myGeotab/apiReference/objects/StatusData">StatusData</Link> and <Link to="/myGeotab/apiReference/objects/LogRecord">LogRecord</Link> API. When a date is requested that is
                less than or greater than the data, we return the first/last value with the date of the time requested. To minimize confusion, we now return the <i>first/last</i> value with the{" "}
                <i>correct</i> <code>dateTime</code>.
            </li>
        </ul>
        <h2>Users</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/User">User</Link> - Added the <code>IsExemptHOSEnabled</code> property to indicate whether the user is allowed
                to use HOS Personal Conveyance.
            </li>
            <li>
                <Link to="/myGeotab/apiReference/objects/User">User</Link> - Added <code>CompanyName</code>, <code>CompanyAddress</code>,
                and <code>CarrierNumber</code> properties to store company and carrier information.
            </li>
            <li>
                <Link to="/myGeotab/apiReference/objects/User">User</Link> - Added <code>CountryCode</code>, <code>PhoneNumber</code>, and{" "}
                <code>PhoneNumberExtension</code> properties to assign a phone number to a selected user.
            </li>
        </ul>
        <h2>Drivers</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/Driver">Driver</Link> - Added <code>LicenseProvince</code> and{" "}
                <code>LicenseNumber</code> properties.
            </li>
            <li>
                <Link to="/myGeotab/apiReference/objects/DriverRegulation">DriverRegulation</Link> - Added <code>RestBreakNeeded</code>,{" "}
                <code>OffDutyNeeded</code>, <code>DaySummaries</code>, <code>WorkdaySummaries</code> and{" "}
                <code>CycleSummaries</code> properties to DriverRegulation.
            </li>
        </ul>
        <h2>DutyStatusLog</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/DutyStatusLog">DutyStatusLog</Link> - Added <code>DeferralStatus</code>, and{" "}
                <code>DeferralMinutes</code> properties to define the duty status deferral and deferral minutes.
            </li>
            <li>
                <Link to="/myGeotab/apiReference/objects/DutyStatusLogType">DutyStatusLogType</Link> - Added the <code>PC_Exempted</code> property to indicate the
                status of a driver.
            </li>
        </ul>
        <h2>DVIRLog</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/DVIRLog">DVIRLog</Link> - Added <code>LogType</code> and <code>DefectList</code>{" "}
                properties.
            </li>
            <li>
                <Link to="/myGeotab/apiReference/objects/DVIRLogType">DVIRLogType</Link> - Most DVIRs are performed as either Pre or Post-trip inspections. To include middle-of-the day scenarios
                such as discovering new defects, or performing additional inspections, we have added a new <code>Intrip</code> inspection type.
            </li>
        </ul>
        <h2>Rules</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/RecipientType">RecipientType</Link> - Added <code>HosEnabled</code> and{" "}
                <code>HosDisabled</code> to <code>RecipientType</code> to automate HosEnabled/HosDisabled duty status logs using rule
                notifications. For example, when an exception event occurs, add an HosEnabled or HosDisabled duty status log at the same time as the event for an unidentified driver.
            </li>
            <li>
                <Link to="/myGeotab/apiReference/objects/ConditionType">ConditionType</Link> - Added <code>NoPreDVIRCheck</code> and{" "}
                <code>NoPostDVIRCheck</code> to <code>ConditionType</code> when no Pre or Post-trip DVIR is performed between work days.
            </li>
            <li>
                <Link to="/myGeotab/apiReference/objects/ConditionType">ConditionType</Link> - Added <code>SpeedLimitAsMeasurement</code> property to the{" "}
                <code>ConditionType</code> to create rules that only apply to posted road speeds that are greater than, or less than a specified value. For example, it
                may be more important to alert the driver when the vehicle is travelling less than 10mph, or greater than 10mph on a highway, than it is on a city street.
            </li>
            <li>
                <Link to="/myGeotab/apiReference/objects/ConditionType">ConditionType</Link> - The <code>NoDVIRCheck</code>{" "}
                <code>ConditionType</code> is obsolete and will be removed in a future version. Please use NoPreDVIRCheck and NoPostDVIRCheck.
            </li>
        </ul>
        <h2>Zones</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/Zone">Zone</Link> - Added the <code>ZoneTypes</code> property for enumeration of zone types for a given zone.
            </li>
        </ul>
        <h2>Devices</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/Go9">GO9</Link> - Added the <code>ObdAlertEnabled</code> property to allow users to enable/disable OBD alerts
                on their vehicles.
            </li>
            <li>
                <Link to="/myGeotab/apiReference/objects/GoDevice">GoDevice</Link> - Added the <code>ParameterVersionOnDevice</code> property to track the current
                parameter version on the device. The current <code>ParameterVersion</code> property communicates the parameter version to the device; however, parameter
                updates are not always immediate.
            </li>
            <li>
                <Link to="/myGeotab/apiReference/objects/Device">Device</Link> - To prevent mismatches based on system clock settings, we have prevented{" "}
                <code>ActiveFrom</code> from being greater than <code>ActiveTo</code> when adding a device.
            </li>
        </ul>
        <h2>Generator-Addin version 3.0</h2>
        <p>We have modernized the Add-In scaffolding, development and packaging tool to use more current techniques and features:</p>
        <ul>
            <li>Now using webpack.</li>
            <li>Now using Puppeteer for browser testing.</li>
            <li>UI now shows a collapsible navbar.</li>
            <li>Can now toggle multi-language support.</li>
            <li>Can now toggle blur and focus events to simulate leaving and re-visiting the Add-In page.</li>
            <li>
                For more information visit GitHub:{" "}
                <a href="https://github.com/Geotab/generator-addin" target="_blank" rel="noopener noreferrer">
                    https://github.com/Geotab/generator-addin
                </a>
            </li>
        </ul>
        <h2>Mg-Api-Js version 2.0</h2>
        <p>This major release merges the API wrappers mg-api-js (previously browser only version) and mg-api-node (previously Nodejs only version) into a single project:</p>
        <ul>
            <li>Uses single js library for nodejs or browser.</li>
            <li>Supports Async promises and legacy callback behavior.</li>
            <li>Simplifies authentication process, no more hard-to-understand callbacks.</li>
            <li>Optional lower-level control over http response.</li>
            <li>
                For more information visit GitHub:{" "}
                <a href="https://github.com/Geotab/mg-api-js" target="_blank" rel="noopener noreferrer">
                    https://github.com/Geotab/mg-api-js
                </a>
            </li>
        </ul>
        <h2>Other SDK updates</h2>
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/BinaryDataSearch">BinaryDataSearch</Link> - Search by <code>DeviceSearch.Groups</code> property using{" "}
                <code>BinaryDataSearch</code>.
            </li>
            <li>
                <Link to="/myGeotab/apiReference/objects/SecurityIdentifier">SecurityIdentifier</Link> - Added <code>SystemSettings</code> value to{" "}
                <code>SecurityIdentifier</code>.
            </li>
            <li>
                Removed the <code>DiagnosticCategory</code> object from the <Link to="/myGeotab/apiReference/methods">SDK reference</Link> page. This is a legacy object
                that is no longer in use.
            </li>
            <li>Updated the ExternalDeviceShutdownDelay documentation to clarify values in minutes rather than seconds.</li>
            <li>
                Added a sample for getting fuel tax details using the <a href="/sdk/software/api/runner.html#sample:get-fuel-tax-details">API runner</a>.
            </li>
            <li>
                Feature Preview items now marked as Beta in <Link to="/myGeotab/apiReference/methods">SDK reference</Link>.
            </li>
            <li>
                Added a hardware{" "}
                <a href="https://docs.google.com/spreadsheets/d/1sy7IOhWmFoo40_I-ruOJO8bVTMLXqHa11d0oJtaoIcE/edit#gid=1133172080" target="_blank" rel="noopener noreferrer">
                    Add-On Data Types
                </a>{" "}
                section to the SDK.
            </li>
        </ul>
    </div>
);

const update2001: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    AddInData (Feature Preview) - Remove requirement of <code>AddInDataId</code> for search by <code>Id</code>.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/AuditSearch">AuditSearch</Link>, <Link to="/myGeotab/apiReference/objects/DeviceSearch">DeviceSearch</Link>,{" "}
                    <Link to="/myGeotab/apiReference/objects/ShipmentLogSearch">ShipmentLogSearch</Link>, <Link to="/myGeotab/apiReference/objects/UserSearch">UserSearch</Link>,{" "}
                    <Link to="/myGeotab/apiReference/objects/ZoneSearch">ZoneSearch</Link> - Added new search by list of <code>Keywords</code>. This allows searching
                    "or” across multiple wildcard searchable string fields of an object in one request. For example, searching for device with keywords will search for matches against{" "}
                    <code>Comment</code>, <code>LicensePlate</code>, <code>Name</code>,{" "}
                    <code>SerialNumber</code> and <code>VehicleIdentificationNumber</code> matching the provided keywords. Keywords
                    strings support wildcard character (<code>%</code>).
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/BinaryData">BinaryData</Link> (nuget only) - Fix issue deserializing enum values known to the server but unknown to older nuget package.
                </p>
            </li>
            <li>
                <p>
                    Calculated Engine Hours Search - As mentioned in 5.7.1904 What's New, <code>DiagnosticEngineHoursAdjustmentId</code> is now interpolated using trips
                    and <code>DiagnosticIgnitionId</code> values when a search includes a from/toDate value(s) to provide exact values by default.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/CompanyDetails">CompanyDetails</Link> - Add documentation describing field length limits. Added more specific error messages relating to
                    max field lengths from <code>CreateDatabase</code> method.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/Device">Device</Link> - <code>HardwareId</code> is no longer returned as part of Device object. For more
                    information regarding this change, please refer to this{" "}
                    <a href="https://community.geotab.com/s/question/0D52J00007MIPRYSA5/sdk-notice-removal-of-device-property" target="_blank" rel="noopener noreferrer">
                        community post
                    </a>
                    .
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DVIRDefect">DVIRDefect</Link> - Providing <code>RepairUser</code> and{" "}
                    <code>RepairDateTime</code> are no longer supported for unrepaired <code>DVIRDefect</code>.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DVIRLog">DVIRLog</Link> - <code>DefectList</code> must be provided with{" "}
                    <code>DVIRLog</code>.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link> - Fixed corner case where it was possible to miss data in feed due to concurrency issue.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link> <code>StatusData</code> - Fix, providing a search to GetFeed{" "}
                    <code>StatusData</code> containing a <code>DiagnosticSearch</code> which has no results within the provided limit of
                    records will now return a feed version advanced by the results limit or remaining records when less then results limit.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/methods/Get">Get</Link> <code>Diagnostic</code> - Fix issue searching by{" "}
                    <code>DiagnosticType.ProprietaryFault</code> or <code>DiagnosticType.LegacyFault</code> causing error result.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/GoCurve">GoCurve</Link> - Added <code>IsIoxConnectionEnabled</code>. (Adds to{" "}
                    <code>GO4v3</code>, <code>GO5</code>, <code>GO6</code>,{" "}
                    <code>GO7</code>, <code>GO8</code>, <code>GO9</code>)
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/Group">Group</Link> (nuget only) - Removed <code>left</code> and{" "}
                    <code>right</code> parameters from constructor and <code>Group.Get</code> method.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/GroupRelations">GroupRelations</Link> - Added <code>AddInDatas</code> property. When{" "}
                    <code>Group</code> linked <code>AddInData</code> (Feature Preview) is blocking a{" "}
                    <code>Group</code> remove, a list blocking <code>AddInData</code> <code>Id</code>s will
                    be returned in the <code>GroupRelations</code> property of <code>GroupRelationViolatedException</code>.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/HosRuleSet">HosRuleSet</Link> - Added <code>WashingtonIntrastate7Day</code>,{" "}
                    <code>WashingtonIntrastate8Day</code>, <code>NoneCanada</code>,{" "}
                    <code>HosRuleSetCanadaNorthOf60CycleOne</code>, <code>HosRuleSetCanadaNorthOf60CycleTwo</code>
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/SecurityIdentifier">SecurityIdentifier</Link> - Added <code>ViewGroups</code>,{" "}
                    <code>AdministerWiFiHotspotSettings</code>, <code>ViewWiFiHotspotSettings</code>
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/TextMessage">TextMessage</Link> - Proper support of active from/to dates. *Messages that have not been sent by active to date will not be
                    sent.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/TextMessageSearch">TextMessageSearch</Link> - <code>ParentTextMessageId</code> (long) is obsolete. Usage
                    should be replaced with <code>ParentMessageId</code> (Id).
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/User">User</Link> - Added <code>MaxPCDistancePerDay</code>
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/UserSearch">UserSearch</Link> - Added "negatable” search of <code>FistName</code>,{" "}
                    <code>LastName</code> and <code>Name</code> properties. If the first character of this search property is ‘!', then
                    the API will know to negate the search logic. For example: <code>field = "!John%"</code>, is equivalent to:{" "}
                    <code>WHERE NOT LIKE 'John%'</code>.
                </p>
            </li>
        </ul>
    </div>
);

const update1904: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    AddInData (Feature Preview) - Groups are now optional for AddInData objects, currently in Feature preview. Previously, groups were a required property for the AddInData object.
                    This limited the potential usage of AddInData as there are situations where data should be available to all users regardless of scope, and some users were not able to access data
                    when they belonged to groups outside the data's scope. Removing this restriction means any user is now allowed to get an AddInData object if no group is specified for the object.
                </p>
            </li>
            <li>
                <p>API.cs (nuget only) - Now implements IApi interface. This allows for simpler unit testing of integration code using mocks.</p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/BinaryData">BinaryDataType</Link> - Added <code>SoftwareVersionFull</code>
                </p>
            </li>
            <li>
                <p>
                    Calculated Engine Hours Search - With a custom setting (<code>ENABLEENGINEHOURSINTERPOLATION</code>) applied to your database,
                    DiagnosticEngineHoursAdjustmentId will now be interpolated using trips and DiagnosticIgnitionId values when a search includes a from/toDate value(s) to provide exact values. To
                    apply this custom setting to your database, please reach out to Geotab support. This will become the default behavior in v5.7.2001.
                </p>
            </li>
            <li>
                <p>
                    <code>DatabaseExists</code> fixed to include databases existing in other federations.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/FuelTransactionProductType">FuelTransactionProductType</Link> - Added <code>Hydrogen</code> and{" "}
                    <code>DieselExhaustFluid</code>.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/FuelTransactionProvider">FuelTransactionProvider</Link> - Added <code>GFN</code>.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/HosRuleSet">HosRuleSet</Link> - Added <code>HosRuleSetCanadaCycleOneTeam</code> and{" "}
                    <code>HosRuleSetCanadaCycleTwoTeam</code>.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/LoginResult">LoginResult</Link> - Removed unsupported legacy property <code>SecurityToken</code>. This
                    property duplicated the supported property <code>Credentials</code>. It was previously maintained for compatibility with MyGeotab Web Server 5.6.1
                    which is no longer supported.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/Rule">Rule</Link> - Fix, don't allow adding Rules without Conditions.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/SecurityIdentifier">SecurityIdentifier</Link> - Added <code>ViewGroups</code>.
                </p>
            </li>
        </ul>
    </div>
);

const update1903: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DutyStatusLog">DutyStatusLog</Link>: Added <code>EditRequestedByUser</code>.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DutyStatusLog">DutyStatusLog</Link>: Locations will not be included with DutyStatusLog by default. To include locations must use{" "}
                    <code>dutyStatusLogSearch.IncludeLocations: true</code>.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DutyStatusLogType">DutyStatusLogType</Link>: Added <code>HosEnabled</code>,{" "}
                    <code>HosDisabled</code>.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DVIRLog">DVIRLog</Link>: Added <code>RepairDate</code>.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/IoxAddOn">IoxAddOn</Link>: Added <code>DateTime</code> representing when the channel was set to the given
                    value.
                </p>
            </li>
            <li>
                <p>
                    Serialization: ISO date time at zero hour will now have full ISO time ex <code>1986-01-01</code> -{">"}{" "}
                    <code>1986-01-01T00:00:00.000Z</code>.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/HosRuleSet">HosRuleSet</Link>: Added <code>AmericaShortHaul14hrWorkday</code>,{" "}
                    <code>AmericaShortHaul8Day14hrWorkday</code>, <code>OilTransportShortHaul14hrWorkday</code>,{" "}
                    <code>OilTransportShortHaul8Day14hrWorkday</code>, <code>CaliforniaFlammableLiquidWithRestRequirement</code>,{" "}
                    <code>CaliforniaSchoolPupilWithRestRequirement</code>, <code>CaliforniaFarmProductWithRestRequirement</code>,{" "}
                    <code>OilTransportCaliforniaProperty</code>, <code>OilWellCaliforniaProperty</code>,{" "}
                    <code>AmericaSalespersonNonCdlShortHaul</code>
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/User">User</Link>: Active from/to: The user property <code>ActiveTo</code> will automatically be set to max
                    date (2050-01-01) to denote that it is active. To account for differences in Client machine time vs Server machine time, we are allowing users to set the value of{" "}
                    <code>ActiveTo</code> to a max window of 24 hours in the future (i.e. Current Time + 24 hours). In this situation we are considering it to be
                    historical.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/CustomData">CustomData</Link>: Incomplete custom data is no longer returned via{" "}
                    <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link> API.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link>: Added feeds for entities that could generate more than 50,000 records in a single request. Please take note of
                    the limits on results.
                </p>
                <ul>
                    <li>
                        <p>
                            <Link to="/myGeotab/apiReference/objects/Audit">Audit</Link> - 50,000 record limit
                        </p>
                    </li>
                    <li>
                        <p>
                            <Link to="/myGeotab/apiReference/objects/Device">Device</Link> - 5,000 record limit
                        </p>
                    </li>
                    <li>
                        <p>
                            <Link to="/myGeotab/apiReference/objects/Diagnostic">Diagnostic</Link> - 50,000 record limit
                        </p>
                    </li>
                    <li>
                        <p>
                            <Link to="/myGeotab/apiReference/objects/DriverChange">DriverChange</Link> - 50,000 record limit
                        </p>
                    </li>
                    <li>
                        <p>
                            <Link to="/myGeotab/apiReference/objects/Route">Route</Link> - 10,000 record limit
                        </p>
                    </li>
                    <li>
                        <p>
                            <Link to="/myGeotab/apiReference/objects/Rule">Rule</Link> - 10,000 record limit
                        </p>
                    </li>
                    <li>
                        <p>
                            <Link to="/myGeotab/apiReference/objects/TextMessage">TextMessage</Link> - 50,000 record limit
                        </p>
                    </li>
                    <li>
                        <p>
                            <Link to="/myGeotab/apiReference/objects/Trailer">Trailer</Link> - 50,000 record limit
                        </p>
                    </li>
                    <li>
                        <p>
                            <Link to="/myGeotab/apiReference/objects/User">User</Link> - 5,000 record limit
                        </p>
                    </li>
                    <li>
                        <p>
                            <Link to="/myGeotab/apiReference/objects/Zone">Zone</Link> - 10,000 record limit
                        </p>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
);

const update1902: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/ApplicationVersionInformation">ApplicationVersionInformation</Link>: Added beta support
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/BinaryDataType">BinaryDataType</Link>: Added <code>SoftwareVersionSection1</code>,{" "}
                    <code>SoftwareVersionSection2</code>, <code>SoftwareVersionSection3</code>
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DefectRemark">DefectRemark</Link>: Added beta support
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DefectSeverity">DefectSeverity</Link>: Added <code>Unregulated</code>
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/Device">Device</Link>: Adding a device will now force the ActiveTo property to max date. Setting a device's ActiveTo property to a future
                    date, but not max date, will force the value to max date.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DeviceType">DeviceType</Link>: Added <code>GO9</code>
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DiagnosticType">DiagnosticType</Link>: Added <code>ProprietaryFault</code>,{" "}
                    <code>LegacyFault</code>
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DriverRegulation">DriverRegulation</Link>: Added beta support
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DtcClass">DtcClass</Link>: Added beta support
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DtcSeverity">DtcSeverity</Link>: Added beta support
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DutyStatusAvailability">DutyStatusAvailability</Link>: Added properties <code>CycleRest</code>,{" "}
                    <code>DutySinceCycleRest</code>, <code>Is16HourExemptionAvailable</code>,{" "}
                    <code>IsAdverseDrivingExemptionAvailable</code>, <code>IsOffDutyDeferralExemptionAvailable</code>
                </p>
            </li>
            <li>
                <p>DutyStatusAvailabilityDuration: Removed from documentation, will be obsoleted in future</p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DutyStatusViolationType">DutyStatusViolationType</Link>: Added <code>CycleRest</code> and{" "}
                    <code>DutySinceCycleRest</code>
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DVIRDefect">DVIRDefect</Link>: Added beta support
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/ElectricEnergyUnit">ElectricEnergyUnit</Link>: Added beta support
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/ElectricEnergyEconomyUnit">ElectricEnergyEconomyUnit</Link>: Added beta support
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/FaultData">FaultData</Link>: Added <code>ClassCode</code>,{" "}
                    <code>Severity</code> and <code>SourceAddress</code> properties
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/Go9">GO9</Link>: Added support
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/GroupSearch">GroupSearch</Link>: Added search by <code>Reference</code>
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/HosRuleSet">HosRuleSet</Link>: Added <code>CaliforniaFlammableLiquid</code>,{" "}
                    <code>CaliforniaSchoolPupil</code>, <code>CaliforniaFarmProduct</code>,{" "}
                    <code>OilTransportCalifornia8day</code>, <code>OilWellCalifornia8day</code>
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/KnownId">KnownId</Link>: Added <code>UnitOfMeasureLitersPerTonneId</code>,{" "}
                    <code>DiagnosticStateOfChargeId</code>, <code>DiagnosticTotalLifetimeBatteryEnergyInDuringACChargingId</code>,{" "}
                    <code>DiagnosticTotalLifetimeBatteryEnergyInDuringDCChargingId</code>,{" "}
                    <code>DiagnosticTotalLifetimeOnBoardChargerEnergyOutDuringACChargingId</code>,{" "}
                    <code>DiagnosticTotalLifetimeOnBoardChargerEnergyInDuringACChargingInId</code>,{" "}
                    <code>DiagnosticOnBoardChargerAcInputVoltageId</code>, <code>DiagnosticElectricVehicleChargingStateId</code>,{" "}
                    <code>DiagnosticElectricVehicleBatteryPowerId</code>, <code>DiagnosticOnBoardChargerACInputPowerId</code>,{" "}
                    <code>DiagnosticOnBoardChargerDCOutputPowerId</code>,{" "}
                    <code>DiagnosticElectricEnergyInId,DiagnosticElectricEnergyOutId</code>,{" "}
                    <code>HosRuleSetCaliforniaFlammableLiquid</code>, <code>HosRuleSetCaliforniaSchoolPupil</code>,{" "}
                    <code>HosRuleSetCaliforniaFarmProduct</code>, <code>HosRuleSetOilTransportCalifornia8day</code>,{" "}
                    <code>HosRuleSetOilWellCalifornia8day</code>, <code>ControllerProprietaryFaultId</code>,{" "}
                    <code>ControllerLegacyFaultId</code>, <code>SourceProprietaryId</code>,{" "}
                    <code>SourceLegacyId</code>, <code>DiagnosticBluetoothNitricOxideConcentrationId</code>,{" "}
                    <code>DiagnosticBluetoothNitrogenDioxideConcentrationId</code>,{" "}
                    <code>DiagnosticBluetoothCarbonMonoxideConcentrationId</code>, <code>DiagnosticBluetoothAmmoniaConcentrationId</code>,{" "}
                    <code>DiagnosticBluetoothMethaneConcentrationId</code>, <code>DiagnosticBluetoothEthanolConcentrationId</code>,{" "}
                    <code>DiagnosticBluetoothHydrogenConcentrationId</code>, <code>DiagnosticBluetoothCarbonDioxideConcentrationId</code>
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/MimeContent">MimeContent</Link>: Added <code>ChannelNumber</code> property
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/RepairStatusType">RepairStatusType</Link>: Added beta support
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/SecurityIdentifier">SecurityIdentifier</Link>: Added <code>InspectDVIR</code>,{" "}
                    <code>CertifyDVIR</code> - Removed <code>DailyUsageReport</code>
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/User">User</Link>: Added <code>ElectricEnergyEconomyUnit</code> and{" "}
                    <code>isEmailReportEnabled</code> properties
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/User">User</Link>: Fixed bug where <code>isDriver</code> property would be included with{" "}
                    <code>id</code> in nested driver entities. This property is removed from nested entities. It will remain in non-nested users.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/VersionInformation">VersionInformation</Link>: <code>Server</code> is obsolete and replaced with more
                    detailed <code>Application</code> property (see{" "}
                    <Link to="/myGeotab/apiReference/objects/ApplicationVersionInformation">ApplicationVersionInformation</Link>)
                </p>
            </li>
            <li>
                <p>.Net nuget package: Group constructor with only ID has been removed.</p>
            </li>
        </ul>
        <h2>Result and rate limits</h2>
        <p>
            For an in-depth description of the result in rate limit changes in 5.7.1902 and future releases see{" "}
            <a href="https://www.geotab.com/blog/result-and-rate-limits/" target="_blank" rel="noopener noreferrer">
                this blog post
            </a>
        </p>
        <ul>
            <li>
                <p>
                    <HashLink to="/myGeotab/guides/concepts#limits">Concepts</HashLink> section updated to reflect new result and rate limits.
                </p>
            </li>
            <li>
                <p>
                    Result Limits: Maximum result limit of 50,000 has been added to generic <code>Get</code> (including <code>Get</code>{" "}
                    using <code>search</code>) requests of entity types: AnnotationLog, DVIRLog, TrailerAttachment, IoxAddOn, CustomData, BinaryData. Results limits
                    will be added to more entity types in future releases.
                </p>
            </li>
            <li>
                <p>
                    Rate Limits: Rate limits of 1 RPS (request-per-second) has been added to all <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link> APIs.
                </p>
            </li>
        </ul>
    </div>
);

const update1901: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>Sun-setting support for SendEmail API. No longer available in API documentation.</p>
            </li>
            <li>
                <Link to="/myGeotab/apiReference/objects/Device">Device</Link>: Active from/to:
                <ul>
                    <li>
                        The device property <code>ActiveTo</code> will automatically be set to max date (2050-01-01) to denote that it is active.
                    </li>
                    <li>
                        To account for differences in Client machine time vs Server machine time, we are allowing users to set the value of <code>ActiveTo</code> to a
                        max window of 24 hours in the future (i.e. Current Time + 24 hours). In this situation we are considering it to be historical.
                    </li>
                </ul>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DVIRLog">DVIRLog</Link>: Added <code>Location</code> property.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/SecurityIdentifier">SecurityIdentifier</Link>: Added <code>ViewBusinessIntelligence</code>,{" "}
                    <code>ActiveTrackingClearance</code>.
                </p>
            </li>
            <li>
                SecurityRole: Added <code>EmailSent</code>, <code>SkipHosVerify</code>,{" "}
                <code>SkipHosClaimUnassigned</code>, <code>SkipDvirInspect</code>.
            </li>
        </ul>
    </div>
);

const update1804Dot1: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <Link to="/myGeotab/apiReference/objects/API">API.cs</Link> (.Net only): Fix bug, Windows 10 using IIS Express possible hanging <i>synchronous</i> requests using nuget package
                5.7.1803\5.7.1804.
            </li>
        </ul>
    </div>
);

const update1804: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/methods/Add">Add</Link>/<Link to="/myGeotab/apiReference/methods/Set">Set</Link> <Link to="/myGeotab/apiReference/objects/Device">Device</Link>:{" "}
                    <code>ParameterVersion</code> will auto increment server side when device parameters property changed. Server must see that{" "}
                    <code>ParameterVersion</code> has incremented to send parameters to an installed GO device (ex device beeping instructions). Previously,{" "}
                    <code>ParameterVersion</code> required manual increment.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/API">API.cs</Link> (.Net only): Fix bug, in certain senario changing <code>Timeout</code> property could
                    abort the action on timeout and not cancel underlying request.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/CustomVehicleDevice">CustomVehicleDevice</Link>: Support of vehicle specific custom devices which provide vehicle specific properties and
                    functionality. Custom device product ID must be of CustomVehicleDevice type. Contact your reseller for more information.
                </p>
                <ul>
                    <li>
                        <p>
                            Improved support for calculated odometer and raw odometer with third-party diagnostic KnownId <code>DiagnosticThirdPartyOdometerId</code>{" "}
                            and <code>OdometerAdjustmentId</code>
                        </p>
                    </li>
                    <li>
                        <p>
                            Improved support for calculated engine hours with third-party diagnostic KnownId <code>DiagnosticThirdPartyEngineRunTimeId</code> and{" "}
                            <code>EngineHoursAdjustmentId</code>
                        </p>
                    </li>
                    <li>
                        <p>
                            <code>VehicleIdentificationNumber</code> property moved from CustomDevice to CustomVehicleDevice
                        </p>
                    </li>
                    <li>
                        <p>
                            Added <code>LicencePlate</code> and <code>LicenceState</code> properties
                        </p>
                    </li>
                </ul>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DutyStatusAvailability">DutyStatusAvailability</Link>: Added BETA support for <code>Recap</code> and{" "}
                    <code>CycleAvailabilities</code> properties
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DutyStatusAvailability">DutyStatusAvailability</Link>: Replaced <code>Availabilities</code> list with
                    separate properties: <code>Driving</code>, <code>Cycle</code>, <code>Rest</code>,{" "}
                    <code>Duty</code>, <code>Workday</code>
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DeviceType">DeviceType</Link>: Added <code>CustomVehicleDevice</code>.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DriverChange">DriverChange</Link>: DriverChange object Id property is no longer backed by integer type. It is now backed by GUID type.
                    When update 1804 is applied to the database, all previous numeric entity Id's will be invalidated and assigned a new GUID Id's. This could pose an issue if your integration
                    stores driver change Id and you then reference the DriverChange by that Id. Note: JSON representation of Id was previously string and remains string type.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DutyStatusLogType">DutyStatusLogType</Link>: Added <code>ExemptionOffDutyDeferral</code>.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DutyStatusViolationType">DutyStatusViolationType</Link>: Added <code>DailyDriving</code>,{" "}
                    <code>DailyRest</code>, <code>DailyDuty</code>, <code>DailyOff</code>.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/KnownId">KnownId</Link>: Added <code>DiagnosticThirdPartyEngineRunTimeId</code>,{" "}
                    <code>DiagnosticThirdPartyOdometerId</code>.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link> <Link to="/myGeotab/apiReference/objects/LogRecord">LogRecord</Link>: Fixed bug with inconstant results limit.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/SecurityIdentifier">SecurityIdentifier</Link>: Added <code>DirectSupportAdmin</code>,{" "}
                    <code>UserLoginStatusRead</code>, <code>UserLoginStatusSet</code>.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/SecurityIdentifier">SecurityIdentifier</Link>: Values <code>AlarmSoundList</code>,{" "}
                    <code>Tracking</code>, <code>CreateNewSqlDatabase</code>,{" "}
                    <code>EngineControllerList</code>, <code>PurgeSettings</code>,{" "}
                    <code>SendImmobilizationInstruction</code> are obsolete and will be removed in version 1806+.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/SecurityRole">SecurityRole</Link>: Added <code>SupportTicketInsert</code>,{" "}
                    <code>TrainingTicketInsert</code>, <code>SupportTicketSet</code>,{" "}
                    <code>TrainingTicketSetUser</code>, <code>LoginFailure</code>, <code>UserLockout</code>,{" "}
                    <code>UserUnlocked</code>, <code>ShipmentLogInsert</code>, <code>ShipmentLogSet</code>,{" "}
                    <code>ShipmentLogRemove</code>, <code>TrailerAttachmentInsert</code>,{" "}
                    <code>TrailerAttachmentSet</code>, <code>TrailerAttachmentRemove</code>.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/ZoneSearch">ZoneSearch</Link>: Added <code>FromDate</code> and{" "}
                    <code>ToDate</code> search properties providing ability to filter zones by their active dates.
                </p>
            </li>
        </ul>
        <InformationalBox>
            <p>
                <Link to="/myGeotab/apiReference/objects/API">API.cs</Link> (.Net only): There is a known issue on Windows 10 using IIS Express with possible hanging <i>synchronous</i> requests
                using nuget package 5.7.1803\5.7.1804. This issue is solved in 5.7.1804.1 or greater.
            </p>
        </InformationalBox>
    </div>
);

const update1803: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    SecurityRole: Added <code>CertificateSet</code> permission
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DriverChangeSearch">DriverChangeSearch</Link>: Added property <code>Type</code> indicating the
                    DriverChangeType to search for exclusively.
                </p>
            </li>
        </ul>
        <InformationalBox>
            <p>
                <Link to="/myGeotab/apiReference/objects/API">API.cs</Link> (.Net only): There is a known issue on Windows 10 using IIS Express with possible hanging <i>synchronous</i> requests
                using nuget package 5.7.1803\5.7.1804. This issue is solved in 5.7.1804.1 or greater.
            </p>
        </InformationalBox>
    </div>
);

const update1802: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DutyStatusLog">DutyStatusLog</Link>: Added properties <code>Odometer</code>,{" "}
                    <code>EngineHours</code>, <code>EventRecordStatus</code>, <code>EventCode</code>,{" "}
                    <code>EventType</code>
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/DutyStatusLogType">DutyStatusLogType</Link>: Added <code>SituationalDrivingClear</code>
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/FuelTaxDetail">FuelTaxDetail</Link>: Added properties <code>HourlyIsOdometerInterpolated</code>,{" "}
                    <code>IsEnterOdometerInterpolated</code>, <code>IsExitOdometerInterpolated</code>
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/FuelTaxDetail">FuelTaxDetail</Link>: Obsolete <code>IsClusterOdometer</code> - Superseded by the
                    IsEnterOdometerInterpolated, HourlyIsOdometerInterpolated, and IsExitOdometerInterpolated properties. Will be removed in future version.
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/FuelTaxDetailSearch">FuelTaxDetailSearch</Link>: Added properties <code>IncludeBoundaries</code>,{" "}
                    <code>IncludeHourlyData</code>
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects/SecurityIdentifier">SecurityIdentifier</Link>: Added <code>ViewTripTypeChangeData</code>,{" "}
                    <code>EditTripTypeChangeData</code>
                </p>
            </li>
        </ul>
        <h2>Notice</h2>
        <p>
            An issue was discovered which could cause integrations using the Geotab.Checkmate.Objectmodel nuget package v5.7.1801 and lower to encounter a serialization failure when a new
            DiagnosticType is introduced. The issue has been addressed in nuget package v
            <a href="https://www.nuget.org/packages/Geotab.Checkmate.ObjectModel/" target="_blank" rel="noopener noreferrer">
                5.7.1802
            </a>
            . To ensure compatibility, it is strongly recommended that all integrations referencing the nuget package v5.7.1801 and lower update to version v5.7.1802 as soon as possible. (this issue
            is only relevant to .Net nuget package users)
        </p>
    </div>
);

const update1801: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    KnownId - Removed: <code>UnitOfMeasureLitersPer100KilometersId</code>. Diagnostics associated with this unit of measure now use{" "}
                    <code>UnitOfMeasureKilometersPerLiterId</code>. This will not affect any previously recorded data.
                </p>
            </li>
            <li>
                <p>
                    KnownId - Added: <code>DiagnosticGpsLogReasonId</code>, <code>DiagnosticEngineRoadSpeedId</code>
                </p>
            </li>
            <li>
                <p>
                    ConditionType - Added: <code>DVIRDefect</code> - Currently works with Devices not Trailers
                </p>
            </li>
            <li>
                <p>
                    SecurityIdentifier - Added: <code>DriverIdentificationClearance</code>, <code>AccelerometerDataClearance</code>,{" "}
                    <code>ServicePlansClearance</code>, <code>AuxiliaryClearance</code>,{" "}
                    <code>EngineStatusDataClearance</code>, <code>ResellerControlClearance</code>,{" "}
                    <code>GoTalkClearance</code>, <code>StatusGroupsClearance</code>,{" "}
                    <code>ProductGuideClearance</code>, <code>FeaturePreviewClearance</code>,{" "}
                    <code>NewsNotificationsClearance</code>, <code>ManageAddinsClearance</code>,{" "}
                    <code>DeviceCurrentStatusClearance</code>
                </p>
            </li>
        </ul>
        <h2>Notice</h2>
        <p>In early 2018 the following legacy properties will be removed:</p>
        <p>
            <b>Authenticate</b>: <code>userLogin</code> parameter. This was kept around for compatibility with legacy (5.6.* and lower) integrations. It has not been
            publicly exposed or documented since version 5.6 of MyGeotab. It is planned to be removed as a valid parameter in version 5.7.1803. The{" "}
            <code>userName</code> parameter is the standard supported property that should be used.
        </p>
        <p>
            <b>LoginResult</b>: <code>securityToken</code> property. LoginResult is the object returned by the <Link to="/myGeotab/apiReference/methods/Authenticate">Authenticate</Link> method. It's property{" "}
            <code>securityToken</code> was kept around for compatibility with legacy (5.6.* and lower) integrations. It has not been publicly exposed or documented
            since version 5.6 of MyGeotab. It is planned to be removed as a valid parameter in version 5.7.1803. The property <code>credentials</code> is the standard
            supported property that shares the same value.
        </p>
    </div>
);

const update1712: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    API.cs (.Net only) - Fix: When password and session id are supplied to constructor, session id will be used until no longer valid. Previously, session id would only be used if
                    password was not supplied.
                </p>
            </li>
            <li>
                <p>FuelTaxDetail - Added properties: ”IsEnterOdometerInterpolated”, "IsExitOdometerInterpolated”, "HourlyIsOdometerInterpolated”</p>
            </li>
            <li>
                <p>User - Removed property: "MenuCollapsedNotified”</p>
            </li>
        </ul>
    </div>
);

const update1711: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>GetAddresses - Added: "hosAddresses” parameter to optionally search for ELD compliant address</p>
            </li>
            <li>
                <p>UnitOfMeasure - Added: Kilowatt hours ("UnitOfMeasureKiloWattHoursId”)</p>
            </li>
            <li>
                <p>SecurityIdentifier - Added "ViewBinaryData”, "ManageAddInData”, "ViewAddInData”</p>
            </li>
            <li>
                <p>HosRuleSet - Added "CarrierExemption”</p>
            </li>
            <li>
                <p>.Net SDK samples updated to target netcoreapp2.0</p>
            </li>
            <li>
                <p>.Net nuget package now supports framework: netstandard2.0 (removed support for net46)</p>
            </li>
        </ul>
    </div>
);

const update1709: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>API.cs (.Net only) - Added cancellation token parameter to AuthenticateAsync and CallAsync methods.</p>
            </li>
            <li>
                <p>
                    DutyStatusLog - Added "Malfunction” property - The DutyStatusMalfunctionType of the DutyStatusLog record. As a flag it can be both a diagnostic and malfunction state which is
                    used to mark status based records (e.g. "D”, "SB”) as having a diagnostic or malfunction present at time of recording.
                </p>
            </li>
            <li>
                <p>DutyStatusLog - Added "Sequence” property - The sequence number, which is used to generate the sequence ID.</p>
            </li>
            <li>
                <p>
                    DutyStatusLogType - Added "EnginePowerup”, "EngineShutdown”, "EnginePowerupPC”, "EngineShutdownPC”, "PowerCompliance”, "EngineSyncCompliance”, "TimingCompliance”,
                    "PositioningCompliance”, "DataRecordingCompliance”, "DataTransferCompliance”, "OtherCompliance”, "MissingElementCompliance”, "UnidentifiedDrivingCompliance”, "INT_PC”, "INT_D”.
                </p>
            </li>
            <li>
                <p>
                    Controller - Added short integer "CodeId” property, which will replace the "Code” property. New "AnyController” for J1708 engine diagnostics to allow replacing those engine
                    diagnostics identical except for the controller with one diagnostic. J1708 engine diagnostics for 58 separate SIDs were updated.
                </p>
            </li>
            <li>
                <p>CustomDevice - Added "VehicleIdentificationNumber” property.</p>
            </li>
            <li>
                <p>FuelTaxDetail - Added "Driver” property.</p>
            </li>
            <li>
                <p>
                    DriverChangeSearch - Added "IncludeOverlappedChanges” property - A value indicating whether to include the last driver change before the from date or the most recent driver
                    change (if the from date is not provided).
                </p>
            </li>
            <li>
                <p>InvalidUserException - Message changed from to "Incorrect MyGeotab login credentials…” to "Incorrect login credentials…”.</p>
            </li>
        </ul>
        <h2>New objects</h2>
        <ul>
            <li>
                <p>DutyStatusMalfunctionType - Added - Malfunction or Diagnostic type of the DutyStatusLog.</p>
            </li>
            <li>
                <p>DutyStatusState - Added - The record status of DutyStatusLog.</p>
            </li>
        </ul>
    </div>
);

const update1707: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    Web Request Notifications: fix {"{"}zoneId{"}"} and {"{"}zoneComment{"}"} tokens would not get populated unless {"{"}zone{"}"} or {"{"}address{"}"} were also included.
                </p>
            </li>
            <li>
                <p>
                    Documentation: API Reference updated to include default value and max length of object properties in their descriptions. Default values are automatically used when adding an
                    entity and those properties have no value assigned (are null). For example, adding a Group with color = null, will add group with default color "Blue”. If a property has no
                    default value, it is required when calling "Add”.
                </p>
            </li>
            <li>
                <p>
                    Set operations now retain the value of missing (null) properties. A positive effect of this change is that is remedies a long existing issue that could occur when a server is a
                    newer version (ex 5.7.1704) than client nuget package (ex 5.7.1701) which is making requests to it. The issue could arise when a new Enum value was added and exists only in the
                    server's code base, not client client nuget package. When the unknown Enum was received by the client, it could not be deserialized into an Enum value and would throw an
                    exception. Starting in nuget package version 5.7.1707.x, the unknown Enum value will be deserialiezed to null. This means the object can round trip” on "Set” because the server
                    will now (starting at version 5.7.1707) fill in the null value with the existing saved value of the property.
                </p>
            </li>
            <li>
                <p>
                    Added: JsonRpcError, JsonRpcErrorData - to better align JSON-RPC errors with the JSON-RPC 2.0 specification. Non-standard (now legacy) error properties have been deprecated. This
                    should not affect nuget package users as the API.cs object serialized the JSON-RPC error results as Exceptions which are thrown. This may affect users consuming the raw JSON-RPC
                    result of requests. It's recommended to update usages to the official, standardized, properties as outlined in the API Reference. Of note: the new objects exposes "requestIndex”
                    property which is the index at which a "multicall” failed.
                </p>
            </li>
            <li>
                <p>
                    Added: User/Driver objects now have property "IsDriver” to clearly indicate when a user is a driver. This also makes it easier to save a user who is no longer a driver, set the
                    property to false and save.
                </p>
            </li>
            <li>
                <p>
                    Added: FuelTaxDetail - A new entity which provides API access to calculated fuel tax data. In the past this data was only available via the IFTA Report in MyGeotab where it was
                    calculated on the fly. Fuel tax details are now processed using live data and stored in the database and they can be access using the API via Get and{" "}
                    <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link> methods. Full documentation coming soon.
                </p>
            </li>
        </ul>
    </div>
);

const update1706: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    DatabaseId has been removed from .net package as per the{" "}
                    <a href="https://helpdesk.geotab.com/hc/en-us/community/posts/255601466--NET-SDK-ID-Object-Changes" target="_blank" rel="noopener noreferrer">
                        December 2016 post
                    </a>
                </p>
            </li>
            <li>
                <p>
                    DutyStatusLogType: Added: "Authority”{" "}
                    <b>
                        <i>requires update of .net nuget package to ensure compatibility</i>
                    </b>
                </p>
            </li>
            <li>
                <p>FuelTransaction: Added: "ExternalReference”</p>
            </li>
            <li>
                <p>FuelTransactionSearch: Added: "ExternalReference” and "Provider”</p>
            </li>
            <li>
                <p>User: Added: "AuthorityName” and "AuthorityAddress”</p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link> of Trip now includes stop point (woohoo!) <b>*requires server running 5.7.1706.x</b>
                </p>
            </li>
            <li>
                <p>
                    <a href="https://my112.geotab.com/sdk/#/apiWrappers" aria-label="API clients section in SDK document" target="_blank" rel="noopener noreferrer">
                        API Clients
                    </a>{" "}
                    section added to SDK documentation
                </p>
            </li>
            <li>
                <p>Geotab.Reflection package no longer a dependency of Geotab.Checkmate.Objectmodel nuget package</p>
            </li>
        </ul>
    </div>
);

const update1705: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    GO8: Added preliminary support for GO8 devices{" "}
                    <b>
                        <i>requires update of .net nuget package to correctly read/write GO8 devices</i>
                    </b>
                </p>
            </li>
            <li>
                <p>IoxAddOn: Added preliminary support for IoxAddOn, IoxAddOnSearch, SerialIoxContent,KnownIoxAddOnType, IoxOutputContent, MimeContent</p>
            </li>
            <li>
                <p>TextMessageSearch: Added search by: "ParentTextMessageId”</p>
            </li>
        </ul>
    </div>
);

const update1704: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>DutyStatusLog - Added property: State</p>
            </li>
            <li>
                <p>DutyStatusLogSearch - Added search by device groups</p>
            </li>
            <li>
                <p>API.cs is now compatible with System.Net.Http v4.3.1</p>
            </li>
            <li>
                <p>Fix, API.cs proxy support. New constructor that accepts HttpMessageHandler, deprecated constructor that accepts IWebProxy and Proxy property</p>
            </li>
            <li>
                <p>Fix, content type of API response headers changed from "text/html” to "application/json”</p>
            </li>
        </ul>
    </div>
);

const update1703: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    DevicePlan: Added: D2GODriverChallengeStandard{" "}
                    <b>
                        <i>requires update of .net nuget package to ensure compatibility</i>
                    </b>
                </p>
            </li>
            <li>
                <p>
                    HosRuleSet.cs Added: America7DayNo34h, America8DayNo34h, AmericaShortHaulNo34h, AmericaShortHaul8DayNo34h, BrazilShortHaul{" "}
                    <b>
                        <i>requires update of .net nuget package to ensure compatibility</i>
                    </b>
                </p>
            </li>
            <li>
                <p>
                    SecurityRole.cs Added: ReassignData{" "}
                    <b>
                        <i>requires update of .net nuget package to ensure compatibility</i>
                    </b>
                </p>
            </li>
            <li>
                <p>
                    TimeZoneId: it was possible to add a user or device with "Unknown” time zone ID. This was only possible using the API and "Unknown” is not returned via the GetTimeZones method or
                    a valid Olson time zone. The ability to add users and devices with "Unknown” as been removed and all users and devices with this time zone ID have been changed to "Etc/GMT”
                </p>
            </li>
        </ul>
    </div>
);

const update1702: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>FuelTransactionProvider: Added - "Voyager”, "UltramarCST”</p>
            </li>
            <li>
                <p>
                    SecurityIdentifier: Added - "PurchaseMarketplacePaidItems”{" "}
                    <b>
                        <i>requires update of .net nuget package to ensure compatibility</i>
                    </b>
                </p>
            </li>
            <li>
                <p>
                    SecurityRole: Added: "TripTypeChangeInsert”, "TripTypeChangeRemove”, "CustomReportSendError”{" "}
                    <b>
                        <i>requires update of .net nuget package to ensure compatibility</i>
                    </b>
                </p>
            </li>
            <li>
                <p>Fix: nuget package issue making API requests from Azure WebJob</p>
            </li>
            <li>
                <p>Fix: TimeZoneInfoAdjustmentRule serialization (result of GetDaylightSavingRules)</p>
            </li>
        </ul>
    </div>
);

const update1701: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>Added "Hardware” section to SDK</p>
            </li>
            <li>
                <p>Nuget: Replace usage of Microsoft.Net.Http with System.Net.Http (WebRequest ={">"} HttpClient)</p>
            </li>
            <li>
                <p>DiagnosticSearch: Added DiagnosticType property to search by the type of diagnostic. Ex, only GoFault diagnostics.</p>
            </li>
            <li>
                <p>
                    Added "ExpiredPasswordException” object. This exception can be thrown if a user makes a request while their ChangePassword flag is true. The user must change their password
                    before they are able to successfully make further requests
                </p>
            </li>
            <li>
                <p>
                    SecurityRole: Added "ReportHosAvailability”{" "}
                    <b>
                        <i>requires update of .net nuget package to ensure compatibility</i>
                    </b>
                </p>
            </li>
            <li>
                <p>If you are using the .net nuget package and plan to use the new "HOS Only” device plan you must update to nuget package version 5.7.1701 or greater to ensure compatibility.</p>
            </li>
        </ul>
    </div>
);

const update1612: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    Id refactoring - The ID object has been refactored in the .NET SDK. See{" "}
                    <a href="https://helpdesk.geotab.com/hc/en-us/community/posts/255601466--NET-SDK-ID-Object-Changes" target="_blank" rel="noopener noreferrer">
                        this forum post
                    </a>{" "}
                    for details.
                </p>
            </li>
            <li>
                <p>
                    Updated description of GoCurveAuxiliary (GO4v3, GO6, GO7) properties: ImmobilizeUnit: With ImmobilizeUnit being true, it is used to define the delay before the driver
                    identification reminder is sent out if the driver key has not been not swiped. The maximum value of this property is 255. When it is less or equal to 180, it indicates the number
                    of seconds of the delay. When it is greater than 180, the delay increases 30 seconds for every increment of one of this property. For example, 180 indicates 180 seconds, 181
                    indicates 210 seconds, and 182 indicates 240 seconds. ImmobilizeArming: A value mainly used for enable or disable driver identification reminder. If it is used in conjunction
                    with vehicle relay circuits, it can force the driver to swipe the driver key before starting the vehicle.
                </p>
            </li>
        </ul>
    </div>
);

const update1611: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    Authentication rate limiting being phased in. See this{" "}
                    <a href="https://www.geotab.com/blog/api-call-limits/" target="_blank" rel="noopener noreferrer">
                        Blog Post
                    </a>{" "}
                    for more details. Added "Rate Limiting” section to <Link to="/myGeotab/guides/concepts">SDK Concepts</Link>.
                </p>
            </li>
            <li>
                <p>KnownId - Added: "DiagnosticDieselExhaustFluidId”, ”DiagnosticDieselParticulateFilterLampId”, "DiagnosticPowerTakeoffEngagedId”, "DiagnosticPowerTakeoffTotalFuelUsedId”</p>
            </li>
            <li>
                <p>KnownId - Removed: "DiagnosticBluetoothBeaconOutOfRangeId”</p>
            </li>
            <li>
                <p>Trailer - Added "Groups” property. Trailers can now be added to groups.</p>
            </li>
            <li>
                <p>TrailerSearch - Added property groups. Search for Trailers that are members of these GroupSearch(s) one of it's children or one of it's parents.</p>
            </li>
            <li>
                <p>*SecurityIdentifier - Added: "RepairDVIR”</p>
            </li>
        </ul>
    </div>
);

const update1610: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>KnownId - Added: "DiagnosticDieselExhaustFluidId”, "DiagnosticDieselParticulateFilterLampId”, "DiagnosticPowerTakeoffEngagedId”, "DiagnosticPowerTakeoffTotalFuelUsedId”</p>
            </li>
            <li>
                <p>HosRuleSet - Added: "Florida7Day”, "Florida8Day”, "FloridaShortHaul7Day”, "FloridaShortHaul8Day”</p>
            </li>
        </ul>
        <h2>New objects</h2>
        <ul>
            <li>
                OverLimitException: Thrown when a user has exceeded the query limit of an API (currently only applies to authentication). Previously, if a user reached this limit, an
                InvalidUserException would have been thrown.
            </li>
        </ul>
    </div>
);

const update1609: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>KnownId - "DiagnosticRamFailure” name fixed to be "DiagnosticRamFailureId”</li>
        </ul>
    </div>
);

const update1608: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>MessageContentType - Added: "DriverWhiteList”</p>
            </li>
            <li>
                <p>DutyStatusLogType - Added: "PC” (Personal conveyance driver status), "YM” (Yard move driver status), "WT” (Wait time oil well driver status).</p>
            </li>
            <li>
                <p>FuelTransaction - Added "ProductType” property.</p>
            </li>
            <li>
                <p>FuelTransactionProvider - Added "WexLegacy”, "Fuelman” and "Comdata”.</p>
            </li>
            <li>
                <p>GoDevice - Added "GoTalkLanguage” property.</p>
            </li>
            <li>
                <p>User - Added "IsYardMoveEnabled” and "IsPersonalConveyanceEnabled” properties.</p>
            </li>
            <li>
                <p>
                    HosRuleSet - Added "OilWell7Day”, "OilWell7DayBig”, "OilWell8Day”, "OilWell8DayBig”, "AmericaTexas”, "AmericaTexasShortHaul”, "OilTransportTexas”, "OilWellTexas”,
                    "AmericaShortHaul8Day”, "AmericaShortHaulPassenger8Day”, "OilTransportShortHaul8Day”, "AmericaTexasShortHaul8Day”
                </p>
            </li>
            <li>
                <p>KnownId - Added "DiagnosticSystemAlertId”</p>
            </li>
            <li>
                <p>
                    TimeZoneInfo - Adjusted to support recently updated Windows times zones and the latest version of{" "}
                    <a href="http://www.unicode.org/cldr/charts/dev/supplemental/zone_tzid.html" target="_blank" rel="noopener noreferrer">
                        IANA/Windows
                    </a>{" "}
                    mapped time zones.{" "}
                    <a href="https://docs.google.com/document/d/1kjIhyqpgOg1wNHi3JkvV7uXVBzhl5stlZZxIVjXs1Fc/edit#" target="_blank" rel="noopener noreferrer">
                        Click here for more info
                    </a>
                </p>
            </li>
        </ul>
        <h2>New objects</h2>
        <ul>
            <li>
                <p>DriverWhiteListContent</p>
            </li>
            <li>
                <p>GoTalkLanguage</p>
            </li>
            <li>
                <p>FuelTransactionProductType</p>
            </li>
        </ul>
        <p>For detailed information on new features please review the API Reference.</p>
        <p>
            <i>*.Net users will require a dll update</i>
        </p>
    </div>
);

const update1607: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>Performance and maintenance enhancements.</li>
        </ul>
    </div>
);

const update1606: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>DiagnosticTypes - Added "GoFault”</p>
            </li>
            <li>
                <p>FuelTransactionProvider - Added "WexCustomer”, "Drive” and "FuelTracker”</p>
            </li>
            <li>
                <p>SecurityIdentifier - Added "EventOccurrenceList”,”ViewCertificates”,”ManageCertificates”</p>
            </li>
            <li>
                <p>HosRuleSet - Added "AmericaSalesperson”</p>
            </li>
            <li>
                <p>.Net: MachineSettings - Fix to work with ASP.Net Web API projects</p>
            </li>
            <li>
                <p>.Net: DataToComponenet - Updated "Equals” method to compare payloads for equality</p>
            </li>
            <li>
                <p>.Net: DutyStatusOrigin - removed unused "Serializable” attribute</p>
            </li>
            <li>
                <p>.Net - Changes in API.cs to use HttpClient instead of HttpWebRequest in order to support .Net core in the future.</p>
            </li>
            <li>
                <p>dll requires .Net Framework version v4.6+</p>
            </li>
        </ul>
        <h2>New APIs</h2>
        <ul>
            <li>
                <p>GetDirections</p>
            </li>
            <li>
                <p>OptimizeWaypoints</p>
            </li>
        </ul>
        <h2>New objects</h2>
        <ul>
            <li>
                <p>Directions</p>
            </li>
            <li>
                <p>Leg</p>
            </li>
            <li>
                <p>Step</p>
            </li>
            <li>
                <p>Waypoint</p>
            </li>
        </ul>
        <p>
            <i>*.Net users will require a dll update</i>
        </p>
    </div>
);

const update1605: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>Added new Units of Measure (km/L, kg/km, L/lane km, L/ton and g/m^2) ** .Net users will require a dll update*</li>
        </ul>
    </div>
);

const update1604: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    For security reasons, TLS 1.2 is being enforced on all servers. To fix the integration, please update to at least .NET 4.5 and use the{" "}
                    <a href="https://www.nuget.org/packages/Geotab.Checkmate.ObjectModel/" target="_blank" rel="noopener noreferrer">
                        latest nuget package
                    </a>
                    . For more information, please visit the{" "}
                    <a href="https://helpdesk.geotab.com/entries/108236723-TLS1-2-Upgrade-Notice" target="_blank" rel="noopener noreferrer">
                        forum discussion
                    </a>
                    .
                </p>
            </li>
            <li>
                <p>Driver has a new property: viewDriversOwnDataOnly. When set to true, a driver gains the ability to view their own driving data. ** .Net users will require a dll update*</p>
            </li>
        </ul>
    </div>
);

const update1602: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>TextToSpeechContent has been renamed to GoTalkContent and RelayContent has been renamed to IoxOutputContent ** .Net users will require a dll update*</li>
        </ul>
    </div>
);

const update1601: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    New recipient types added that will send even if there is a delay in data. (BeepTenTimesRapidlyAllowDelay, BeepThreeTimesAllowDelay, BeepThreeTimesRapidlyAllowDelay ,
                    TextToSpeechAllowDelay) ** .Net users will require a dll update*
                </p>
            </li>
            <li>
                <p>Access to third party messages via API. ** .Net users will require a dll update*</p>
            </li>
        </ul>
        <h2>New objects</h2>
        <ul>
            <li>
                <p>IoxAddOn</p>
            </li>
            <li>
                <p>KnownIoxAddOnType</p>
            </li>
            <li>
                <p>IoxAddOnSearch</p>
            </li>
            <li>
                <p>IoxOutputContent</p>
            </li>
            <li>
                <p>GoTalkContent</p>
            </li>
        </ul>
    </div>
);

const update1512: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    Fixed operator overloading for Id object in .Net dll (id1 == id2 is the same as id1.Equals(id2)) -{" "}
                    <i>*If you are using dll version 5.7.1508-1511 this will require a dll update.</i>
                </p>
            </li>
            <li>
                <p>
                    New addin: Trips Streetview added to{" "}
                    <a href="https://github.com/Geotab/addin-trips-streetview" target="_blank" rel="noopener noreferrer">
                        GitHub
                    </a>
                </p>
            </li>
        </ul>
    </div>
);

const update1511: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>Added new security clearances for:</p>
                <ul>
                    <li>
                        <p>ViewMarketplacePaidItems: Allow user to see paid Marketplace items</p>
                    </li>
                    <li>
                        <p>DeviceAdminDeleteUnplugReplace: Access to removing vehicle, unpluging device, and replacing device.</p>
                    </li>
                </ul>
            </li>
        </ul>
        <p>
            <i>*If you are using dll version 5.7.1508-1510 this will require a dll update.</i>
        </p>
    </div>
);

const update1509: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    New SDK. Featuring the new{" "}
                    <a href="https://geotab.github.io/sdk/software/api/runner.html" target="_blank" rel="noopener noreferrer">
                        SDK Runner
                    </a>
                    , new methods and objects (
                    <a href="https://geotab.github.io/sdk/software/api/reference/" target="_blank" rel="noopener noreferrer">
                        click here to see the preview items
                    </a>
                    )
                </p>
            </li>
            <li>
                <p>Code snippets in the reference documentation. Now you can see working examples of the methods as they are used in the runner.</p>
            </li>
            <li>
                <p>.Net users will require a .DLL update to access the latest features.</p>
            </li>
        </ul>
        <h2>New methods</h2>
        <ul>
            <li>
                <p>CreateDatabase</p>
            </li>
            <li>
                <p>DatabaseExists</p>
            </li>
            <li>
                <p>GenerateCaptcha</p>
            </li>
            <li>
                <p>GetVersionInformation</p>
            </li>
        </ul>
        <h2>New objects</h2>
        <ul>
            <li>
                <p>AnnotationLog</p>
            </li>
            <li>
                <p>AnnotationLogSearch</p>
            </li>
            <li>
                <p>CaptchaAnswer</p>
            </li>
            <li>
                <p>CaptchaException</p>
            </li>
            <li>
                <p>Color</p>
            </li>
            <li>
                <p>CompanyDetails</p>
            </li>
            <li>
                <p>DVIRLog</p>
            </li>
            <li>
                <p>DVIRLogSearch</p>
            </li>
            <li>
                <p>DiagnosticCategory</p>
            </li>
            <li>
                <p>DistributionList</p>
            </li>
            <li>
                <p>DuplicateException</p>
            </li>
            <li>
                <p>DutyStatusAvailability</p>
            </li>
            <li>
                <p>DutyStatusAvailabilityDuration</p>
            </li>
            <li>
                <p>DutyStatusAvailablitySearch</p>
            </li>
            <li>
                <p>DutyStatusLog</p>
            </li>
            <li>
                <p>DutyStatusLogSearch</p>
            </li>
            <li>
                <p>DutyStatusLogType</p>
            </li>
            <li>
                <p>DutyStatusViolation</p>
            </li>
            <li>
                <p>DutyStatusViolationSearch</p>
            </li>
            <li>
                <p>DutyStatusViolationType</p>
            </li>
            <li>
                <p>EntityWithVersion</p>
            </li>
            <li>
                <p>FuelEconomyUnit</p>
            </li>
            <li>
                <p>FuelEvent</p>
            </li>
            <li>
                <p>FuelTransaction</p>
            </li>
            <li>
                <p>FuelTransactionProvider</p>
            </li>
            <li>
                <p>HosRuleSet</p>
            </li>
            <li>
                <p>IncludeGroups</p>
            </li>
            <li>
                <p>InvalidMyAdminUserException</p>
            </li>
            <li>
                <p>MapView</p>
            </li>
            <li>
                <p>NameEntity</p>
            </li>
            <li>
                <p>NameEntityWithVersion</p>
            </li>
            <li>
                <p>RadioDownloaderSearch</p>
            </li>
            <li>
                <p>Recipient</p>
            </li>
            <li>
                <p>RecipientType</p>
            </li>
            <li>
                <p>RegistrationException</p>
            </li>
            <li>
                <p>Search</p>
            </li>
            <li>
                <p>ShipmentLog</p>
            </li>
            <li>
                <p>ShipmentLogSearch</p>
            </li>
            <li>
                <p>TextMessageContentType</p>
            </li>
            <li>
                <p>Trailer</p>
            </li>
            <li>
                <p>TrailerAttachment</p>
            </li>
            <li>
                <p>TrailerAttachmentSearch</p>
            </li>
            <li>
                <p>TrailerSearch</p>
            </li>
            <li>
                <p>VersionInformation</p>
            </li>
            <li>
                <p>VolumeUnit</p>
            </li>
        </ul>
        <h2>Deprecated</h2>
        <ul>
            <li>
                <p>BingMapStyle</p>
            </li>
            <li>
                <p>EngineType</p>
            </li>
            <li>
                <p>EngineTypeSearch</p>
            </li>
            <li>
                <p>StatusDataRequestContent</p>
            </li>
        </ul>
    </div>
);

const update1508: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    DriverChangeSearch received new search points including: DeviceSearch, DriverSearch, FromDate and ToDate. Checkout the{" "}
                    <Link to="/myGeotab/apiReference/objecs">API Reference</Link> for more details
                </p>
            </li>
            <li>
                <p>
                    DistributionList is now supported by the API. Checkout the <Link to="/myGeotab/apiReference/objects">API Reference</Link> for more details. Some related objects are still pending
                    support (Notification, NotificationTemplate, BinaryData)
                </p>
            </li>
            <li>
                <p>
                    <a href="https://github.com/Geotab/sdk-addin-samples" aria-label="Add-In examples in SDK documentation" target="_blank" rel="noopener noreferrer">
                        Add-In examples
                    </a>{" "}
                    have been added to SDK documentation
                </p>
            </li>
            <li>
                <p>
                    When searching for Zones you can now specify a traversal method of the group tree. You can choose to include just the specified element, just the ancestors, just the descendants,
                    or both ancestors and descendants. See the ZoneSearch object in the <Link to="/myGeotab/apiReference/objects">API Reference</Link> for more details
                </p>
            </li>
            <li>
                <p>Fix documentation for object properties</p>
            </li>
            <li>
                <p>Added KnownIds: DiagnosticDeviceTotalIdleFuel</p>
            </li>
            <li>
                <p>
                    Added FuelTransaction API (<i>Beta</i>)
                </p>
            </li>
            <li>
                <p>
                    HOS/DVIR objects supported in API. Key objects are AnnotationLog, DVIRLog, DutyStatusAvailability, DutyStatusLog, DutyStatusViolation, and ShipmentLog. Check out the{" "}
                    <Link to="/myGeotab/apiReference/objects">API Reference</Link> for more details
                </p>
            </li>
            <li>
                <p>Added Groups property to StatusDataSearch. This allows searching for status data for devices in the supplied groups. This does not return interpolated results</p>
            </li>
            <li>
                <p>
                    Added from/to date search to UserSearch object. Checkout the <Link to="/myGeotab/apiReference/objects">API Reference</Link> for more details
                </p>
            </li>
            <li>
                <p>
                    <Link to="/myGeotab/apiReference/objects">API Reference</Link>, objects now show properties from inherited classes. For example GoDevice extends Device and will show properties
                    of GoDevice and Device in the documentation
                </p>
            </li>
            <li>
                <p>
                    Geotab Announces New{" "}
                    <a href="https://www.geotab.com/dev-channel/" target="_blank" rel="noopener noreferrer">
                        DEV Channel
                    </a>{" "}
                    for Developers
                </p>
            </li>
        </ul>
    </div>
);

const update1505: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>New condition types added - .Net will require dll update</li>
        </ul>
    </div>
);

const update1504: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>Add KnownId for DiagnosticDeviceTotalIdleFuelId - .Net will require dll update</li>
        </ul>
    </div>
);

//ToDo: Need Faizan's feedback on these forum post URLs
const update1502: ReactNode = (
    <div className="paragraph">
        <ul>
            <li>
                <p>
                    Get <i>all</i> zones now populating points correctly (
                    <a
                        href="https://helpdesk.geotab.com/entries/26004844-Get-Zone-return-distinct-points"
                        aria-label="forum post about zones populating points correctly"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        see forum post
                    </a>
                    )
                </p>
            </li>
            <li>
                <p>Fixed TimeZoneInfo isDaylightSavingsSupported always false using .Net API client</p>
            </li>
            <li>
                <p>
                    Units of measure have been converted to use Known Id's (
                    <a href="https://helpdesk.geotab.com/entries/52897090-MyGeotab-SDK-Update-KnownId" aria-label="forum post about using known Ids" target="_blank" rel="noopener noreferrer">
                        see forum post
                    </a>
                    )
                </p>
            </li>
            <li>
                <p>
                    Adding, setting and removing of some entities has been disabled via the API. Exception Event, Trip, Status Data, Fault Data, Log Record with exceptions for adding odometer and
                    engine hours adjustments and dismissing faults
                </p>
            </li>
            <li>
                <p>
                    Clearer documentation of date and long values in <Link to="/myGeotab/apiReference/methods">API Reference</Link>
                </p>
            </li>
            <li>
                <p>
                    Data Feed section added to Guides portion of SDK (
                    <a href="https://docs.google.com/document/d/1LJfb57qyBX2WklnqioHtlWkYN9xKBWxA_FIpaJzjKyY/edit" target="_blank" rel="noopener noreferrer">
                        see document
                    </a>
                    )
                </p>
            </li>
        </ul>
    </div>
);

const pageTitle: PageTitleProps = {
    title: "Release Notes",
    breadCrumbItems: ["MYG", "Release Notes"],
    button: {
        text: "Subscribe for Updates",
        link: "https://community.geotab.com/CommunitiesLogin?startURL=%2Fs%2Fgroup%2F0F92J000000bnW9SAI%2Fintegrators-hub%3Flanguage%3Den_US%26t%3D1643135255743"
    }
};

const pageSections: TableOfContentsItem[] = [
    {
        elementId: "v-11-37",
        summary: "September 6, 2024",
        details: update11Point37
    },
    {
        elementId: "v-11-35",
        summary: "August 23, 2024",
        details: update11Point35
    },
    {
        elementId: "v-11-34",
        summary: "August 16, 2024",
        details: update11Point34
    },
    {
        elementId: "v-11-33",
        summary: "August 9, 2024",
        details: update11Point33
    },
    {
        elementId: "v-11-32",
        summary: "August 2, 2024",
        details: update11Point32
    },
    {
        elementId: "v-11-31",
        summary: "July 26, 2024",
        details: update11Point31
    },
    {
        elementId: "v-11-30",
        summary: "July 19, 2024",
        details: update11Point30
    },
    {
        elementId: "v-11-29",
        summary: "July 12, 2024",
        details: update11Point29
    },
    {
        elementId: "v-11-27",
        summary: "June 28, 2024",
        details: update11Point27
    },
    {
        elementId: "v-11-26",
        summary: "June 21, 2024",
        details: update11Point26
    },
    {
        elementId: "v-11-25",
        summary: "June 14, 2024",
        details: update11Point25
    },
    {
        elementId: "v-11-24",
        summary: "June 7, 2024",
        details: update11Point24
    },
    {
        elementId: "v-11-23",
        summary: "May 31, 2024",
        details: update11Point23
    },
    {
        elementId: "v-11-22",
        summary: "May 24, 2024",
        details: update11Point22
    },
    {
        elementId: "v-11-21",
        summary: "May 17, 2024",
        details: update11Point21
    },
    {
        elementId: "v-11-20",
        summary: "May 10, 2024",
        details: update11Point20
    },
    {
        elementId: "v-11-19",
        summary: "May 3, 2024",
        details: update11Point19
    },
    {
        elementId: "v-11-18",
        summary: "April 26, 2024",
        details: update11Point18
    },
    {
        elementId: "v-11-17",
        summary: "April 19, 2024",
        details: update11Point17
    },
    {
        elementId: "v-11-16",
        summary: "April 12, 2024",
        details: update11Point16
    },
    {
        elementId: "v-11-15",
        summary: "April 5, 2024",
        details: update11Point15
    },
    {
        elementId: "v-11-14",
        summary: "March 29, 2024",
        details: update11Point14
    },
    {
        elementId: "v-11-13",
        summary: "March 22, 2024",
        details: update11Point13
    },
    {
        elementId: "v-11-12",
        summary: "March 15, 2024",
        details: update11Point12
    },
    {
        elementId: "v-11-11",
        summary: "March 8, 2024",
        details: update11Point11
    },
    {
        elementId: "v-11-10",
        summary: "March 1, 2024",
        details: update11Point10
    },
    {
        elementId: "v-11-9",
        summary: "February 23, 2024",
        details: update11Point9
    },
    {
        elementId: "v-11-7",
        summary: "February 2, 2024",
        details: update11Point7
    },
    {
        elementId: "v-11-6",
        summary: "January 26, 2024",
        details: update11Point6
    },
    {
        elementId: "v-11-4",
        summary: "January 12, 2024",
        details: update11Point4
    },
    {
        elementId: "v-11-0-6844",
        summary: "December 8, 2023",
        details: update11Point0Point6844
    },
    {
        elementId: "v-11-0-60xx",
        summary: "November 3, 2023",
        details: update11Point0Point60xx
    },
    {
        elementId: "v-11-0-48xx",
        summary: "September 8, 2023",
        details: update11Point0Point48xx
    },
    {
        elementId: "v-11-0-30xx",
        summary: "June 2, 2023",
        details: update11Point0Point30xx
    },
    {
        elementId: "v-11-0-29xx",
        summary: "May 26, 2023",
        details: update11Point0Point29xx
    },
    {
        elementId: "v-11-0-28xx",
        summary: "May 19, 2023",
        details: update11Point0Point28xx
    },
    {
        elementId: "v-11-0",
        summary: "11.0",
        details: update11
    },
    {
        elementId: "v-10-0",
        summary: "10.0",
        details: update10
    },
    {
        elementId: "v-9-0",
        summary: "9.0",
        details: update9
    },
    {
        elementId: "v-8-0",
        summary: "8.0",
        details: update8
    },
    {
        elementId: "v-7-0",
        summary: "7.0",
        details: update7
    },
    {
        elementId: "v-6-0",
        summary: "6.0",
        details: update6
    },
    {
        elementId: "v-2104",
        summary: "5.7.2104",
        details: update2104
    },
    {
        elementId: "v-2103",
        summary: "5.7.2103",
        details: update2103
    },
    {
        elementId: "v-2102",
        summary: "5.7.2102",
        details: update2102
    },
    {
        elementId: "v-2101",
        summary: "5.7.2101",
        details: update2101
    },
    {
        elementId: "v-2004",
        summary: "5.7.2004",
        details: update2004
    },
    {
        elementId: "v-2003",
        summary: "5.7.2003",
        details: update2003
    },
    {
        elementId: "v-2002",
        summary: "5.7.2002",
        details: update2002
    },
    {
        elementId: "v-2001",
        summary: "5.7.2001",
        details: update2001
    },
    {
        elementId: "v-1904",
        summary: "5.7.1904",
        details: update1904
    },
    {
        elementId: "v-1903",
        summary: "5.7.1903",
        details: update1903
    },
    {
        elementId: "v-1902",
        summary: "5.7.1902",
        details: update1902
    },
    {
        elementId: "v-1901",
        summary: "5.7.1901",
        details: update1901
    },
    {
        elementId: "v-1804Dot1",
        summary: "5.7.1804.1",
        details: update1804Dot1
    },
    {
        elementId: "v-1804",
        summary: "5.7.1804",
        details: update1804
    },
    {
        elementId: "v-1803",
        summary: "5.7.1803",
        details: update1803
    },
    {
        elementId: "v-1802",
        summary: "5.7.1802",
        details: update1802
    },
    {
        elementId: "v-1801",
        summary: "5.7.1801",
        details: update1801
    },
    {
        elementId: "v-1712",
        summary: "5.7.1712",
        details: update1712
    },
    {
        elementId: "v-1711",
        summary: "5.7.1711",
        details: update1711
    },
    {
        elementId: "v-1709",
        summary: "5.7.1709",
        details: update1709
    },
    {
        elementId: "v-1707",
        summary: "5.7.1707",
        details: update1707
    },
    {
        elementId: "v-1706",
        summary: "5.7.1706",
        details: update1706
    },
    {
        elementId: "v-1705",
        summary: "5.7.1705",
        details: update1705
    },
    {
        elementId: "v-1704",
        summary: "5.7.1704",
        details: update1704
    },
    {
        elementId: "v-1703",
        summary: "5.7.1703",
        details: update1703
    },
    {
        elementId: "v-1702",
        summary: "5.7.1702",
        details: update1702
    },
    {
        elementId: "v-1701",
        summary: "5.7.1701",
        details: update1701
    },
    {
        elementId: "v-1612",
        summary: "5.7.1612",
        details: update1612
    },
    {
        elementId: "v-1611",
        summary: "5.7.1611",
        details: update1611
    },
    {
        elementId: "v-1610",
        summary: "5.7.1610",
        details: update1610
    },
    {
        elementId: "v-1609",
        summary: "5.7.1609",
        details: update1609
    },
    {
        elementId: "v-1608",
        summary: "5.7.1608",
        details: update1608
    },
    {
        elementId: "v-1607",
        summary: "5.7.1607",
        details: update1607
    },
    {
        elementId: "v-1606",
        summary: "5.7.1606",
        details: update1606
    },
    {
        elementId: "v-1605",
        summary: "5.7.1605",
        details: update1605
    },
    {
        elementId: "v-1604",
        summary: "5.7.1604",
        details: update1604
    },
    {
        elementId: "v-1602",
        summary: "5.7.1602",
        details: update1602
    },
    {
        elementId: "v-1601",
        summary: "5.7.1601",
        details: update1601
    },
    {
        elementId: "v-1512",
        summary: "5.7.1512",
        details: update1512
    },
    {
        elementId: "v-1511",
        summary: "5.7.1511",
        details: update1511
    },
    {
        elementId: "v-1509",
        summary: "5.7.1509",
        details: update1509
    },
    {
        elementId: "v-1508",
        summary: "5.7.1508",
        details: update1508
    },
    {
        elementId: "v-1505",
        summary: "5.7.1505",
        details: update1505
    },
    {
        elementId: "v-1504",
        summary: "5.7.1504",
        details: update1504
    },
    {
        elementId: "v-1502",
        summary: "5.7.1502",
        details: update1502
    }
];

export default function ReleaseNotes() {
    return (
        <Page section={HeaderSections.MyGeotab} pageTitle={pageTitle} tableOfContents={pageSections}>
            <div className="paragraph">
                For a list of release notes for each software release, including bug fixes and functionality improvements, please refer to{" "}
                <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1Vp0cDkBAUj1ppOqzypUn3USkmRwltKg4W8LAOSOP0Ls/edit#heading=h.x3hgnbrgkk0">
                    Fleet Management Application Release Notes.
                </a>
            </div>
        </Page>
    );
}
