import { ReactNode } from "react";
import { Page } from "../../components";
import { PageTitleProps } from "../../components/PageTitle/PageTitle";
import { HeaderSections } from "../../components/Header/headerSectionsEnum";
import { TableOfContentsItem } from "../../components/TableOfContents/TableOfContents";
import { Link } from "react-router-dom";
import { CodeSample } from "../../components/CodeSamplesContainer";

const updateSeptember302024: ReactNode = (
    <div className="paragraph">
        <h2>GO Plan launch and plan management changes in Device Management and API</h2>
        <p>As previously mentioned, we are simplifying Rate Plans for ease of use and to support the launch of richer dataset experiences and tools. With these changes, the concept of individual plans will be less relevant.</p>
        <p>Starting October 1, 2024, the following changes will be made to the <strong>ChangeDeviceBillingPlanBulkAsync(...)</strong> and <strong>ChangeDeviceBillingPlan(...)</strong> methods:</p>
        <p><strong>IMPORTANT:</strong>  These changes are very important to note if an integration relies on hard-coded values for <code>devicePlanId</code>.</p>
        <ul>
            <li>If a device is actively billing, the <strong>ChangeDeviceBillingPlan</strong> methods will only work with Suspend, Base (if not on the GO Plan), and the GO Plan (if not on ProPlus). Any other <code>devicePlanId</code> values will be invalid. For all other plan changes, contact Geotab Support.</li>
            <li>If a device is in Suspend, the <strong>ChangeDeviceBillingPlan</strong> methods will only accept Restore (ID: 78), which places the device on the last active plan it was on before Suspend. Any other <code>devicePlanIds</code> will be invalid.</li>
            <li>If a device is not yet activated, the methods will accept all valid <code>devicePlanIds</code> for the device type.</li>
            <li>Terminate will continue to be supported via the <strong>TerminateDeviceBilling</strong> methods.</li>
        </ul>
        <p>
            <strong>NOTE:</strong> The GO Plan <code>devicePlanId</code> is 54.
        </p>
        <h2>Deprecated warranty-related data in APIs</h2>
        <p>In the June What's New announcement, we announced that we were phasing out warranty-related data in APIs as the Limited Lifetime Warranty has been applied to all GO devices.</p>
        <p>In this update, we have deprecated the following data in API calls associated with orders:</p>
        <ul>
            <li>PostOrder — Deprecated <code>warrantyOptionId</code> from the <strong>PostOrder</strong> method.</li>
            <li>GetWarrantyOptions — Deprecated <strong>GetWarrantyOptions</strong> method.</li>
        </ul>
        <h2>New API rate limits</h2>
        <p>To ensure optimal performance and reliability of our APIs, we will be introducing rate limits to the following methods at the end of October. Rate limiting helps manage the number of requests a user can make to our API in a given period, preventing excessive usage that can slow down the system.</p>
        <ul>
            <li><strong>PostOrder</strong> method — Limited to 100 requests per 15 minutes.</li>
            <li><strong>GetOnlineOrderStatus</strong> method — Limited to 250 requests per 15 minutes.</li>
        </ul>
        <p>These rate limits will help ensure that our API remains fast and responsive for everyone.</p>
        <h2>Removing the ability for Partners to terminate or make plan changes for third-party cameras via the API</h2>
        <p>In a future release, Partners will no longer be able to terminate or make plan changes for third-party cameras (devices that start with EVD) via the API to avoid billing discrepancies.</p>
        <p>This will impact the following API methods:</p>
        <ul>
            <li><strong>TerminateDeviceBilling</strong></li>
            <li><strong>TerminateDeviceBillingBulkAsync(...)</strong></li>
            <li><strong>ChangeDeviceBillingPlan</strong></li>
            <li><strong>ChangeDeviceBillingPlanBulkAsync(...)</strong></li>
        </ul>
        <p>We've also updated the Warranty status column to display "Not available" for third-party cameras in the MyAdmin UI temporarily, as well as the following API methods:</p>
        <ul>
            <li><strong>GetDeviceContractsByPage</strong></li>
            <li><strong>GetDeviceContracts</strong></li>
        </ul>
        <p>As a standard practice, MyAdmin calculates warranty status based on the billing start date. However, for these products, the warranty needs to be calculated from the shipment date. While we work to correctly display warranty in Device Management, you will see "Not available".</p>
        <h2>Simplifying orderItemProductAttributeGroups object in the PostOrder method</h2>
        <p>To simplify the integration process for IOX-KEYBOX and other configurable products, we are making some changes to the <strong>PostOrder</strong> method in a future release, specifically the <code>orderItemProductAttributeGroups</code> object within the <code>apiOrderHeader</code> model.</p>
        <p>We will be simplifying the structure:</p>
        <ul>
            <li>Deprecating the <code>OrderItemProductAttribute</code> table</li>
            <li>Moving the <code>sValue</code> column in the <code>OrderItemProductAttribute</code> table to the <code>OrderItemProductAttributeGroup</code> table</li>
        </ul>
        <p>Code Snippet - Before:</p>
        <CodeSample
            language="javascript"
            code={`"orderItemProductAttributeGroups": [
    {
        "orderItemProductAttributes": [
            {
                "ProductAttributeId": 1,
                "value": "101"
            }
        ],
        "quantity": 1
    },
    {
        "orderItemProductAttributes": [
            {
                "ProductAttributeId": 1,
                "value": "102"
            }
        ],
        "quantity": 2
    }
]`}
        />
        <p>Code Snippet - After:</p>
        <CodeSample
            language="javascript"
            code={`"orderItemProductAttributeGroups": [
    {
        "value": "101",
        "quantity": 1
    },
    {
        "value": "102",
        "quantity": 2
    }
]`}
        />
    </div>
);

const updateAugust262024: ReactNode = (
    <div className="paragraph">
        <h2>GO Plan launch update and plan management changes in Device Management and API</h2>
        <p>As previously mentioned, we are simplifying Rate Plans for ease of use and to support the launch of richer dataset experiences and tools. With these changes, the concept of individual plans will be less relevant.</p>
        <p>Changes will come to the <strong>ChangeDeviceBillingPlanBulkAsync(...)</strong> and <strong>ChangeDeviceBillingPlan(...)</strong> methods on October 1, 2024.</p>
        <p>
            <strong>IMPORTANT:</strong> These changes are very important to note if an integration relies on hard-coded values for <code>devicePlanId</code>.
        </p>
        <ul>
            <li>
                Actively billing
                <ul>
                    <li>If a device is actively billing, the <strong>ChangeDeviceBillingPlan</strong> methods will only work with Suspend, Base (if not on the GO Plan), and the GO Plan (if not on ProPlus). Any other <code>devicePlanId</code> values will be invalid. For all other plan changes, contact Geotab Support.
                    </li>
                </ul>
            </li>
            <li>
                Suspend
                <ul>
                    <li>If a device is in Suspend, the <strong>ChangeDeviceBillingPlan</strong> methods will only accept Restore (ID: 78), which places the device on the last active plan it was on before Suspend. Any other devicePlanIds will be invalid.
                    </li>
                </ul>
            </li>
            <li>
                Not yet activated
                <ul>
                    <li>If a device is not yet activated, the methods will accept all valid <code>devicePlanIds</code> for the device type.
                    </li>
                </ul>
            </li>
            <li>
                Terminate
                <ul>
                    <li>Terminate will continue to be supported via the <strong>TerminateDeviceBilling</strong> methods.</li>
                </ul>
            </li>
        </ul>
        <p>
            <strong>NOTE:</strong> The GO Plan <code>devicePlanId</code> is 54.
        </p>
        <h2>New API rate limits</h2>
        <p>To ensure optimal performance and reliability of our APIs, we will be introducing rate limits to the following methods in a future release. Rate limiting helps manage the number of requests a user can make to our API in a given period, preventing excessive usage that can slow down the system.</p>
        <p>
            <strong>PostOrder</strong> method — Limited to 100 requests per 15 minutes.
        </p>
        <p>
            <strong>GetOnlineOrderStatus</strong> method — Limited to 250 requests per 15 minutes.
        </p>
        <p>These rate limits will help ensure that our API remains fast and responsive for everyone.</p>
    </div>
);

const updateJuly292024: ReactNode = (
    <div className="paragraph">
        <h2>Deprecated the ExternalVendor method</h2>
        <p>Previously, there were two methods that allow you to register External Vendor Devices (EVDs) in MyAdmin</p>
        <ul>
            <li>
                <strong>BulkExternalVendor</strong> (registers multiple EVDs in bulk) and
            </li>
            <li>
                <strong>ExternalVendor</strong> (registers a single EVD)
            </li>
        </ul>
        As announced last month, we've deprecated the ExternalVendor method to optimize the EVD registration process.
        <h2>Coming Soon - GO Plan launch update and plan management changes in Device Management and API</h2>
        <p>
            As previously mentioned in last month's announcement and in <a target="_blank" rel="noopener noreferrer"
                href="https://www.geotab.com/blog/mygeotab-updates-april-2024/" aria-label="What's new in MyGeotab April 2024">
                April's What's New in MyGeotab</a>, we are simplifying Rate Plans for ease of use and to support the launch of richer dataset experiences
            and tools. With these changes, the concept of individual plans will be less relevant. At the end of September, there will be changes made to
            the change plan functionality in Device Management (Devices {">"} Device Management) and related APIs.
        </p>
        <p>
            <strong>NOTE:</strong> We've included new details about the GO and Base plans, as well as clarification on devices that have yet to activate.
            You can find more details on how this change might impact you in July's What's New Release Notes <a
                href="https://docs.google.com/document/d/1gLlr8FTEZ1zkKOCUE9tD0MVvwtKS35sHw9MpUtInskY/edit?usp=sharing"
                target="_blank" rel="noopener noreferrer">here</a>.
        </p>
        <p>
            <strong>IMPORTANT:</strong> These changes are very important to note if an integration relies on hard-coded values for <code>devicePlanId</code>.
        </p>
        <p>
            Specifically, active GO devices cannot change plans starting October 1, 2024:
        </p>
        <ul>
            <li>
                Actively billing — If a device is actively billing, the <strong>ChangeDeviceBillingPlan</strong> methods will only work with Suspend, Base (if not on the GO Plan), and the GO Plan (if not on ProPlus). Any other <code>devicePlanId</code> values will be invalid.
            </li>
            <li>
                Suspend — If a device is in Suspend, the <strong>ChangeDeviceBillingPlan</strong> methods will only accept Restore (ID: 78), which places the device on the last
                active plan it was on before Suspend. Any other <code>devicePlanIds</code> will be invalid.
            </li>
            <li>
                Not yet activated — If a device is not yet activated, the methods will accept all valid <code>devicePlanIds</code> for the device type.
            </li>
            <li>
                Terminate — Terminate will continue to be supported via the <strong>TerminateDeviceBilling</strong> methods.
            </li>
        </ul>
        <strong>NOTE:</strong> The GO Plan and Asset Plan <code>devicePlanId</code>s are 54 and 55, respectively.
    </div>
);

const updateJune242024: ReactNode = (
    <div className="paragraph">
        <h2>New Changes - Action Required</h2>
        <strong>Coming soon</strong> - Plan management changes in Device Management and API
        <ul>
            <li>
                At the end of August, there will be changes made to the change plan functionality in Device Management (Devices {">"} Device Management) and related APIs.
                Specifically, active GO devices cannot change plans after September 1, 2024.
                These changes will come to the <strong>ChangeDeviceBillingPlanBulkAsync</strong> and <strong>ChangeDeviceBillingPlan</strong> methods.
            </li>
            <li>
                <strong>IMPORTANT:</strong> These changes are very important to note if an integration relies on hardcoded values for <code>devicePlanId</code>.
            </li>
            <li>
                Actively billing — If a device is actively billing, the <strong>ChangeDeviceBillingPlan</strong> methods will only work with Suspend. Any other <code>devicePlanId</code>
                values will be invalid.
            </li>
            <li>
                Suspend — If a device is in Suspend, the <strong>ChangeDeviceBillingPlan</strong> methods will only accept Restore (ID: 78), which places the device on the last
                active plan it was on before Suspend. Any other <code>devicePlanIds</code> will be invalid.
            </li>
            <li>
                Not yet activated — If a device is not yet activated, the methods will accept all valid <code>devicePlanIds</code> for the device type.
            </li>
            <li>
                Terminate — Terminate will continue to be supported via the <strong>TerminateDeviceBilling</strong> methods.
            </li>
        </ul>
        <h2>Updates</h2>
        <h3>Updated firstDeviceActivationDate to align with First Connect Date in Device Admin</h3>
        We've updated the <code>firstDeviceActivationDate</code> property (in <strong>ApiDeviceContract</strong> and <strong>DeviceContract</strong> objects) to retrieve the same data as the
        <code>First Connect Date</code> column in <strong>Device Admin</strong>.
        <h3>Device Plan changes in Checkout</h3>
        <ul>
            <li>
                As previously announced, you will only need to select a Device Plan for GO devices when checking out. Plans are now assigned automatically for all
                other types of products (such as GO Anywhere). All new orders of GO Anywhere devices will be activated on the Asset Plan, and you will no longer be
                required to select the plan during the checkout process.
            </li>
            <li>
                For orders placed via the <strong>PostOrder method</strong>, the <code>DevicePlanLevel</code> is only applied to GO devices in the order. All other plans are automatically
                assigned.
            </li>
        </ul>
        <h3>Phasing out warranty-related data in APIs</h3>
        <ul>
            <li>
                We announced that on April 27, 2024, the Limited Lifetime Warranty has been applied to all GO devices to provide you with long-term coverage, no
                matter which plan you are on.
            </li>
            <li>
                As previously announced, we are now phasing out warranty-related data in API calls associated with orders.
            </li>
            <li>
                <strong>NOTE:</strong> For integration continuity, these will remain active and return null values. We'll have another update in a few months to
                let you know when we're deprecating them.
                <ul>
                    <li>
                        PostOrder — Phasing out <code>warrantyOptionId</code> from the <strong>PostOrder</strong> method.
                    </li>
                    <li>
                        GetWarrantyOptions — Phasing out <strong>GetWarrantyOptions</strong> method.
                    </li>
                </ul>
            </li>
        </ul>
        <h3>Deprecated currentStatus property in ApiOnlineOrder</h3>
        <ul>
            <li>
                As previously announced, we have deprecated <code>currentStatus</code> property in <strong>ApiOnlineOrder</strong>.
            </li>
            <li>
                <strong>NOTE:</strong> The <code>currentStatus</code> property has been replaced by <code>currentOrderStatus</code> to return the current status of the order.
            </li>
        </ul>
        <h3>Deprecating the ExternalVendor method</h3>
        <ul>
            <li>
                Currently, there are two methods that allow you to register External Vendor Devices (EVDs) in MyAdmin - <strong>BulkExternalVendor</strong>
                (registers multiple EVDs in bulk) and <strong>ExternalVendor</strong> (registers a single EVD).
            </li>
            <li>
                At the end of July, we will deprecate the <strong>ExternalVendor</strong> method to optimize the EVD registration process.
            </li>
        </ul>
    </div>
);

const updateMay272024: ReactNode = (
    <div className="paragraph">
        <h2>Added character limits to Checkout fields</h2>
        <ul>
            <li>
                As previously announced, we added character limits to the following fields in Checkout:
                <ul>
                    <li>
                        My reference — 500-character limit
                    </li>
                    <li>
                        General comments for packing slip — 300-character limit
                    </li>
                    <li>
                        Order comments — 300-character limit
                    </li>
                </ul>
            </li>
            <li>
                <strong>NOTE</strong>: Orders processed through the PostOrder method that exceed these character limits will be placed; however, please note that the packing slip will not display any characters exceeding the limit.
            </li>
        </ul>
        <h2>Updated the GetOnlineOrderStatus endpoint</h2>
        <ul>
            <li>
                In addition to updating the values in Order source, we have added the <code>Store</code> and <code>Bulk</code> values to the <strong>GetOnlineOrderStatus</strong> endpoint. MyAdmin will remain as a value for integration continuity.
            </li>
        </ul>
        <h2>Added ChangeDeviceBillingPlanBulk method for bulk billing plan changes</h2>
        <ul>
            <li>
                Currently, Partners use the <strong>ChangeDeviceBillingPlan</strong> API to change the device billing plan, which takes 60 to 90 seconds per device to process. This can take a long time if you're changing plans for a large amount of devices.
            </li>
            <li>
                To help with this, we've added the <strong>ChangeDeviceBillingPlanBulk</strong> method to handle bulk changes. With this method, you can change the device billing plan for up to 500 devices at a time. For information, refer to the <Link to="../myAdmin/apiReference/methods">API References</Link> page.
            </li>
        </ul>
        <h2>Added TerminateDeviceBillingBulk method to terminate multiple devices at a time</h2>
        <ul>
            <li>
                To streamline the process of terminating multiple devices, we've added a new API method: <strong>TerminateDeviceBillingBulk</strong>. With this method, you can terminate up to 500 devices at a time. For information, refer to the <Link to="../myAdmin/apiReference/methods">API References</Link> page.
            </li>
        </ul>
        <h2>Reminder — Deprecating currentStatus property in ApiOnlineOrder</h2>
        <ul>
            <li>
                As previously announced, we will be deprecating <code>currentStatus</code> property in a future release. We've extended the deprecation date to June 22, 2024.
            </li>
            <li>
                <strong>NOTE</strong>: The <code>currentStatus</code> property has been replaced by <code>currentOrderStatus</code> to return the current status of the order.
            </li>
        </ul>
        <h2>Phasing out warranty-related data in APIs</h2>
        <ul>
            <li>
                We announced last month that on April 27, 2024, the Limited Lifetime Warranty has been applied to all GO devices to provide you with long-term coverage, no matter which plan you are on.
            </li>
            <li>
                Next month, we will be phasing out warranty-related data in API calls associated with orders.
            </li>
            <li>
                <strong>NOTE</strong>: For integration continuity, these will remain active and return null values when this is released. We'll have another update in a few months to let you know when we're deprecating them.
                <br />
                PostOrder — Phasing out <code>warrantyOptionId</code> from the <strong>PostOrder</strong> method.
                <br />
                GetWarrantyOptions — Phasing out <strong>GetWarrantyOptions</strong> method.
            </li>
        </ul>
    </div>
);

const updateApril292024: ReactNode = (
    <div className="paragraph">
        <h2>Updated firstDeviceActivationDate to align with First Connect Date in Device Admin</h2>
        <ul>
            <li>
                We've updated the <code>firstDeviceActivationDate</code> property (in <strong>ApiDeviceContract</strong> and <strong>DeviceContract</strong> objects) to retrieve the same data as <strong>First Connect Date</strong> column in <strong>Device Admin</strong>.
            </li>
        </ul>
        <h2>Added OptionalPara parameter to GetDeviceContracts method</h2>
        <ul>
            <li>
                As previously announced, we've added the <code>OptionalPara</code> parameter to the <strong>GetDeviceContracts</strong> method. This will allow Integrators to select and define the properties they want to see in the returned object.
            </li>
            <li>
                Previously, when device information was retrieved through the <strong>GetDeviceContracts</strong> method, the only option available was to return all data for a device. With this update, you can control how much data you want to retrieve, reducing the response times and the amount of data that has to be processed.
            </li>
        </ul>
        <h2>Reminder — Deprecating currentStatus property in APIOnlineOrder</h2>
        <ul>
            <li>
                We've extended the deprecation date for the <code>currentStatus</code> property to May 25, 2024.
            </li>
            <li>
                <strong>NOTE</strong>: The <code>currentStatus</code> property has been replaced by <code>currentOrderStatus</code> to return the current status of the order.
            </li>
        </ul>
    </div>
);

const updateMarch252024: ReactNode = (
    <div className="paragraph">
        <h2>Added newCustomerPlan property to ApiDeviceContractRatePlan method</h2>
        <ul>
            <li>
                To help you determine whether a device is on the New Customer Plan, the <strong>ApiDeviceContractRatePlan</strong> method will now have a new property: <code>newCustomerPlan</code>. This new property will return one of the following responses:
                <ul>
                    <li>
                        <strong>true</strong> — The device is on the New Customer Plan
                    </li>
                    <li>
                        <strong>false</strong> — The device is not on the New Customer Plan
                    </li>
                </ul>
            </li>
        </ul>
        <h2>PostOrder</h2>
        <ul>
            <li>
                As previously announced, we have added character limits to the following fields:
                <ul>
                    <li>
                        <code>My reference</code> — 500-character limit
                    </li>
                    <li>
                        <code>General comments for packing slip</code> — 300-character limit
                    </li>
                    <li>
                        <code>Order comments</code> — 300-character limit
                    </li>
                </ul>
            </li>
            <li>
                Orders processed through the <strong>PostOrder</strong> method that exceed these character limits will not be placed, and will prompt an error.
            </li>
        </ul>
        <h2>Moving devicePlanLevel property to ApiOnlineOrderItem API</h2>
        <ul>
            <li>
                In a future release, we will be improving the checkout experience for selecting Device Plans when you buy devices. Currently, a single Device Plan applies to your entire order. Soon, you’ll be able to select and assign a Device Plan for each product in your cart. For GO Anywhere devices, the Device Plan will be assigned automatically.
            </li>
            <li>
                Together with the upcoming feature that allows assigning a Device Plan for each product in your cart in the MyAdmin UI, we will be moving the <code>devicePlanLevel</code> property from the <strong>ApiOrderHeader</strong> API to <strong>ApiOnlineOrderItem</strong> in a future release.
            </li>
        </ul>
        <h2>Adding OptionalPara parameter to GetDeviceContracts method</h2>
        <ul>
            <li>
                In a future release, we will be adding the <code>OptionalPara</code> parameter to the <strong>GetDeviceContracts</strong> method. This will allow Integrators to select and define the properties they want to see in the returned object, as well as improve response times.
            </li>
        </ul>
        <h2>Updating firstDeviceActivationDate to Align with First Connect Date in Device Admin</h2>
        <ul>
            <li>
                In a future release, we will be renaming the <strong>First Device Activation Date</strong> column to <strong>First Connect Date</strong> in the Monthly Billing report (accessible via Billing &gt; Monthly Billings). This change is to align with the <strong>First Connect Date</strong> column in Device Admin.
            </li>
            <li>
                We will be updating the <code>firstDeviceActivationDate</code> property (in <strong>ApiDeviceContract</strong> and <strong>DeviceContract</strong> objects) to retrieve the same data as the <strong>First Connect Date</strong> column in <strong>Device Admin</strong>.
            </li>
        </ul>
        <h2>Deprecating currentStatus property in ApiOnlineOrder</h2>
        <ul>
            <li>
                On April 27, 2024, we will be deprecating the <code>currentStatus</code> property, as it has been replaced by <code>currentOrderStatus</code> to return the current status of the order.
            </li>
            <li>
                The deprecation of <code>currentStatus</code> was previously communicated in March 2021, which was also when <code>currentOrderStatus</code> became available for use.
            </li>
        </ul>
    </div>
);

const updateFebruary262024: ReactNode = (
    <div className="paragraph">
        <h2>Max results for unpaginated endpoints</h2>
        <ul>
            <li>Starting February 10, 2024, most of our API endpoints that provide all results in a single request will be limited to a maximum of 100,000 results.</li>
            <li>Some endpoints that previously returned more than 100,000 results (prior to 2024) will maintain their existing functionality to ensure continuous API backward-compatibility.</li>
        </ul>
        <h2>PostOrder</h2>
        <ul>
            <li>
                In a future release, we will be adding character limits to the following fields:
                <ul>
                    <li>
                        <code>My reference</code> — 500-character limit
                    </li>
                    <li>
                        <code>General comments for packing slip</code> — 300-character limit
                    </li>
                    <li>
                        <code>Order comments</code> — 300-character limit
                    </li>
                </ul>
            </li>
            <li>
                Orders processed through the <strong>PostOrder</strong> method that exceed these character limits will not be placed.
            </li>
        </ul>
        <h2>Updating firstDeviceActivationDate to align with first connect date in Device Admin</h2>
        <ul>
            <li>
                We will be updating the <code>firstDeviceActivationDate</code> property (in <strong>ApiDeviceContract</strong> and <strong>DeviceContract</strong>{" "}
                objects) to retrieve the same data as the <strong>First Connect Date</strong> column in <strong>Device Admin</strong>.
            </li>
        </ul>
        <h2>Deprecating currentStatus property in ApiOnlineOrder</h2>
        <ul>
            <li>
                On April 27, 2024, we will be deprecating the <code>currentStatus</code> property, as it has been replaced by{" "}
                <code>currentOrderStatus</code> to return the current status of the order.
            </li>
            <li>
                The deprecation of <code>currentStatus</code> was previously communicated in March 2021, which was also when{" "}
                <code>currentOrderStatus</code> became available for use.
            </li>
        </ul>
    </div>
);

const updateJanuary152024: ReactNode = (
    <div className="paragraph">
        <h2>ApiRatePlan</h2>
        <ul>
            <li>
                In September 2023, we added two parameters (<code>isAutoActivated</code> and <code>billingStartDate</code>) to the{" "}
                <strong>GetDeviceContractsByPage</strong> and <strong>GetDeviceContracts</strong> methods. As a result, we have now deprecated the{" "}
                <code>isAutoActivated</code> property from the <strong>ApiRatePlan</strong> object.
            </li>
        </ul>
    </div>
);

const updateDecember042023: ReactNode = (
    <div className="paragraph">
        <h2>ApiRatePlan</h2>
        <ul>
            <li>
                In September, we added two parameters (<code>isAutoActivated</code> and <code>billingStartDate</code>) to the{" "}
                <strong>GetDeviceContractsByPage</strong> and <strong>GetDeviceContracts</strong> methods. As a result, we will be deprecating the{" "}
                <code>isAutoActivated</code> property from the <strong>ApiRatePlan</strong> object.
            </li>
        </ul>
    </div>
);

const updateOctober042023: ReactNode = (
    <div className="paragraph">
        <h2>PostOrder</h2>
        <ul>
            <li>
                Currently, for the <strong>PostOrder</strong> method, <strong>InvalidDataException</strong> is returned when there is an exception. In a future release, these exceptions will be
                returned as a <strong>MyAdminException</strong> instead.
            </li>
        </ul>
    </div>
);

const updateSeptember052023: ReactNode = (
    <div className="paragraph">
        <h2>GetDeviceContractsByPage & GetDeviceContracts</h2>
        <ul>
            <li>
                Along with the new <code>Auto-Activated</code> and <code>Billing Start Date</code> columns in Device Admin, we have added two new parameters to the <strong>GetDeviceContractsByPage</strong> and <strong>GetDeviceContracts</strong> methods:
            </li>
            <ul>
                <li>
                    <code>isAutoActivated</code> - Returns a result that indicates whether the device was auto-activated by Geotab.
                </li>
                <ul>
                    <li>
                        <strong>true</strong> - The device was auto-activated by Geotab.
                    </li>
                    <li>
                        <strong>false</strong> - The device has yet to be activated or was activated from being installed in an asset.
                    </li>
                </ul>
                <li>
                    <code>billingStartDate</code> - Returns the date when the device was activated.
                </li>
            </ul>
        </ul>
        <h2>CalculateVinOemEligibilitiesAsync and GetOemSupportEligibilityForMakeAsync</h2>
        <ul>
            <li>
                Together with the Vehicle / VIN Lookup page improvements, we've added the following APIs to allow you to confirm whether a vehicle or VIN is eligible for OEM connectivity services:
            </li>
            <ul>
                <li>
                    <strong>CalculateVinOemEligibilitiesAsync</strong> - Returns a result that indicates whether a VIN in a specific region is eligible for OEM connectivity services.
                </li>
                <li>
                    <strong>GetOemSupportEligibilityForMakeAsync</strong> - Returns a result that indicates whether a vehicle make and model in a specific region is eligible for OEM connectivity services.
                </li>
            </ul>
        </ul>
        <h2>GetOnlineOrderStatus</h2>
        <ul>
            <li>
                We have added a new <code>shippingCost</code> field as a return object to the <strong>GetOnlineOrderStatus</strong> method. This field will return the shipping cost at the time the order was placed.
            </li>
        </ul>
    </div>
);

const updateJuly292023: ReactNode = (
    <div className="paragraph">
        <h2>MyAdminAPIUser</h2>
        <ul>
            <li>
                We have deprecated the <strong>MyAdminApiUser</strong> role. This role was initially used to make MyAdmin API calls, but we confirmed that API calls are open to anyone with a MyAdmin account. As a result, we have deprecated the role to reduce confusion and simplify access to our API.
            </li>
        </ul>
        <h2>CalculateVinOemEligibilitiesAsync and GetOemSupportEligibilityForMakeAsync</h2>
        <ul>
            <li>
                Together with the Vehicle / VIN Lookup page improvements, we've added the following APIs to allow you to confirm whether a vehicle or VIN is eligible for OEM connectivity services:
            </li>
            <ul>
                <li>
                    <strong>CalculateVinOemEligibilitiesAsync</strong> - Returns a result that indicates whether a VIN in a specific region is eligible for OEM connectivity services.
                </li>
                <li>
                    <strong>GetOemSupportEligibilityForMakeAsync</strong> - Returns a result that indicates whether a vehicle make and model in a specific region is eligible for OEM connectivity services.
                </li>
            </ul>
        </ul>
        <h2>DatabaseExists</h2>
        <ul>
            <li>
                To save time when creating new databases, we've added a new <strong>DatabaseExists</strong> method that returns a true or false value to allow you to confirm whether a database name already exists:
            </li>
            <ul>
                <li>
                    <strong>true</strong> - The database name already exists
                </li>
                <li>
                    <strong>false</strong> - The database name does not exist
                </li>
            </ul>
        </ul>
    </div>
);

const updateJuly102023: ReactNode = (
    <div className="paragraph">
        <h2>GetOnlineOrderStatus</h2>
        <ul>
            <li>
                In a future release, we will be adding a new <code>shippingCost</code> field as a return object to the <strong>GetOnlineOrderStatus</strong> method.
                This field will return the shipping cost at the time the order was placed.
            </li>
            <li>
                In a future release, the <strong>GetOnlineOrderStatus</strong> method will add a default date range and return order data from the last month if the following parameters are sent as
                null:
            </li>
            <ul>
                <li>purchaseOrderNo</li>
                <li>orderNo</li>
                <li>orderDateFrom</li>
                <li>orderDateTo</li>
            </ul>
            <li>There will also be a limit of 5000 orders that can be retrieved in a single request.</li>
        </ul>
    </div>
);

const updateJuly012023: ReactNode = (
    <div className="paragraph">
        <h2>ProvisionDevicesBulk</h2>
        <ul>
            <li>
                Currently, <strong>ProvisionDevice</strong> and <strong>ProvisionDeviceToAccount</strong> methods are available to provision a single third-party custom telematics or OEM device (via the serial number).
            </li>
            <li>
                To help you manage large quantities of devices, we've added a new <strong>ProvisionDevicesBulk</strong> method to provision multiple third-party custom telematics devices or OEM in bulk with an optional quantity field.
            </li>
        </ul>
        <h2>GetDeviceContractsByPage and GetDeviceContracts</h2>
        <ul>
            <li>
                In a future release, we will be adding two new columns to Device Admin: <code>Auto-Activated</code> and <code>Billing Start Date</code> to improve visibility of auto-activation events, as well as the actual start of billing for a device.
            </li>
            <li>
                The <code>Auto-Activated</code> column will help you identify whether a device has been automatically activated by Geotab, and display the following values:
            </li>
            <ul>
                <li>
                    <strong>Yes</strong> - The device was automatically activated by Geotab.
                </li>
                <li>
                    <strong>No</strong> - The device was activated by installation.
                </li>
                <li>
                    <strong>Not Available</strong> - The device has been shipped, but has not been installed, or auto-activated.
                    <br />
                    <strong>NOTE</strong>: For devices shipped prior to the release of this feature, <strong>Not Available</strong> will be displayed.
                </li>
            </ul>
        </ul>
        <ul>
            <li>
                The <code>Billing Start Date</code> column will help you determine when billing started for a device. This column will display one of the following values:
            </li>
            <ul>
                <li>
                    <strong>Date</strong> - Displays the date that the device was auto - activated, installed, or transferred from another Partner.
                    <br />
                    <strong>NOTE</strong>: Plan changes for devices will not impact this date.
                </li>
                <li>
                    <strong>Not Available</strong> - Indicates that a device has been shipped, but has not been installed, or auto - activated.
                    <br />
                    <strong>NOTE</strong>: For devices shipped prior to the release of this feature, <strong>Not Available</strong> will be displayed.
                </li>
            </ul>
            <li>
                Therefore, we will also be making data points of <code>Auto-Activated</code> and <code>Billing Start Date</code> available through the < strong> GetDeviceContractsByPage</strong> and < strong> GetDeviceContracts</strong> APIs in a future release.We will provide more details closer to the release date.
            </li>
        </ul>

        <h2>GetOnlineOrderStatus</h2>
        <ul>
            <li>
                In an upcoming release, we will be adding the following parameters to the <strong>GetOnlineOrderStatus</strong> API:
            </li>
            <li>
                <code>promoCode</code> - Returns the promo code used when the order was placed, if applicable.
            </li>
            <li>
                <code>rateCode</code> - Returns the promo code used for a monthly Rate Plan subscription when the order was placed, if applicable.
            </li>
        </ul>
    </div>
);

const updateMay292023: ReactNode = (
    <div className="paragraph">
        <h2>GetPartnerDeviceContractsAsync</h2>
        <ul>
            <li>
                To help you find devices faster, we've updated the <strong>GetPartnerDeviceContractsAsync</strong> API to include a database search parameter (
                <code>databaseFilter</code>). This parameter allows you to retrieve a list of devices that are owned by the specified database.
            </li>
        </ul>
    </div>
);

const updateMarch272023: ReactNode = (
    <div className="paragraph">
        <h2>ApiDeviceContract</h2>
        <ul>
            <li>
                In an upcoming release, we will use the gateway's activation date as the source for <code>FirstDeviceActivationDate</code> when returning an{" "}
                <strong>ApiDeviceContract</strong> object (for example, by calling <strong>GetDeviceContractsByPage</strong>) to align with the Device Admin page
            </li>
        </ul>
    </div>
);

const updateMarch062023: ReactNode = (
    <div className="paragraph">
        <h2>ApiDeviceContract</h2>
        <ul>
            <li>
                In an upcoming release, we will use the gateway's activation date as the source for <code>FirstDeviceActivationDate</code> when returning an{" "}
                <strong>ApiDeviceContract</strong> object (for example, by calling <strong>GetDeviceContractsByPage</strong>) to align with the Device Admin page
            </li>
        </ul>
    </div>
);

const updateFebruary132023: ReactNode = (
    <div className="paragraph">
        <h2>GetPartnerDeviceContractsAsync</h2>
        <ul>
            <li>
                Currently, the <strong>GetPartnerDeviceContractsAsync</strong> method allows you to return a list of third-party devices under your account.
            </li>
            <li>
                In a future release, we'll enhance the <strong>GetPartnerDeviceContractsAsync</strong> method to return a list that includes all device types, including GO devices.
            </li>
        </ul>
    </div>
);

const updateJanuary232023: ReactNode = (
    <div className="paragraph">
        <h2>EditUserContact</h2>
        <ul>
            <li>
                To ensure that Customers receive their orders in a timely manner, we've updated the <strong>EditUserContact</strong> method to validate provided addresses with the option to override
                invalid addresses if the user has the <code>Contact-Override</code> role.
            </li>
            <li>The API call will now match how shipping contacts are validated in checkout.</li>
        </ul>
    </div>
);

const updateDecember122022: ReactNode = (
    <div className="paragraph">
        <h2>DeviceContractsByPage</h2>
        <ul>
            <li>
                The <code>fromdate</code> and <code>todate</code> parameters are now optional in the GetDeviceContractsByPage API, so more
                records can be retrieved.
            </li>
        </ul>
        <h2>OnlineOrderStatus</h2>
        <ul>
            <li>
                A new <code>OrderSource</code> parameter has been added to the GetOnlineOrderStatus method.
            </li>
            <li>
                The <code>OrderSource</code> parameter allows you to view a list of orders based on the channel used to place the order: MyAdmin, Marketplace, or API.
            </li>
        </ul>
        <h2>EditUserContact</h2>
        <ul>
            <li>
                In an upcoming release, we will update the <strong>EditUserContact</strong> method logic to validate provided addresses, and provide the option to override invalid addresses, if the
                user has the <code>Contact-Override</code> role.
            </li>
        </ul>
    </div>
);

const updateDecember052022: ReactNode = (
    <div className="paragraph">
        <h2>DeviceContractTransactions</h2>
        <ul>
            <li>
                When calling <strong>GetDeviceContractTransactions</strong>, you will now see a new property in the returned <strong>DeviceContractTransactions</strong> object: int{" "}
                <code>UserContactId</code>
            </li>
            <li>
                If null, <code>UserContactId</code> will return -1
            </li>
            <li>Else, it will return the unique ID that belongs to the user contact</li>
            <li>
                This ID can be leveraged alongside <strong>GetUserContacts</strong> to return all the contact details of the user contact (currently <strong>DeviceContractTransactions</strong> only
                returns <code>UserContactName</code>)
            </li>
        </ul>
        <h2>ForceChangePasswordForUsersAsync</h2>
        <ul>
            <li>
                The <strong>ForceChangePasswordForUsersAsync</strong> method now allows you to toggle on 'Force Password Change' for user accounts so that you can decide how often users are
                resetting their passwords
            </li>
            <li>
                The <strong>ForceChangePasswordForUsersAysync</strong> requires the <code>apikey</code>, <code>sessionID</code>, and a
                list of usernames <code>userNames</code>, and you must toggle <code>forceChangePassword</code> to True or False.
            </li>
        </ul>
        <h2>EditUserContact</h2>
        <ul>
            <li>
                In an upcoming release, we will update the <strong>EditUserContact</strong> method logic to validate provided addresses, and provide the option to override invalid addresses, if the
                user has the <code>Contact-Override</code> role.
            </li>
        </ul>
    </div>
);

const updateNovember142022: ReactNode = (
    <div className="paragraph">
        <h2>ApiOnlineOrder</h2>
        <ul>
            <li>
                A new source parameter has been added to the <code>ApiOnlineOrder</code> object: <code>OrderSource.</code>
            </li>
            <li>The Order source parameter allows you to determine the channel used to place the order: MyAdmin, Marketplace, or API.</li>
        </ul>
        <h2>MyInstallLogs</h2>
        <ul>
            <li>
                A new <strong>GetMyInstallLogs</strong> method has been added to allow you to easily retrieve your installation record information.
            </li>
        </ul>
        <h2>CreateExternalVendorDeviceBulkAsync</h2>
        <ul>
            <li>
                A new method, <strong>CreateExternalVendorDeviceBulkAsync</strong>, has been added to make it easier for Marketplace Partners to offer products that do not connect to Geotab
                gateways.
            </li>
            <li>
                The <strong>CreateExternalVendorDeviceBulkAsync</strong> method allows Marketplace Partners to register devices using serial numbers, as well as the option to include IMEI and SIM
                numbers, so they show up in MyAdmin.
            </li>
            <li>
                To use this method, you must have the <code>DeviceRegister-Bulk</code> role.
            </li>
        </ul>
    </div>
);

const updateSeptember192022: ReactNode = (
    <div className="paragraph">
        <h2>ApiDeviceContract</h2>
        <ul>
            <li>
                <strong>ApiDeviceContract</strong> has been updated with a boolean value for the <code>isAutoActivated</code> parameter to indicate whether a device was auto-activated by Geotab using True or False.
            </li>
            <li>
                The True label indicates the device was auto-activated by Geotab.
            </li>
        </ul>
        <h2>AccountsFromSerialsAsync</h2>
        <ul>
            <li>
                A new API, <strong>GetAccountsFromSerialsAsync</strong>, has been added to allow our Partners with multiple accounts identify the account associated with a specified device, and facilitates the use of other MyAdmin APIs that require account information.
            </li>
        </ul>
        <h2>OnlineOrderStatus</h2>
        <ul>
            <li>
                A new parameter, <code>poExactMatch</code>, has been added to <strong>GetOnlineOrderStatus</strong> to allow you to search for orders that match a specific PO number.
            </li>
            <li>
                To search for orders that match an exact PO number, use double quotation marks.
            </li>
        </ul>
        <h2>End of life - MyAdmin APIs for third-party device data ingestion</h2>
        <ul>
            <li>
                On September 30, 2022, the MyAdmin APIs for third-party device data ingestion into MyGeotab will be permanently discontinued. After this date, MyGeotab will be unable to receive data from any devices that have not migrated to DIG.
            </li>
            <li>
                For more information, refer to the{" "}
                <a
                    href="https://docs.google.com/document/d/1hJj2CEMJlgbXHjF-it8Gey8b5_EnUDCqqhS8jRtxGyw/edit#heading=h.dzk3awl3nlud"
                    aria-label="Migrating to DIG from MyAdmin API/SDK guide"
                    target="_blank"
                    rel="noopener noreferrer">
                    Migrating to DIG from MyAdmin API/SDK guide
                </a>
                .
            </li>
            <li>
                If you're a new Partner with a standalone telematics device, refer to the{" "}
                <Link
                    to="./../../myGeotab/guides/usingCustomTelematicsDevices">
                    Using Custom Telematics Devices
                </Link>{" "}
                section in the MyGeotab SDK.
            </li>
        </ul>
    </div>
);

const updateSeptember122022: ReactNode = (
    <div className="paragraph">
        <h2>GetDeviceContracts</h2>
        <ul>
            <li>
                <strong>GetDeviceContracts</strong> has been updated for Partners that closely integrate their billing with MyAdmin
            </li>
            <li>
                The API now identifies the Rate Plan of a specific device in real time, as opposed to only the device mode
            </li>
        </ul>
        <h2>Moving the MyAdmin SDK site</h2>
        <ul>
            <li>
                On September 17, 2022, the current MyAdmin SDK site will be deprecated, and the MyAdmin SDK will be merged with the MyGeotab SDK site to create a centralized point of information related to integrating with Geotab.
            </li>
        </ul>
        <h2>End of life - MyAdmin APIs for third-party device data ingestion</h2>
        <ul>
            <li>
                On September 30, 2022, the MyAdmin APIs for third-party device data ingestion into MyGeotab will be permanently discontinued. After this date, MyGeotab will be unable to receive data from any devices that have not migrated to DIG.
            </li>
            <li>
                For more information, refer to the{" "}
                <a
                    href="https://docs.google.com/document/d/1hJj2CEMJlgbXHjF-it8Gey8b5_EnUDCqqhS8jRtxGyw/edit#heading=h.dzk3awl3nlud"
                    aria-label="Migrating to DIG from MyAdmin API/SDK guide"
                    target="_blank"
                    rel="noopener noreferrer">
                    Migrating to DIG from MyAdmin API/SDK guide
                </a>
                .
            </li>
            <li>
                If you're a new Partner with a standalone telematics device, refer to the{" "}
                <Link
                    to="./../../myGeotab/guides/usingCustomTelematicsDevices">
                    Using Custom Telematics Devices
                </Link>{" "}
                section in the MyGeotab SDK.
            </li>
        </ul>
    </div>
);

const updateAugust172022: ReactNode = (
    <div className="paragraph">
        <h2>Moving the MyAdmin SDK site</h2>
        <ul>
            <li>
                On September 17, 2022, the current MyAdmin SDK site will be deprecated, and the MyAdmin SDK will be merged with the MyGeotab SDK site to create a centralized point of information related to integrating with Geotab.
            </li>
        </ul>
        <h2>End of life - MyAdmin APIs for third-party device data ingestion</h2>
        <ul>
            <li>
                On September 30, 2022, the MyAdmin APIs for third-party device data ingestion into MyGeotab will be permanently discontinued. After this date, MyGeotab will be unable to receive data from any devices that have not migrated to DIG.
            </li>
            <li>
                For more information, refer to the{" "}
                <a
                    href="https://docs.google.com/document/d/1hJj2CEMJlgbXHjF-it8Gey8b5_EnUDCqqhS8jRtxGyw/edit#heading=h.dzk3awl3nlud"
                    aria-label="Migrating to DIG from MyAdmin API/SDK guide"
                    target="_blank"
                    rel="noopener noreferrer">
                    Migrating to DIG from MyAdmin API/SDK guide
                </a>
                .
            </li>
            <li>
                If you're a new Partner with a standalone telematics device, refer to the{" "}
                <Link
                    to="./../../myGeotab/guides/usingCustomTelematicsDevices">
                    Using Custom Telematics Devices
                </Link>{" "}
                section in the MyGeotab SDK.
            </li>
        </ul>
    </div>
);

const updateJuly112022: ReactNode = (
    <div className="paragraph">
        <h2>Moving the MyAdmin SDK site</h2>
        <ul>
            <li>
                On August 29, 2022, the current MyAdmin SDK site will be deprecated, and the MyAdmin SDK will be merged with the MyGeotab SDK site to create a centralized point of information related to integrating with Geotab.
            </li>
        </ul>
        <h2>End of life - MyAdmin APIs for third-party device data ingestion</h2>
        <ul>
            <li>
                On September 30, 2022, the MyAdmin APIs for third-party device data ingestion into MyGeotab will be permanently discontinued. After this date, MyGeotab will be unable to receive data from any devices that have not migrated to DIG.
            </li>
            <li>
                For more information, refer to the{" "}
                <a
                    href="https://docs.google.com/document/d/1hJj2CEMJlgbXHjF-it8Gey8b5_EnUDCqqhS8jRtxGyw/edit#heading=h.dzk3awl3nlud"
                    aria-label="Migrating to DIG from MyAdmin API/SDK guide"
                    target="_blank"
                    rel="noopener noreferrer">
                    Migrating to DIG from MyAdmin API/SDK guide
                </a>
                .
            </li>
            <li>
                If you're a new Partner with a standalone telematics device, refer to the{" "}
                <Link
                    to="./../../myGeotab/guides/usingCustomTelematicsDevices">
                    Using Custom Telematics Devices
                </Link>{" "}
                section in the MyGeotab SDK.
            </li>
        </ul>
    </div>
);

const updateJune202022: ReactNode = (
    <div className="paragraph">
        <h2>Orders</h2>
        <ul>
            <li>
                Currently, MyAdmin does not require a zip code or postal code for shipments. As a result, shipments may be delayed due to blank or invalid zip or postal codes, and require
                verification before the order can be shipped.
            </li>
            <li>
                To ensure we can get your order shipped as quickly as possible, the Zip Code/Postal Code field will be required, and we're adding the functionality to validate the zip or postal code
                during the checkout process.
            </li>
        </ul>
    </div>
);

const updateJune062022: ReactNode = (
    <div className="paragraph">
        <h2>ApiOrderHeader</h2>
        <ul>
            <li>
                An optional parameter <code>Requested Delivery Date</code> is going to be added to <strong>ApiOrderHeader</strong> object, so Partners can request a specific delivery date for orders placed through the API.
            </li>
        </ul>
        <h2>GetDeviceContracts</h2>
        <ul>
            <li>
                We've added two new parameters - <code>ordersAddedFrom</code> and <code>ordersAddedTo</code> - to the <strong>GetDeviceContracts</strong> method, so you can retrieve a list of OEM Purchase Order(PO) numbers added during the specific date range.
            </li>
            <li>
                When using these parameters, the other two parameters - <code>fromDate</code> and <code>toDate</code> - are not required.
            </li>
        </ul>
        <h2>Monthly Billing</h2>
        <ul>
            <li>
                In an upcoming release, we're adding an Extended from Database column on the Monthly Billing page for clarity and to improve the Monthly Billing reports for Extendable Services.
            </li>
            <li>
                The Extended from Database column displays the names of the originating databases, when a device has extended services from a database in the selected billing period. We are also renaming the Database column to Database Billed to more accurately describe what this column represents.
            </li>
            <li>
                Please take note of this if your integration uses column names rather than column numbers.
            </li>
        </ul>
        <h2>Orders</h2>
        <ul>
            <li>
                Currently, MyAdmin does not require a zip code or postal code for shipments. As a result, shipments may be delayed due to blank or invalid zip or postal codes, and require verification before the order can be shipped.
            </li>
            <li>
                To ensure we can get your order shipped as quickly as possible, the Zip Code/Postal Code field will be required, and we're adding the functionality to validate the zip or postal code during the checkout process.
            </li>
        </ul>
    </div>
);

const updateMay162022: ReactNode = (
    <div className="paragraph">
        <h2>OnlineOrderStatus</h2>
        <ul>
            <li>
                The <strong>GetOnlineOrderStatus</strong> API has been updated to include a new parameter, <code>includeCancelledOrders</code>, which allows you to
                include cancelled orders in the search results.
            </li>
        </ul>
        <h2>ApiOrderHeader</h2>
        <ul>
            <li>
                An optional parameter <code>Requested Delivery Date</code> is going to be added to <strong> ApiOrderHeader</strong> object, so Partners can request a
                specific delivery date for orders placed through the API.
            </li>
        </ul>
        <h2>Monthly Billing</h2>
        <ul>
            <li>In an upcoming release, we're adding an Extended from Database column on the Monthly Billing page for clarity and to improve the Monthly Billing reports for Extendable Services.</li>
            <li>
                The Extended from Database column displays the names of the originating databases, when a device has extended services from a database in the selected billing period. We are also
                renaming the Database column to Database Billed to more accurately describe what this column represents.
            </li>
            <li>Please take note of this if your integration uses column names rather than column numbers.</li>
        </ul>
        <h2>Orders</h2>
        <ul>
            <li>
                Currently, MyAdmin does not require a zip code or postal code for shipments. As a result, shipments may be delayed due to blank or invalid zip or postal codes, and require
                verification before the order can be shipped.
            </li>
            <li>
                To ensure we can get your order shipped as quickly as possible, the Zip Code/Postal Code field will be required, and we're adding the functionality to validate the zip or postal code
                during the checkout process.
            </li>
        </ul>
    </div>
);

const updateApril112022: ReactNode = (
    <div className="paragraph">
        <h2>PartnerDeviceContractsAsync</h2>
        <ul>
            <li>
                A new API, <strong>GetPartnerDeviceContractsAsync</strong>, has been added to allow Marketplace Partners and Integration Partners to retrieve a list of active devices they've produced, as well as the database each device is connected to.
            </li>
            <li>
                With this API, these Partners can set up, service, and support their products, as well as ensure their products are properly functioning.
            </li>
            <li>
                The <code>ThirdPartyPartner-Admin</code> role is required to access the <strong>GetPartnerDeviceContractsAsync</strong> API.
            </li>
            <li>
                To use this API, input <code>sessionID</code> in addition to the <strong>GetPartnerDeviceContractsAsync</strong> method. Once this is complete, the API call retrieves the list of devices including the Serial Number, Database, and Product Code.
            </li>
            <li>
                This API is for Partners who produce third-party products only.
            </li>
        </ul>
        <h2>Shutdown complete - Disabled GET requests used for authentication</h2>
        <ul>
            <li>
                For several months, we have communicated that we will disable <strong>Authenticate</strong> and <strong>RegisterNewUser</strong> GET requests to the MyAdmin API for increased security.
            </li>
            <li>
                As mentioned, effective March 31, 2022, GET requests have been disabled. POST requests will continue to be supported.
            </li>
            <li>
                If GET requests are used for authentication purposes, users will receive the following error message: “405 Method Not Allowed.”
            </li>
        </ul>
    </div>
);

const updateMarch252022: ReactNode = (
    <div className="paragraph">
        <h2>Updated character limit for reseller reference</h2>
        <ul>
            <li>
                We've made changes to our current character limit for the Reseller Reference field. We have increased the limit from 250 characters to 500 characters.
            </li>
        </ul>
        <h2>Shutdown imminent - Disable GET requests used for authentication</h2>
        <ul>
            <li>
                Effective March 31, 2022, GET requests will be disabled. POST requests will continue to be supported. If GET requests are used for authentication purposes, users will receive the following error message: “405 Method Not Allowed.”
            </li>
        </ul>
    </div>
);

const updateFebruary282022: ReactNode = (
    <div className="paragraph">
        <h2>DeviceContracts</h2>
        <ul>
            <li>
                The <strong>GetDeviceContracts</strong> method search functionality has been updated to support searching for OEM devices based on full or partial Purchase Order (PO) numbers.
            </li>
        </ul>
        <h2>Disable GET requests used for authentication</h2>
        <ul>
            <li>
                In an upcoming release, <strong>Authenticate</strong> and <strong>RegisterNewUser</strong> GET requests will be disabled to the MyAdmin API for increased security.
            </li>
            <li>
                POST requests will continue to be supported. If GET requests are used for authentication purposes, users will receive the following error message: “405 Method Not Allowed.”
            </li>
        </ul>
    </div>
);

const updateJanuary312022: ReactNode = (
    <div className="paragraph">
        <h2>Generate serial numbers in bulk</h2>
        <ul>
            <li>
                Serial numbers can now be requested for up to 1000 custom telematics devices (with the same product code) at a time.
            </li>
            <li>
                Previously, the functionality only supported one custom telematics device at a time.
            </li>
        </ul>
        <h2>Update to the NuGet package</h2>
        <ul>
            <li>
                The NuGet package now includes common elements from the MyGeotab SDK. We leverage .NET Core instructions, so in order to use the new package any integrations using the old .NET Framework instructions must be updated.
            </li>
        </ul>
        <h2>Disable GET requests used for authentication</h2>
        <ul>
            <li>
                In an upcoming release, <strong>Authenticate</strong> and <strong>RegisterNewUser</strong> GET requests will be disabled to the MyAdmin API for increased security.
            </li>
            <li>
                POST requests will continue to be supported. If GET requests are used for authentication purposes, users will receive the following error message: “405 Method Not Allowed.”
            </li>
        </ul>
    </div>
);
const pageTitle: PageTitleProps = {
    title: "Release Notes",
    breadCrumbItems: ["MYA", "Release Notes"]
};

const pageSections: TableOfContentsItem[] = [
    {
        elementId: "myadmin-september-30-2024",
        summary: "September 30, 2024",
        details: updateSeptember302024
    },
    {
        elementId: "myadmin-august-26-2024",
        summary: "August 26, 2024",
        details: updateAugust262024
    },
    {
        elementId: "myadmin-july-29-2024",
        summary: "July 29, 2024",
        details: updateJuly292024
    },
    {
        elementId: "myadmin-june-24-2024",
        summary: "June 24, 2024",
        details: updateJune242024
    },
    {
        elementId: "myadmin-may-27-2024",
        summary: "May 27, 2024",
        details: updateMay272024
    },
    {
        elementId: "myadmin-april-29-2024",
        summary: "April 29, 2024",
        details: updateApril292024
    },
    {
        elementId: "myadmin-march-25-2024",
        summary: "March 25, 2024",
        details: updateMarch252024
    },
    {
        elementId: "myadmin-february-26-2024",
        summary: "February 26, 2024",
        details: updateFebruary262024
    },
    {
        elementId: "myadmin-january-15-2024",
        summary: "January 15, 2024",
        details: updateJanuary152024
    },
    {
        elementId: "myadmin-december-4-2023",
        summary: "December 4, 2023",
        details: updateDecember042023
    },
    {
        elementId: "myadmin-october-4-2023",
        summary: "October 4, 2023",
        details: updateOctober042023
    },
    {
        elementId: "myadmin-september-5-2023",
        summary: "September 5, 2023",
        details: updateSeptember052023
    },
    {
        elementId: "myadmin-july-29-2023",
        summary: "July 29, 2023",
        details: updateJuly292023
    },
    {
        elementId: "myadmin-july-10-2023",
        summary: "July 10, 2023",
        details: updateJuly102023
    },
    {
        elementId: "myadmin-july-1-2023",
        summary: "July 1, 2023",
        details: updateJuly012023
    },
    {
        elementId: "myadmin-may-29-2023",
        summary: "May 29, 2023",
        details: updateMay292023
    },
    {
        elementId: "myadmin-march-27-2023",
        summary: "March 27, 2023",
        details: updateMarch272023
    },
    {
        elementId: "myadmin-march-6-2023",
        summary: "March 6, 2023",
        details: updateMarch062023
    },
    {
        elementId: "myadmin-february-13-2023",
        summary: "February 13, 2023",
        details: updateFebruary132023
    },
    {
        elementId: "myadmin-january-23-2023",
        summary: "January 23, 2023",
        details: updateJanuary232023
    },
    {
        elementId: "myadmin-december-12-2022",
        summary: "December 12, 2022",
        details: updateDecember122022
    },
    {
        elementId: "myadmin-december-5-2022",
        summary: "December 5, 2022",
        details: updateDecember052022
    },
    {
        elementId: "myadmin-november-14-2022",
        summary: "November 14, 2022",
        details: updateNovember142022
    },
    {
        elementId: "myadmin-september-19-2022",
        summary: "September 19, 2022",
        details: updateSeptember192022
    },
    {
        elementId: "myadmin-september-12-2022",
        summary: "September 12, 2022",
        details: updateSeptember122022
    },
    {
        elementId: "myadmin-august-17-2022",
        summary: "August 17, 2022",
        details: updateAugust172022
    },
    {
        elementId: "myadmin-july-11-2022",
        summary: "July 11, 2022",
        details: updateJuly112022
    },
    {
        elementId: "myadmin-june-20-2022",
        summary: "June 20, 2022",
        details: updateJune202022
    },
    {
        elementId: "myadmin-june-6-2022",
        summary: "June 6, 2022",
        details: updateJune062022
    },
    {
        elementId: "myadmin-may-16-2022",
        summary: "May 16, 2022",
        details: updateMay162022
    },
    {
        elementId: "myadmin-april-11-2022",
        summary: "April 11, 2022",
        details: updateApril112022
    },
    {
        elementId: "myadmin-march-25-2022",
        summary: "March 25, 2022",
        details: updateMarch252022
    },
    {
        elementId: "myadmin-february-28-2022",
        summary: "February 28, 2022",
        details: updateFebruary282022
    },
    {
        elementId: "myadmin-january-31-2022",
        summary: "January 31, 2022",
        details: updateJanuary312022
    }
];

export default function ReleaseNotes() {
    return <Page section={HeaderSections.MyAdmin} pageTitle={pageTitle} tableOfContents={pageSections} />;
}
